import normalizeForSearch from 'normalize-for-search'
import flow from 'lodash.flow'
import { isStopCode } from 'common/stops/helpers/stops-helpers'

const uselessWords: string[] = [
  '',
  'de',
  'del',
  'la',
  'el',
  'los',
  'las',
  'un',
  'una',
  'y',
]

const nomalizedShortWords: {[word: string]: string} = {
  av: 'avenida',
  avda: 'avenida',
  pza: 'plaza',
  ctra: 'carretera',
  urb: 'urbanizacion',
  gta: 'glorieta',
  rda: 'ronda',
  rtda: 'rotonda',
  est: 'estacion',
  pol: 'poligono',
  ind: 'industrial',
  ayto: 'ayuntamiento',
  fdez: 'fernandez',
  fco: 'francisco',
  mtnez: 'martinez',
  sta: 'santa',
  pº: 'paseo',
  cº: 'camino',
  mª: 'maria',
  nº: 'numero',
  gª: 'garcia',
  dr: 'doctor',
  pque: 'parque',
  pta: 'puerta',
}

function normalizeShortWord(word: string): string {
  return nomalizedShortWords[word] || word
}

function normalizeShortWords(input: string[]): string[] {
  return input.map((word) => normalizeShortWord(word))
}

function removeUselessWords(input: string[]): string[] {
  return input.filter((word) => (
    !uselessWords.includes(word)
  ))
}

function replaceWordSeparatorsBySpaces(input: string): string {
  return input.replace(/[ºª,-.()/]/g, ' ')
}

function splitWordsBySpace(input: string): string[] {
  return input.split(/ +/)
}

function removeDuplicates(words: string[]): string[] {
  return words.filter((word, index) => words.indexOf(word) === index)
}

export const getSearchableWords = flow(
  normalizeForSearch,
  replaceWordSeparatorsBySpaces,
  splitWordsBySpace,
  removeUselessWords,
  normalizeShortWords,
  removeDuplicates,
)

function filterStopCodes(words: string[]): string[] {
  return words.filter((word) => isStopCode(word))
}

export const extractStopCodes = flow(
  splitWordsBySpace,
  removeDuplicates,
  filterStopCodes,
)
