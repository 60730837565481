import { ParamAppConfig } from 'common/core/models/param-app-config-models'

export default class AppParamConfigStore {
  constructor(private localStorageKey: string) {}

  getConfig(): ParamAppConfig | undefined {
    const storedValue = localStorage.getItem(this.localStorageKey)
    if (!storedValue) {
      return undefined
    }
    return JSON.parse(storedValue)
  }

  setConfig(config: ParamAppConfig) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(config))
  }
}
