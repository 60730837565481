import React from 'react'
import { Helmet } from 'react-helmet'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

interface PageMetadataProps {
  pageId: string
  options?: any
}

const PageMetadata: React.FC<PageMetadataProps> = (props) => {
  const { pageMetadataCollection } = useBrandConfigContext().common
  return (
    <Helmet>
      <title>
        {pageMetadataCollection[props.pageId].title(props.options)}
      </title>
      <meta
        name="description"
        content={pageMetadataCollection[props.pageId].description(props.options)}
      />
    </Helmet>
  )
}

export default PageMetadata
