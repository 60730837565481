import React from 'react'
import { AppModal } from 'common/ui/components/AppModal/AppModal'
import { FormattedMessage, useIntl } from 'react-intl'
import s from 'common/ui/components/modals/modals.module.scss'

interface Props {
  isOpen: boolean
  onAccept: () => void
  onCancel: () => void
}

const LogoutModal: React.FC<Props> = ({ isOpen, onAccept, onCancel }) => {
  const intl = useIntl()

  function handleAccept(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onAccept()
  }

  return (
    <AppModal
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'auth_logout_modal_title' })}
      shouldShowCloseButton={false}
      onRequestClose={onCancel}
    >
      <form onSubmit={handleAccept} method="POST">
        <FormattedMessage id="auth_logout_modal_text" />
        <div className={s.buttonsContainer}>
          <button type="button" onClick={onCancel} className={s.button}>
            <FormattedMessage id="auth_logout_modal_cancel" />
          </button>
          <button type="submit" className={s.button}>
            <FormattedMessage id="auth_logout_modal_accept" />
          </button>
        </div>
      </form>
    </AppModal>
  )
}

export default LogoutModal
