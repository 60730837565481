import { AppThemeName } from 'common/ui/models/app-theme-models'

function getMapBaseStyles(): google.maps.MapTypeStyle[] {
  return [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
  ]
}

function getMapDarkStyles(): google.maps.MapTypeStyle[] {
  return [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#2c2c2c',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8a8a8a',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#373737',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#3c3c3c',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [
        {
          color: '#4e4e4e',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#3d3d3d',
        },
      ],
    },
  ]
}

function getMapStyles(uiTheme: AppThemeName): google.maps.MapTypeStyle[] {
  if (uiTheme === AppThemeName.Dark) {
    return [
      ...getMapBaseStyles(),
      ...getMapDarkStyles(),
    ]
  }
  return getMapBaseStyles()
}

export function createMapConfig(
  googleMapsLib: typeof google.maps, // eslint-disable-line
  center: {lat: number, lng: number },
  uiTheme: AppThemeName,
  zoom?: number,
): google.maps.MapOptions {
  const styles = getMapStyles(uiTheme)
  return {
    backgroundColor: 'transparent',
    center,
    zoom,
    clickableIcons: false,
    mapTypeControl: false,
    // mapTypeControlOptions: {
    //   style: googleMapsLib.MapTypeControlStyle.DROPDOWN_MENU,
    //   position: googleMapsLib.ControlPosition.BOTTOM_CENTER,
    // },
    fullscreenControlOptions: {
      position: googleMapsLib.ControlPosition.RIGHT_BOTTOM,
    },
    styles,
  }
}

// Hack to prevent markerclusterer library bug
// that causes map freeze when touching a cluster icon while zooming the map
const preventUserEventsOnClustersCss = 'pointer-events: none'

const commonCss = `
  background-color: rgba(13, 6, 146, 0.5);
  box-shadow: 0px 0px 0px 3px rgba(13, 6, 146, 0.2);
  text-shadow: rgba(0, 0, 0, 0.65) 0px 0px 2px;
  border-radius: 50%;
  ${preventUserEventsOnClustersCss}
`

export const getClusteringOptions = () => ({
  gridSize: 50,
  zoomOnClick: false,
  maxZoom: 15,
  styles: [
    {
      height: 20,
      width: 20,
      textColor: `#ffffff; ${commonCss}`,
      textSize: 11,
    }, {
      height: 25,
      width: 25,
      textColor: `#ffffff; ${commonCss}`,
      textSize: 11,
    }, {
      height: 30,
      width: 30,
      textColor: `#ffffff; ${commonCss}`,
      textSize: 11,
    }, {
      height: 35,
      width: 35,
      textColor: `#ffffff; ${commonCss}`,
      textSize: 11,
    }, {
      height: 50,
      width: 50,
      textColor: `#ffffff; ${commonCss}`,
      textSize: 11,
    },
  ],
})
