export enum ClientType {
  Web = 'web',
  Android = 'android',
  IOS = 'ios',
}

export interface ParamAppConfig {
  clientType: ClientType
  enableGA: boolean
  androidClientVersionCode: number | undefined
  iosClientVersion: string | undefined
}
