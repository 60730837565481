import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as StarBorderIcon } from 'common/images/star_border-24px.svg'
import { ReactComponent as StarIcon } from 'common/images/star-24px.svg'
import { ReactComponent as EditIcon } from 'common/images/edit-24px.svg'
import { ReactComponent as MapIcon } from 'common/images/map-filled-24px.svg'
import { Stop } from 'common/stops/models/stop-models'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'
import s from './StopActions.module.scss'

const renderFavoritesButton = (props: Props) => {
  if (props.stopFav) {
    return (
      <button
        type="button"
        className={s.button}
        onClick={props.onRemoveFromFavoritesButtonClick}
      >
        <StarIcon /><br /><FormattedMessage id="stop_actions_remove_fav" />
      </button>
    )
  }
  return (
    <button
      type="button"
      className={s.button}
      onClick={props.onAddToFavoritesButtonClick}
    >
      <StarBorderIcon /><br /><FormattedMessage id="stop_actions_add_fav" />
    </button>
  )
}

const renderRenameButton = (props: Props) => {
  if (props.stopFav) {
    return (
      <button
        type="button"
        className={s.button}
        onClick={props.onRenameFavoriteButtonClick}
      >
        <EditIcon /><br /><FormattedMessage id="stop_actions_rename" />
      </button>
    )
  }
  return undefined
}

interface Props {
  stop: Stop
  stopFav?: StopFav
  onAddToFavoritesButtonClick: () => void
  onRemoveFromFavoritesButtonClick: () => void
  onRenameFavoriteButtonClick: () => void
}

const StopActions: React.FC<Props> = (props) => {
  const { urls } = useBrandConfigContext().stops
  return (
    <div className={s.stopActions}>
      { renderFavoritesButton(props) }
      { renderRenameButton(props) }
      <Link to={urls.getStopsMapUrl(props.stop!.stopCode)} className={s.button}>
        <MapIcon /><br /><FormattedMessage id="stop_actions_show_on_map" />
      </Link>
    </div>
  )
}

export default StopActions
