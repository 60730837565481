import { ClientType, ParamAppConfig } from 'common/core/models/param-app-config-models'

export default class GoogleAnalyticsManager {
  constructor(
    private paramAppConfig: ParamAppConfig,
    private gaIdMapping: {[key in ClientType]?: string},
  ) {}

  public initialize(): void {
    const gaId = this.getGAId()
    window.ga('create', gaId, 'auto')
    if (!this.paramAppConfig.enableGA) {
      window.ga('set', 'sendHitTask', null)
    }
  }

  private getGAId(): string {
    const { clientType } = this.paramAppConfig
    const foundId = this.gaIdMapping[clientType]
    if (!foundId) {
      throw new Error(`There is no Google Analytics ID available for '${clientType}' client type`)
    }
    return foundId
  }
}
