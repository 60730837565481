interface StopMapState {
  zoom: number
  center: {
    lat: number
    lng: number
  },
  userLocation?: {
    lat: number
    lng: number
  },
}

interface StoredPayload {
  zoom: number
  center: {
    lat: number
    lng: number
  },
  userLocation?: {
    lat: number
    lng: number
  },
}

export default class StopMapStateStore {
  constructor(private localStorageKey: string) {}

  public set(state: StopMapState): void {
    const payload = StopMapStateStore.convertStateToPayload(state)
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }

  public get(): StopMapState | undefined {
    const payloadStr = window.localStorage.getItem(this.localStorageKey)
    if (payloadStr) {
      return StopMapStateStore.convertPayloadToState(JSON.parse(payloadStr))
    }
    return undefined
  }

  private static convertPayloadToState(payload: StoredPayload): StopMapState {
    return {
      zoom: payload.zoom,
      center: {
        lat: payload.center.lat,
        lng: payload.center.lng,
      },
      userLocation: payload.userLocation,
    }
  }

  private static convertStateToPayload(state: StopMapState): StoredPayload {
    return {
      zoom: state.zoom,
      center: {
        lat: state.center.lat,
        lng: state.center.lng,
      },
      userLocation: state.userLocation,
    }
  }
}
