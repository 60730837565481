import React, { useEffect, useState, useCallback } from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import { LineListItem } from 'common/lines/models/line-list-models'
import { StopListItem } from 'common/stops/models/stop-list-models'
import SearchPageView from 'common/search/components/SearchPageView/SearchPageView'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

const SearchPage: React.FC = () => {
  const [retrieveStatus, setRetrieveStatus] = useState<OpStatus>()
  const [retrieveError, setRetrieveError] = useState<Error>()
  const [stopList, setStopList] = useState<StopListItem[]>()
  const [lineList, setLineList] = useState<LineListItem[]>()

  const { services } = useBrandConfigContext().stops

  const fetchData = useCallback(async () => {
    setRetrieveStatus(OpStatus.InProgress)
    try {
      const [retrievedStopList, retrievedLineList] = await Promise.all([
        services.stopListApiClient.getStopList(),
        services.lineListApiClient.getLineList(),
      ])
      setStopList(retrievedStopList)
      setLineList(retrievedLineList)
      setRetrieveStatus(OpStatus.Success)
    } catch (error) {
      setRetrieveError(error)
      setRetrieveStatus(OpStatus.Failed)
    }
  }, [services])

  const defaultQuery = services.searchHistoryStore.getLastQuery()

  useEffect(() => { fetchData() }, [fetchData])

  return (
    <SearchPageView
      retrieveStatus={retrieveStatus}
      retrieveError={retrieveError}
      stopList={stopList}
      lineList={lineList}
      defaultQuery={defaultQuery}
      onRetryFetchButtonClick={fetchData}
    />
  )
}

export default SearchPage
