import React from 'react'
import s from './ValueSelector.module.scss'

const ValueSelector: React.FC = ({ children }) => (
  <div className={s.themePicker}>
    { children }
  </div>
)

export default ValueSelector
