import React from 'react'
import { StopListItem } from 'common/stops/models/stop-list-models'
import StopMap from 'common/maps/components/StopMap/StopMap'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

interface Props {
  stopList: StopListItem[]
  googleMapsLib: typeof google.maps
  selectedStopCode?: string
}

const NormalStopMap: React.FC<Props> = (props) => {
  const { stops: stopsBrandConfig } = useBrandConfigContext()
  const mapState = stopsBrandConfig.services.stopMapStateStore.get()
  return (
    <StopMap
      stopList={props.stopList!}
      googleMapsLib={props.googleMapsLib!}
      selectedStopCode={props.selectedStopCode}
      enableClustering
      mapCenter={mapState?.center ?? stopsBrandConfig.helpers.defaultMapCenter}
      mapZoom={mapState?.zoom ?? 11}
      onMapIdle={(map) => {
        const zoom = map.getZoom()
        const center = map.getCenter()
        const payload = {
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
          zoom,
        }
        stopsBrandConfig.services.stopMapStateStore.set(payload)
      }}
    />
  )
}

export default NormalStopMap
