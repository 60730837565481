import React, { useState } from 'react'
import classNames from 'classnames'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { scrollToTop } from 'common/ui/helpers/scroll-utils'
import { ReactComponent as CloseIcon } from 'common/images/close-24px.svg'
import {
  Stop, StopDeparture, StopItinerary, LineDirection,
} from 'common/stops/models/stop-models'
import { FavoriteArrival } from 'common/stops/services/favorite-arrivals-store/FavoriteArrivalsStore'
import DepartureGroupComponent, { DepartureGroup } from 'common/stops/components/DepartureGroup/DepartureGroup'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage } from 'react-intl'
import naturalSort from 'natural-sort'
import s from './StopArrivals.module.scss'

function getDepartureGroups(departures: StopDeparture[]): DepartureGroup[] {
  return departures.reduce<DepartureGroup[]>((accGroups, curr) => {
    const existentGroup = accGroups.find((group) => (
      group.lineNumber === curr.lineNumber
        && group.lineBound === curr.lineBound
    ))
    if (existentGroup && existentGroup.departures.length < 3) {
      existentGroup.departures.push(curr)
    } else {
      accGroups.push({
        lineCode: curr.lineCode,
        lineBound: curr.lineBound,
        lineNumber: curr.lineNumber,
        lineDirection: curr.lineDirection,
        transportTypeCode: curr.transportTypeCode,
        isNocturnal: curr.isNocturnal,
        departures: [curr],
      })
    }
    return accGroups
  }, [])
}

function renderFavoriteDeparturesAlert(
  isThereFavLines: boolean,
  stop: Stop,
  isFavDeparturesHintDismissed: boolean,
  onDismissFavDeparturesHint: () => void,
  stopsConfig: StopsBrandConfig,
) {
  if (isFavDeparturesHintDismissed
  || stopsConfig.helpers.stopTypeCodesWithoutFavDeparturesAlert.includes(stop.stopTypeCode)
  || isThereFavLines
  || stop.itineraries?.length === 1) {
    return undefined
  }
  return (
    <div className={s.hint}>
      <FormattedMessage id="stop_fav_arrivals_hint" />
      <button
        type="button"
        className={s.hintClose}
        onClick={onDismissFavDeparturesHint}
      >
        <CloseIcon />
      </button>
    </div>
  )
}

const renderHelpButton = (handleClick: () => void) => (
  <div className={s.helpSection}>
    <button type="button" onClick={handleClick} className={s.helpButton}>
      <FormattedMessage id="arrivals_sources_modal_title" />
    </button>
  </div>
)
export interface StopArrivalsProps {
  stop: Stop
  onShowNoDataExplanationClick: () => void
}

function renderDepartureGroups(
  departureGroups: DepartureGroup[],
  areFavorite: boolean,
  onCreateFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
  onRemoveFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
) {
  return departureGroups.map((group) => (
    <DepartureGroupComponent
      group={group}
      isFavorite={areFavorite}
      key={`${group.lineNumber}-${group.lineBound}`}
      onCreateFavoriteArrival={onCreateFavoriteArrival}
      onRemoveFavoriteArrival={onRemoveFavoriteArrival}
    />
  ))
}

function renderItineraries(
  itineraries: StopItinerary[],
  areFavorite: boolean,
  onCreateFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
  onRemoveFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
) {
  return itineraries
    .sort((a, b) => naturalSort()(a.lineNumber, b.lineNumber))
    .map((itinerary) => {
      const group: DepartureGroup = {
        lineCode: itinerary.lineCode,
        lineDirection: itinerary.lineDirection,
        lineBound: itinerary.destinationName,
        lineNumber: itinerary.lineNumber,
        transportTypeCode: itinerary.transportTypeCode,
        isNocturnal: itinerary.isNocturnalLine,
        departures: [],
      }
      return (
        <DepartureGroupComponent
          key={`${group.lineNumber}-${itinerary.lineDirection}`}
          group={group}
          isFavorite={areFavorite}
          onCreateFavoriteArrival={onCreateFavoriteArrival}
          onRemoveFavoriteArrival={onRemoveFavoriteArrival}
        />
      )
    })
}

function renderFavoriteLines(
  departureGroups: DepartureGroup[],
  favItinerariesWithoutArrivals: StopItinerary[],
  onCreateFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
  onRemoveFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
) {
  const favoriteDepartures = renderDepartureGroups(
    departureGroups, true, onCreateFavoriteArrival, onRemoveFavoriteArrival,
  )
  const favItineraries = renderItineraries(
    favItinerariesWithoutArrivals, true, onCreateFavoriteArrival, onRemoveFavoriteArrival,
  )
  return (
    <div className={classNames(s.linesCard, s.favoriteArrivals)}>
      { favoriteDepartures }
      { favItineraries }
    </div>
  )
}

function renderNotFavoriteLines(
  departureGroups: DepartureGroup[],
  itineraries: StopItinerary[],
  onCreateFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
  onRemoveFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void,
) {
  const favoriteDepartures = renderDepartureGroups(
    departureGroups, false, onCreateFavoriteArrival, onRemoveFavoriteArrival,
  )
  const favItineraries = renderItineraries(
    itineraries, false, onCreateFavoriteArrival, onRemoveFavoriteArrival,
  )
  return (
    <div>
      { favoriteDepartures }
      { favItineraries }
    </div>
  )
}

function getItinerariesWithoutArrivals(
  stop: Stop,
  departureGroups: DepartureGroup[],
): StopItinerary[] {
  if (!stop.itineraries) {
    return []
  }
  return stop.itineraries
    .filter((itinerary) => (
      !departureGroups.find((departureGroup) => (
        departureGroup.lineCode === itinerary.lineCode
          && departureGroup.lineDirection === itinerary.lineDirection
      ))
    ))
}

function isFavorite(
  favoriteArrivals: FavoriteArrival[],
  lineCode: string | undefined,
  lineDir: LineDirection | undefined,
): boolean {
  return !!favoriteArrivals.find((favoriteArrival) => (
    favoriteArrival.lineCode === lineCode
    && favoriteArrival.lineDir === lineDir
  ))
}

export const StopArrivals = (props: StopArrivalsProps) => {
  const { stops: stopsConfig, common: commonConfig } = useBrandConfigContext()
  const { stopCode } = props.stop
  const { favoriteArrivalsStore } = stopsConfig.services
  const { uiPreferencesStore } = commonConfig.services

  const [favoriteArrivals, setFavoriteArrivals] = useState<FavoriteArrival[]>(
    favoriteArrivalsStore.getFavArrivals(stopCode),
  )

  const [isFavDeparturesHintDismissed, setIsFavDeparturesHintDismissed] = useState(
    uiPreferencesStore.isFavDeparturesHintDismissed(),
  )

  function dismissFavDeparturesHint() {
    uiPreferencesStore.dismissFavDeparturesHint()
    setIsFavDeparturesHintDismissed(true)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'dismissFavDeparturesHint',
    })
  }

  function createFavoriteArrival(linecode: string, lineDir: LineDirection) {
    favoriteArrivalsStore.addFavArrival(stopCode, linecode, lineDir)
    setFavoriteArrivals(favoriteArrivalsStore.getFavArrivals(stopCode))
    scrollToTop()
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'createFavDeparture',
      eventAction: linecode,
      eventLabel: lineDir,
    })
  }

  function removeFavoriteArrival(linecode: string, lineDir: LineDirection) {
    favoriteArrivalsStore.removeFavArrival(stopCode, linecode, lineDir)
    setFavoriteArrivals(favoriteArrivalsStore.getFavArrivals(stopCode))
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'removeFavDeparture',
      eventAction: linecode,
      eventLabel: lineDir,
    })
  }

  const departureGroups = getDepartureGroups(props.stop.departures)

  const favDepartureGroups = departureGroups.filter((group) => (
    isFavorite(favoriteArrivals, group.lineCode, group.lineDirection)
  ))
  const notFavDepartureGroups = departureGroups.filter((group) => (
    !isFavorite(favoriteArrivals, group.lineCode, group.lineDirection)
  ))

  const itinerariesWithoutArrivals = getItinerariesWithoutArrivals(props.stop, departureGroups)
  const favItinerariesWithoutArrivals = itinerariesWithoutArrivals.filter((itinerary) => (
    isFavorite(favoriteArrivals, itinerary.lineCode, itinerary.lineDirection)
  ))
  const notFavoriteItinerariesWithoutArrivals = itinerariesWithoutArrivals.filter((itinerary) => (
    !isFavorite(favoriteArrivals, itinerary.lineCode, itinerary.lineDirection)
  ))

  const isThereFavLines = favDepartureGroups.length > 0 || favItinerariesWithoutArrivals.length > 0
  return (
    <>
      <ExternalSectionTitle title={stopsConfig.helpers.getArrrivalsTitleText(props.stop)} />
      {
        isThereFavLines
          ? renderFavoriteLines(
            favDepartureGroups,
            favItinerariesWithoutArrivals,
            createFavoriteArrival,
            removeFavoriteArrival,
          ) : undefined
      }
      <div className={s.linesCard}>
        {
          renderFavoriteDeparturesAlert(
            isThereFavLines, props.stop, isFavDeparturesHintDismissed,
            dismissFavDeparturesHint, stopsConfig,
          )
        }
        {
          notFavDepartureGroups.length > 0 || notFavoriteItinerariesWithoutArrivals.length > 0
            ? renderNotFavoriteLines(
              notFavDepartureGroups,
              notFavoriteItinerariesWithoutArrivals,
              createFavoriteArrival,
              removeFavoriteArrival,
            ) : undefined
        }
        { renderHelpButton(props.onShowNoDataExplanationClick) }
      </div>
    </>
  )
}
