/* eslint-disable quote-props */
import { LineTheme, LineThemeBuilder } from 'common/lines/models/line-style-models'

const busThemes: { [lineNumber: string]: LineTheme } = {
  '01': { primary: '#009A9D' },
  '03': { primary: '#f37b00' },
  '10': { primary: '#008BD2' },
  '11': { primary: '#D39C0A' },
  '13': { primary: '#E30613' },
  '18': { primary: '#C7017F' },
  '22': { primary: '#EE758F' },
  '27': { primary: '#0BBBEF' },
  '28': { primary: '#95C11F' },
  '30': { primary: '#954B97' },
  '34': { primary: '#7F772E' },
  '38': { primary: '#4BB694' },
  '40': { primary: '#FFDD00', secondary: 'black' },
  '43': { primary: '#E786B5' },
  '48': { primary: '#EC6611' },
  '50': { primary: '#748FC9' },
  '56': { primary: '#5C2483' },
  '57': { primary: '#73858E' },
  '58': { primary: '#008BD2' },
  '62': { primary: '#A85A29' },
  '71': { primary: '#FBBA00', secondary: 'black' },
  '72': { primary: '#9A3431' },
  '75': { primary: '#52AE32' },
  '76': { primary: '#C6C500', secondary: 'black' },
  '77': { primary: '#E6007E' },
  '85': { primary: '#D992BF' },
  '88': { primary: '#A98F00' },

  'G1': { primary: '#ef7590' },
  'G2': { primary: '#f49102' },
  'G3': { primary: '#048bd4' },
  'G4': { primary: '#fddc00', secondary: 'black' },
  'G5': { primary: '#55af30' },
  'G6': { primary: '#e10914' },
  'G7': { primary: '#a5177d' },
  'G8': { primary: '#01a78e' },

  'A1': { primary: '#C7D64F', secondary: 'black' },
  'A2': { primary: '#68726A' },
  'A3': { primary: '#F0856A' },
  'A4': { primary: '#8A78B6' },
  'A5': { primary: '#BD2B0B' },
  'A6': { primary: '#B35D00' },
  'A7': { primary: '#375EA9' },
  'A8': { primary: '#EF7723' },
  'A9': { primary: '#08768A' },

  'E2': { primary: '#000000' },
  'E3': { primary: '#000000' },
  'E5': { primary: '#E6A700' },
  'E7': { primary: '#CC6465' },
}

const defaultTheme: LineTheme = {
  primary: 'gray',
}

export const buildLineTheme: LineThemeBuilder = ({ lineNumber }) => (
  busThemes[lineNumber] || defaultTheme
)
