import React from 'react'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { LineListItem } from 'common/lines/models/line-list-models'
import FoundLineLayout from '../FoundLineLayout/FoundLineLayout'
import LineNumber from '../LineNumber/LineNumber'

interface Props {
  lineListItem: LineListItem
}

const FoundRealLine: React.FC<Props> = ({ lineListItem }) => {
  const { urls, helpers } = useBrandConfigContext().stops

  const url = urls.getLineUrl(lineListItem.lineCode)
  const lineNumber = lineListItem.lineNumber.toUpperCase()
  return (
    <FoundLineLayout
      url={url}
      lineNumberElement={(
        <LineNumber
          lineNumber={lineNumber}
          transportTypeCode={lineListItem.transportTypeCode}
          isNocturnal={lineListItem.isNocturnalLine}
        />
      )}
      primaryText={helpers.composeLineDescriptionInSearchResults(
        lineListItem.lineNumber, lineListItem.transportTypeCode,
      )}
      secondaryText={lineListItem.lineName}
    />
  )
}

export default FoundRealLine
