import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from 'common/images/keyboard_arrow_right-24px.svg'
import s from './FoundLineLayout.module.scss'

interface Props {
  url: string
  lineNumberElement: JSX.Element
  primaryText: React.ReactNode
  secondaryText?: string
}

const FoundLineLayout: React.FC<Props> = ({
  url, lineNumberElement, primaryText, secondaryText,
}) => (
  <Link to={url} className={s.foundLine}>
    <div className={s.lineNumberContainer}>
      { lineNumberElement }
    </div>
    <div className={s.lineContent}>
      <div className={s.lineDescription}>
        { primaryText }
      </div>
      { secondaryText && <div className={s.secondaryName}>{secondaryText}</div> }
    </div>
    <ArrowRightIcon className={s.arrow} />
  </Link>
)

export default FoundLineLayout
