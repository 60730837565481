import { User } from 'firebase/auth'
import { UserFirestoreClient } from '../user-firestore-client/UserFirestoreClient'
import StopFavsStore, { StopFav } from '../stop-favs-store/StopFavsStore'

export default class StopFavsService {
  constructor(
    private stopFavsStore: StopFavsStore,
    private userFirestoreClient: UserFirestoreClient,
  ) {}

  getStopFavs(user: User | null) {
    if (user) {
      return this.userFirestoreClient.getStopFavsWithCache(user)
    }
    return this.stopFavsStore.getStopFavs()
  }

  setStopFavs(user: User | null, stopFavs: StopFav[]) {
    if (user) {
      return this.userFirestoreClient.setStopFavs(user, stopFavs)
    }
    return this.stopFavsStore.setStopFavs(stopFavs)
  }
}
