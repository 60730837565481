import { AppUsageTracker } from 'common/core/services/app-usage-tracker/AppUsageTracker'
import { AdsUsagePersistor } from 'common/ads/services/AdsUsagePersistor'
import { ClientType, ParamAppConfig } from 'common/core/models/param-app-config-models'

export class AdsManager {
  private static readonly STOP_AD_INTERVAL_IN_MILLESECONDS = 20 * 60 * 1000

  private static readonly TRIAL_PERIOD_IN_MILLESECONDS = 24 * 60 * 60 * 1000

  constructor(
    private adsUsagePersistor: AdsUsagePersistor,
    private appUsageTracker: AppUsageTracker,
    private paramAppConfig: ParamAppConfig,
  ) {}

  public requestIntersitialAdIfNeeded(page: string): void {
    if (this.shouldShowInterstitialAd()) {
      this.requestInterstitialAd(page)
    }
  }

  private shouldShowInterstitialAd() {
    if (this.isTrialPeriodAvailableForClient() && this.isTrialPeriodActive()) {
      return false
    }
    if (this.isPeriodBetweenAdsActive()) {
      return false
    }
    return true
  }

  private isTrialPeriodAvailableForClient() {
    return [ClientType.Android, ClientType.IOS].includes(this.paramAppConfig.clientType)
  }

  private isTrialPeriodActive() {
    const firstAppUsageTimestamp = this.appUsageTracker.getFirstAppLaunchTimestamp()
    if (!firstAppUsageTimestamp) {
      return true
    }
    return Date.now() - firstAppUsageTimestamp <= AdsManager.TRIAL_PERIOD_IN_MILLESECONDS
  }

  private isPeriodBetweenAdsActive() {
    const timestamp = this.adsUsagePersistor.getLastInterstitialAdImpressionTimestamp()
    if (!timestamp) {
      return false
    }
    return Date.now() - timestamp <= AdsManager.STOP_AD_INTERVAL_IN_MILLESECONDS
  }

  private requestInterstitialAd(page: string) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'interstitialAdImpression',
      eventAction: page,
    })
    this.adsUsagePersistor.setLastInterstitialAdImpressionTimestamp(Date.now())
    AdsManager.requestAndroidBridgeInterstitialAd()
    AdsManager.requestIosBridgeInterstitialAd()
  }

  public static requestAndroidBridgeInterstitialAd() {
    if ((window as any).otmAndroidBridge) {
      const { otmAndroidBridge } = (window as any)
      if (otmAndroidBridge.showInterstitialAd) {
        otmAndroidBridge.showInterstitialAd()
      }
    }
  }

  public static requestIosBridgeInterstitialAd() {
    const handler = (window as any).webkit?.messageHandlers?.otIosBridge
    if (handler) {
      handler.postMessage({ action: 'showInterstitialAd' })
    }
  }
}
