export class AdsUsagePersistor {
  constructor(private localStorageKey: string) {}

  public getLastInterstitialAdImpressionTimestamp(): number | undefined {
    const payload = this.getLocalStoragePayload()
    return payload?.stopAdTimestamp
  }

  public setLastInterstitialAdImpressionTimestamp(timestamp: number): void {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      stopAdTimestamp: timestamp,
    })
  }

  private getLocalStoragePayload(): LSPayload | undefined {
    const payloadStr = window.localStorage.getItem(this.localStorageKey)
    if (!payloadStr) {
      return undefined
    }
    return JSON.parse(payloadStr) as LSPayload
  }

  private setLocalStoragePayload(payload: LSPayload) {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }
}

interface LSPayload {
  stopAdTimestamp?: number
}
