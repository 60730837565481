import React from 'react'
import PosterPreviewLink from 'common/posters/components/PosterPreviewLink/PosterPreviewLink'
import { PosterPreviewModel } from 'common/posters/models/posters-models'
import s from './PostersCatalog.module.scss'

interface MapsPageProps {
  previews: PosterPreviewModel[]
}

const PostersCatalog: React.FC<MapsPageProps> = function PostersCatalog({ previews }) {
  return (
    <div className={s.mapsSection}>
      <div className={s.mapsContainer}>
        {
          previews.map((preview) => <PosterPreviewLink model={preview} key={preview.linkUrl} />)
        }
      </div>
    </div>
  )
}

export default PostersCatalog
