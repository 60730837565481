import React from 'react'
import classNames from 'classnames'
import EnteringAnimation from 'common/ui/components/EnteringAnimation/EnteringAnimation'
import useBottomAdContext from 'common/ads/hooks/useBottomAdContext'
import s from './PageLayout.module.scss'
import Navbar from '../Navbar/Navbar'

interface Props {
  shouldShowNavbar?: boolean
  actionbar: React.ReactNode
}

function renderNavbar(isBottomAdShown: boolean) {
  return (
    <div
      className={classNames({
        [s.fixedNavbar]: true,
        [s.navbarWithBottomAd]: isBottomAdShown,
      })}
    >
      <Navbar />
    </div>
  )
}

const PageLayout: React.FC<Props> = ({
  shouldShowNavbar = true,
  actionbar,
  children,
}) => {
  const { isBottomAdShown } = useBottomAdContext()
  const isFixedActionbar = !isBottomAdShown
  return (
    <>
      <div
        className={classNames({
          [s.actionBar]: true,
          [s.fixedActionBar]: isFixedActionbar,
        })}
      >
        { actionbar }
      </div>
      <EnteringAnimation>
        <div
          className={classNames({
            [s.content]: true,
            [s.withFixedActionbar]: isFixedActionbar,
            [s.withNavbar]: shouldShowNavbar,
            [s.withNavbarAndBottomAd]: isBottomAdShown,
          })}
        >
          { children }
        </div>
      </EnteringAnimation>
      { shouldShowNavbar && renderNavbar(isBottomAdShown)}
    </>
  )
}

export default PageLayout
