export const commonMessagesInSpanish = {
  settings_language: 'Idioma',

  ads_advertisement_title: 'Publicidad',
  ads_hide: 'Ocultar',

  accept_button: 'Aceptar',

  stops_favs_title: 'Paradas guardadas',
  stops_no_favorites: `No tienes paradas guardadas.
    Cuando consultes una parada, pulsa el botón "Guardar parada"
    para que aparezca aquí.`,
  stop_favs_error: 'No se ha podido obtener las paradas guardadas. Por favor, reintenta de nuevo.',
  stops_favs_sort_start: 'Ordenar',
  stops_favs_sort_finish: 'Finalizar',

  chat_card_secondary: '¿Necesitas ayuda?',
  chat_card_primary: 'ESCRÍBENOS POR CHAT',

  share_app_modal_title: 'Compartir la app',
  share_app_modal_text_one: 'Envía este enlace a tus amigos:',
  share_app_modal_copy_clipboard: 'Copiar en el portapapeles',
  share_app_modal_whatsapp: 'Enviar por WhatsApp',
  share_app_modal_telegram: 'Enviar por Telegram',

  install_card_secondary_one: 'Nuestra app es más cómoda ',
  install_card_secondary_two: 'de usar y pesa muy poco',
  install_card_primary: 'INSTALAR GRATIS',

  rate_app_card_secondary: 'Échanos una mano',
  rate_app_card_primary: 'PUNTÚA LA APP',

  sync_reminder_secondary: 'Guarda tus paradas en la nube',
  sync_reminder_primary: 'INICIAR SESIÓN',

  action_bar_install_app: 'Instalar app',
  modal_close_btn: 'Cerrar',

  no_internet_error: 'Vaya, parece que no tienes conexión a internet',

  search_tab_panel_by_text: 'Por nº, nombre o línea',
  search_tab_panel_on_map: 'En el mapa',

  stop_search_action_bar_title: 'Buscar parada',
  stop_search_loading: 'Cargando las paradas',
  stop_search_error: `Se he producido un error al recuperar el listado de paradas.
    Por favor, inténtalo de nuevo.`,
  stop_search_by_code: 'Consultar parada por número',
  search_found_stops_title: 'Paradas encontradas',
  search_found_lines_title: 'Líneas encontradas',
  line_search_show_more: 'MOSTRAR {numberOfItems} LÍNEAS MÁS',
  search_show_more_stops: 'MOSTRAR {numberOfItems} PARADAS MÁS',
  search_stops_not_found: 'No se han encontrado paradas con la consulta "{searchQuery}"',
  search_stop_by_unknown_code: 'Consultar parada número {stopCode}',

  stop_map_action_bar_title: 'Buscar parada',
  stop_map_loading: 'Cargando el mapa',
  stop_map_error: 'Se he producido un error al recuperar los datos del mapa. Por favor, inténtalo de nuevo.',
  map_search_places_field_placeholder: 'Buscar lugares en el mapa',
  stop_map_infowindow_lines: 'Líneas: ',
  stop_map_infowindow_go_to_stop: 'Consultar parada',

  stop_action_bar_title: 'Parada',
  stop_action_bar_search_link_title: 'Buscar parada',
  stop_refresh_button: 'Actualizar',
  stop_loading: 'Cargando la parada',
  stop_error: 'Se ha producido un error al obtener los datos de la parada. Por favor, inténtalo de nuevo.',
  stop_fav_arrivals_hint: 'CONSEJO: Para que una línea aparezca siempre arriba, usa su botón "Fijar línea arriba"',
  stop_error_arrivals_sources_btn: '¿Por qué no hay datos?',
  stop_actions_remove_fav: 'Guardada',
  stop_actions_add_fav: 'Guardar parada',
  stop_actions_rename: 'Renombrar',
  stop_actions_show_on_map: 'Ver en mapa',
  stop_departure_direction: 'A',
  stop_departure_platform: 'Vía {platform}',
  stop_departure_distance: '{distance} m',
  stop_departure_no_data: 'actualmente sin datos',
  stop_departure_actions_fav_arrival_add: 'Fijar línea arriba',
  stop_departure_actions_fav_arrival_remove: 'No fijar línea arriba',
  stop_departure_actions_go_to_line: 'Consultar línea',
  stop_departure_actions_show_on_map: 'Ver línea en el mapa',
  arrivals_sources_modal_title: 'Cómo obtenemos los datos',
  arrivals_sources_modal_ok: 'Entendido',
  stop_fav_modal_title: 'Guardar parada',
  stop_fav_modal_select_name: 'Elige un nombre:',
  stop_fav_modal_cancel: 'Cancelar',
  stop_fav_modal_save: 'Guardar',
  stop_fav_rename_modal_title: 'Renombrar parada',
  stop_fav_rename_modal_select_name: 'Elige un nuevo nombre:',
  stop_fav_rename_modal_cancel: 'Cancelar',
  stop_fav_rename_modal_save: 'Guardar',
  stop_remove_fav_modal_title: 'Eliminar parada guardada',
  stop_remove_fav_modal_text: '¿Eliminar esta parada de las paradas guardadas?',
  stop_remove_fav_cancel: 'Cancelar',
  stop_remove_fav_ok: 'Eliminar',

  line_search_action_bar_title: 'Líneas',
  line_search_loading: 'Cargando las líneas',
  line_search_error: 'Se he producido un error al recuperar el listado de líneas. Por favor, inténtalo de nuevo.',
  line_search_field_placeholder: 'Buscar línea por número o nombre',

  line_action_bar_title: 'Línea',
  line_action_bar_search_link_title: 'Buscar línea',
  line_loading: 'Cargando la línea',
  line_error: 'Se ha producido un error al obtener los datos de la línea. Por favor, inténtalo de nuevo.',
  line_show_on_map_btn: 'Ver línea en el mapa',
  line_schedule_btn: 'Horario a {destination}',
  line_stops_title: 'Paradas de la línea',
  line_schedule_modal_title: 'Horario',

  posters_action_bar_title: 'Planos y mapas',
  posters_available_offline: 'Disponible sin internet',
  posters_not_available_offline: 'Necesita conexión a internet',

  settings_action_bar_title: 'Ajustes de la app',
  settings_theme: 'Tema visual',
  settings_theme_use_system: 'Según el tema del sistema',
  settings_theme_light: 'Tema claro',
  settings_theme_dark: 'Tema oscuro',
  settings_more_stuff: 'Más cosas',
  secondary_actions_rate_app: 'Valora la app',
  secondary_actions_use_chat: 'Escríbenos por chat',
  secondary_actions_share_app: 'Comparte la app',

  retry: 'Reintentar',

  auth_user_account: 'Cuenta de usuario',
  auth_logged_out_intro: 'Inicia sesión para guardar tus paradas favoritas en la nube. Así no las perderás si cambias de móvil.',
  auth_login_with_google: 'Iniciar sesión con Google',
  auth_logged_in_with: 'Sesión iniciada con: ',
  auth_logged_in_description: 'Tus paradas favoritas se guardan en la nube de forma segura.',
  auth_logout: 'Cerrar sesión',
  auth_logout_modal_title: 'Cerrar sesión',
  auth_logout_modal_text: '¿Estás seguro de que quieres cerrar sesión?',
  auth_logout_modal_accept: 'Cerrar sesión',
  auth_logout_modal_cancel: 'Cancelar',

  sync_title: 'Sincronizar favoritos',
  sync_intro: 'Tienes paradas guardadas tanto en tu cuenta de usuario como en este dispositivo. ¿Qué quieres hacer con las paradas del dispositivo?',
  sync_btn_upload_local_stop_favs: 'Añadir las paradas del dispositivo a mi cuenta de usuario',
  sync_btn_delete_local_stop_favs: 'Eliminar las paradas del dispositivo y usar solo las de mi cuenta de usuario',
}
