import React, { useState, useEffect } from 'react'
import s from './ResultList.module.scss'

interface SearchResultProps<T> {
  items: T[]
  renderItem: (item: T) => JSX.Element
  searchQuery: string
  buttonTextFn: (numberOfItems: number) => React.ReactNode
  initialShowLimit?: number
}

const defaultInitialShowLimit = 5

function renderMoreButton(
  numberOfItems: number,
  onClick: () => void,
  buttonTextFn: (numberOfItems: number) => React.ReactNode,
) {
  return (
    <button type="button" onClick={onClick} className={s.button}>
      { buttonTextFn(numberOfItems) }
    </button>
  )
}

export default function ResultList<T>({
  initialShowLimit = defaultInitialShowLimit,
  searchQuery,
  items,
  renderItem,
  buttonTextFn,
}: SearchResultProps<T>) {
  const [showAll, setShowAll] = useState<boolean>(false)
  useEffect(() => { setShowAll(false) }, [searchQuery])

  // const finalInitialShowLimit = initialShowLimit || defaultInitialShowLimit
  const itemsToShow = showAll ? items : items.slice(0, initialShowLimit)
  const initiallyHiddenItems = items.length - initialShowLimit

  return (
    <>
      { itemsToShow.map((item) => renderItem(item)) }
      {
        (!showAll && initiallyHiddenItems > 0)
          ? renderMoreButton(
            initiallyHiddenItems, () => setShowAll(true), buttonTextFn,
          ) : undefined
      }
    </>
  )
}
