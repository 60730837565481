import {
  LinePageMetadata, LinePageMetadataOptions,
  PageMetadata, StopPageMetadata, StopPageMetadataOptions,
} from 'common/core/models/page-metadata-models'
import { PageId } from '../models/app-models'
import { StopTypeNumCode, TransportTypeNumCode } from '../../stops/models/stop-models'

const stopPageMetadata: {[key in StopTypeNumCode]: StopPageMetadata} = {
  [StopTypeNumCode.Bus]: {
    title: ({ stopCode }) => `Parada de buses ${stopCode} | Cuánto tarda mi bus`,
    description: ({ stopCode }) => (
      `Consulta cuánto tiempo le queda a tu bus para llegar a la parada ${stopCode}`
    ),
  },
}

const linePageMetadata: {[key in TransportTypeNumCode]: LinePageMetadata} = {
  [TransportTypeNumCode.Bus]: {
    title: ({ lineNumber, lineName }) => `Horarios bus ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) => (
      `Consulta los horarios y las paradas de la línea de bus ${lineNumber}: ${lineName}`
    ),
  },
}

export const pageMetadataCollection: {[key in PageId]: PageMetadata} = {
  [PageId.HomePage]: {
    title: () => (
      'OkTransit Bilbao | Cuánto tarda mi bus'
    ),
    description: () => (
      'Consulta cuánto tiempo le queda a tu bus para llegar a la parada.'
    ),
  },
  [PageId.StopPage]: {
    title: (options: StopPageMetadataOptions) => (
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].title(options)
    ),
    description: (options: StopPageMetadataOptions) => (
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].description(options)
    ),
  },
  [PageId.LinesPage]: {
    title: () => (
      'Líneas de buses de Bilbao'
    ),
    description: () => (
      'Consulta paradas y horarios de las líneas de buses de Bilbao.'
    ),
  },
  [PageId.LinePage]: {
    title: (options: LinePageMetadataOptions) => (
      linePageMetadata[options.transportTypeCode as TransportTypeNumCode].title(options)
    ),
    description: (options: LinePageMetadataOptions) => (
      linePageMetadata[options.transportTypeCode as TransportTypeNumCode].description(options)
    ),
  },
  [PageId.SearchPage]: {
    title: () => (
      'Buscar parada | OK Transit Bilbao'
    ),
    description: () => (
      `Encuentra tu parada por su nombre o número y consulta cuándo tarda tu bus en llegar.
      Tiempo de espera de todos los buses de Bilbao`
    ),
  },
  [PageId.StopMapPage]: {
    title: () => (
      'Mapa de paradas | OK Transit Bilbao'
    ),
    description: () => (
      `Encuentra tu parada en el mapa y consulta cuánto le queda a tu bus para llegar.
      Tiempo de espera de todos los buses de Bilbao`
    ),
  },

  [PageId.PostersPage]: {
    title: () => (
      'Plano y mapas de transporte de Bilbao'
    ),
    description: () => (
      'Planos y mapas actualizados del transporte de Bilbao en alta definición.'
    ),
  },

  [PageId.BusLinesPosterPage]: {
    title: () => (
      'Plano de líneas de buses de Bilbao actualizado'
    ),
    description: () => (
      'Consulta el plano de líneas de buses de Bilbao actualizado en alta definición.'
    ),
  },
  [PageId.BusStopsPosterPage]: {
    title: () => (
      'Plano de paradas de buses de Bilbao actualizado'
    ),
    description: () => (
      'Consulta el plano de paradas de buses de Bilbao actualizado en alta definición.'
    ),
  },
  [PageId.NocturnalBusesPosterPage]: {
    title: () => (
      'Plano de buses nocturnos de Bilbao actualizado'
    ),
    description: () => (
      'Consulta el plano de buses nocturnos de Bilbao actualizado en alta definición.'
    ),
  },
}
