import { createContext, useContext } from 'react'
import { BrandConfig } from '../models/brand-config.models'

function createBrandConfigContext() {
  const Context = createContext<BrandConfig | null>(null)
  Context.displayName = 'BrandConfigContext'
  return Context
}

export const BrandConfigContext = createBrandConfigContext()

export default function useBrandConfigContext() {
  const context = useContext(BrandConfigContext)
  if (!context) {
    throw new Error('BrandConfigContext is not provided.')
  }
  return context
}
