import React from 'react'
import { AppModal, AppModalSize } from 'common/ui/components/AppModal/AppModal'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { useIntl } from 'react-intl'
import s from 'common/ui/components/modals/modals.module.scss'

interface NoDataModalProps {
  onAccept: () => void
  isOpen: boolean
}

const ArrivalsSourcesModal: React.FC<NoDataModalProps> = ({ isOpen, onAccept }) => {
  const {
    arrivalsSourcesModalContent: ArrivalsSourcesModalContent,
  } = useBrandConfigContext().stops.components
  const intl = useIntl()
  return (
    <AppModal
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'arrivals_sources_modal_title' })}
      shouldShowCloseButton
      onRequestClose={onAccept}
      maxWidth={AppModalSize.Normal}
    >
      <ArrivalsSourcesModalContent />
      <div className={s.buttonsContainer}>
        <button type="button" onClick={() => onAccept()} className={s.button}>
          {intl.formatMessage({ id: 'arrivals_sources_modal_ok' })}
        </button>
      </div>
    </AppModal>
  )
}

export default ArrivalsSourcesModal
