import axios from 'axios'
import { LineListItem } from 'common/lines/models/line-list-models'

export class LineListApiClient {
  constructor(
    private apiGateway: string,
  ) {}

  public async getLineList(): Promise<LineListItem[]> {
    const url = this.apiGateway
    const response = await axios.get<LineListItem[]>(url, {
      timeout: 15 * 1000,
    })
    return response.data
  }
}
