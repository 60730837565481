import React from 'react'
import s from './Container.module.scss'

const Container: React.FC = ({ children }) => (
  <div className={s.root}>
    { children }
  </div>
)

export default Container
