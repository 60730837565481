import React from 'react'
import { SearchableLine } from 'common/search/models/search-models'
import ResultList from 'common/search/components/ResultList/ResultList'
import { useIntl } from 'react-intl'
import FoundRealLine from '../FoundRealLine/FoundRealLine'

interface Props {
  foundSearchableLines: SearchableLine[],
  searchQuery: string,
}

const FoundRealLines: React.FC<Props> = ({ foundSearchableLines, searchQuery }) => {
  const intl = useIntl()
  return (
    <ResultList
      items={foundSearchableLines}
      searchQuery={searchQuery}
      renderItem={({ item }) => (
        <FoundRealLine lineListItem={item} key={item.lineCode} />
      )}
      buttonTextFn={
        (numberOfItems) => intl.formatMessage(
          { id: 'line_search_show_more' },
          { numberOfItems },
        )
      }
    />
  )
}

export default FoundRealLines
