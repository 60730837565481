import React from 'react'
import { ReactComponent as ArrowRightIcon } from 'common/images/keyboard_arrow_right-24px.svg'
import classNames from 'classnames'
import s from './CardLink.module.scss'

interface CardLinkProps {
  leftChildren: React.ReactNode
  className?: string
}

const CardLink: React.FC<CardLinkProps> = (props) => (
  <div className={classNames(s.root, props.className)}>
    <div className={s.leftPart}>
      {props.leftChildren}
    </div>
    <div className={s.rightPart}>
      { props.children }
    </div>
    <ArrowRightIcon className={s.arrow} />
  </div>
)

export default CardLink
