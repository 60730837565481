import { createContext, useContext } from 'react'
import { BottomAdContextModel } from '../models/BottomAdContext'

function createBottomAdContext() {
  const Context = createContext<BottomAdContextModel | null>(null)
  Context.displayName = 'BottomAdContext'
  return Context
}

export const BottomAdContext = createBottomAdContext()

export default function useBottomAdContext() {
  const context = useContext(BottomAdContext)
  if (!context) {
    throw new Error('BottomAdContext is not provided.')
  }
  return context
}
