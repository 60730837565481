import React from 'react'
import { Link } from 'react-router-dom'
import { OpStatus } from 'common/ui/models/ui-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import ErrorView from 'common/ui/components/aux-views/ErrorView'
import NoInternetError from 'common/ui/components/aux-views/NoInternetError'
import { isNoInternetError } from 'common/shared/helpers/network-utils'
import Container from 'common/ui/components/Container/Container'
import { ReactComponent as SearchIcon } from 'common/images/search-24px.svg'
import { LineModel } from 'common/lines/models/line-models'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import EnteringAnimation from 'common/ui/components/EnteringAnimation/EnteringAnimation'
import Line from 'common/lines/components/Line/Line'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { LinePageMetadataOptions } from 'common/core/models/page-metadata-models'
import { FormattedMessage, useIntl } from 'react-intl'

interface LinePageViewProps {
  retrieveLineStatus: OpStatus | undefined
  retrieveLineError: Error | undefined
  line: LineModel | undefined
  onRefreshButtonClick: () => void
}

const renderPageMetada = (line: LineModel, stopConfig: StopsBrandConfig) => {
  const options: LinePageMetadataOptions = {
    lineName: line.lineName,
    lineNumber: line.lineNumber,
    transportTypeCode: line.transportTypeCode,
  }
  return (
    <PageMetadata
      pageId={stopConfig.pageIds.line}
      options={options}
    />
  )
}

const renderSuccessView = (props: LinePageViewProps, stopConfig: StopsBrandConfig) => {
  if (props.retrieveLineStatus === OpStatus.Success && props.line) {
    return (
      <>
        { renderPageMetada(props.line, stopConfig) }
        <EnteringAnimation>
          <Line line={props.line} />
        </EnteringAnimation>
      </>
    )
  }
  return undefined
}

const renderLoadingView = (props: LinePageViewProps) => {
  if (props.retrieveLineStatus === OpStatus.InProgress) {
    return <LoadingView><FormattedMessage id="line_loading" /></LoadingView>
  }
  return undefined
}

const renderNoInternetError = (props: LinePageViewProps) => (
  <NoInternetError onRetryButtonClick={props.onRefreshButtonClick} />
)

const renderNoStopDataError = (props: LinePageViewProps) => {
  const text = <FormattedMessage id="line_error" />
  return (
    <ErrorView mainText={text} onRetryButtonClick={props.onRefreshButtonClick} />
  )
}

const renderErrorView = (props: LinePageViewProps) => {
  if (props.retrieveLineStatus === OpStatus.Failed) {
    if (isNoInternetError(props.retrieveLineError!)) {
      return renderNoInternetError(props)
    }
    return renderNoStopDataError(props)
  }
  return undefined
}

const LinePageView: React.FC<LinePageViewProps> = (props) => {
  const stopsConfig = useBrandConfigContext().stops
  const intl = useIntl()

  const actionBarRightSection = (
    <>
      <Link
        to={stopsConfig.urls.getLinesUrl()}
        title={intl.formatMessage({ id: 'line_action_bar_search_link_title' })}
        className="globalActionBarIcon"
      >
        <SearchIcon />
      </Link>
    </>
  )
  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={<FormattedMessage id="line_action_bar_title" />}
          backLinkPath={stopsConfig.urls.getLinesUrl()}
          rightSection={actionBarRightSection}
        />
      )}
    >
      <Container>
        { renderLoadingView(props) }
        { renderErrorView(props) }
        { renderSuccessView(props, stopsConfig) }
      </Container>
    </PageLayout>
  )
}

export default LinePageView
