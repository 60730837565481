import React, { useState } from 'react'
import { AppModal } from 'common/ui/components/AppModal/AppModal'
import { FormattedMessage, useIntl } from 'react-intl'
import useTransManagerContext from 'common/i18n/hooks/useTransManagerContext'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { ClientType } from 'common/core/models/param-app-config-models'
import s from 'common/ui/components/modals/modals.module.scss'
import LocaleSelector from '../LocaleSelector/LocaleSelector'

const I18nModal = () => {
  const intl = useIntl()
  const {
    localeSelectedByUser, usedLocale, allowToShowLocaleSelectionModal, changeLocale,
  } = useTransManagerContext()
  const commonConfig = useBrandConfigContext().common

  const shouldShowModal = allowToShowLocaleSelectionModal
    && commonConfig.services.paramAppConfig.clientType !== ClientType.Web
    && !localeSelectedByUser

  const [selectedLocale, setSelectedLocale] = useState(usedLocale)

  function handleLocaleSelect(locale: string) {
    setSelectedLocale(locale)
  }

  function handleAccept() {
    changeLocale(selectedLocale)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'selectLocale',
      eventAction: selectedLocale,
      eventLabel: 'initialI18nModal',
    })
  }

  return (
    <AppModal
      isOpen={shouldShowModal}
      shouldCloseOnOverlayClick={false}
      title={intl.formatMessage({ id: 'settings_language' })}
      shouldShowCloseButton={false}
    >
      <LocaleSelector
        selectedLocale={selectedLocale}
        onLocaleSelect={handleLocaleSelect}
      />
      <div className={s.buttonsContainer}>
        <button type="button" onClick={handleAccept} className={s.button}>
          <FormattedMessage id="accept_button" />
        </button>
      </div>
    </AppModal>
  )
}

export default I18nModal
