import React from 'react'
import { ReactComponent as BusIcon } from '../images/stop-bus.svg'
import { StopTypeNumCode } from '../models/stop-models'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: {[key in StopTypeNumCode]: () => JSX.Element } = {
  [StopTypeNumCode.Bus]: () => <BusIcon width="32px" height="32px" />,
}

const BigStopIcon: React.FC<StopIconProps> = ({ stopTypeCode }) => {
  const iconRenderingFunction = mapping[stopTypeCode as StopTypeNumCode]
  if (iconRenderingFunction) {
    return iconRenderingFunction()
  }
  return <></>
}

export default BigStopIcon
