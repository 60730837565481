import { commonMessagesInEnglish } from 'common/i18n/helpers/common-translations-en.helpers'
import { commonMessagesInSpanish } from 'common/i18n/helpers/common-translations-es.helpers'

const messagesInSpanish = {
  ...commonMessagesInSpanish,

  navbar_stops: 'Paradas',
  navbar_lines: 'Líneas',
  navbar_posters: 'Planos',
  navbar_settings: 'Ajustes',

  share_app_text: 'Prueba la app OK Transit Bilbao',

  /* Home */
  home_intro_one: 'Selecciona una parada',
  home_intro_two: 'para consultar cuándo llega a ella tu bus',
  home_stop_search_button_one: 'Buscar parada',
  home_stop_search_button_two: 'por nº o nombre',
  home_map_search_button_one: 'Buscar parada',
  home_map_search_button_two: 'en el mapa',

  home_popular_stops: 'Paradas populares',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `Ej. '5105', 'gran via' o 'bus 56'`,

  found_line_description_bus: 'Bus {lineNumber}',
  found_line_description_other: 'Línea {lineNumber}',

  line_description_bus: 'Línea de bus',
  line_description_other: 'Línea de transporte',

  stop_description_bus: 'Parada de buses',
  stop_description_other: 'Parada',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Líneas',

  next_arrivals_title_buses: 'Próximos buses',
  next_arrivals_title_trains: 'Próximos trenes',

  posters_bus_lines: 'Mapa de líneas de bus',
  posters_bus_stops: 'Mapa de paradas de bus',
  posters_nocturnal_buses: 'Mapa de buses nocturnos',
}

const messagesInEnglish = {
  ...commonMessagesInEnglish,

  navbar_stops: 'Stops',
  navbar_lines: 'Lines',
  navbar_posters: 'Maps',
  navbar_settings: 'Settings',

  share_app_text: 'Try the app "OK Transit Bilbao"',

  /* Home */
  home_intro_one: 'Select a stop to check',
  home_intro_two: 'when your bus arrives there',
  home_stop_search_button_one: 'Search stop',
  home_stop_search_button_two: 'by number or name',
  home_map_search_button_one: 'Search stop',
  home_map_search_button_two: 'on the map',

  home_popular_stops: 'Popular stops',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `E.g. '5105', 'gran via' o 'bus 56'`,

  found_line_description_bus: 'Bus {lineNumber}',
  found_line_description_other: 'Line {lineNumber}',

  line_description_bus: 'Bus line',
  line_description_other: 'Transport line',

  stop_description_bus: 'Bus stop',
  stop_description_other: 'Stop',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Lines',

  next_arrivals_title_buses: 'Next buses',
  next_arrivals_title_trains: 'Next trains',

  posters_bus_lines: 'Bus network lines map',
  posters_bus_stops: 'Bus network stops map',
  posters_nocturnal_buses: 'Night bus network map',
}

export const translationGroups = {
  es: messagesInSpanish,
  en: messagesInEnglish,
}
