import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { getStopListItemStopTypeCode } from 'common/search/helpers/stop-list-helpers'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import s from './SearchResultStop.module.scss'

interface SearchResultStopProps {
  stopListItem: StopListItem
  isVisited: boolean
}

function renderLines(lines: string[], stopLinesDescription: React.ReactNode) {
  return (
    <div className={s.lines}>
      {stopLinesDescription}: { lines.join(', ') }
    </div>
  )
}

const SearchResultStop: React.FC<SearchResultStopProps> = ({ stopListItem, isVisited }) => {
  const stopTypeNumCode = getStopListItemStopTypeCode(stopListItem)
  const lines = stopListItem[StopListItemIndex.LineNumbers]
  const { urls, helpers, components } = useBrandConfigContext().stops
  const { stopIcon: StopIconComponent } = components
  const stopLinesDescription = helpers.getStopLinesDescriptionInSearch(stopTypeNumCode)
  return (
    <>
      <Link
        to={urls.getStopUrl(stopListItem[StopListItemIndex.StopCode])}
        className={classNames({ [s.stop]: true, [s.visited]: isVisited })}
      >
        <div className={s.stopIcon}>
          <StopIconComponent stopTypeCode={stopTypeNumCode} />
        </div>
        <div className={s.content}>
          <div className={s.stopName}>{stopListItem[StopListItemIndex.StopName]}</div>
          { lines ? renderLines(lines, stopLinesDescription) : undefined }
        </div>
        <div className={s.stopCode}>{stopListItem[StopListItemIndex.StopCode]}</div>
      </Link>
    </>
  )
}

export default SearchResultStop
