import { StopCodeWrapperInterface } from 'common/stops/services/StopCodeWrapperInterface'
import { StopTypeNumCode } from '../models/stop-models'

export class CustomStopCodeWrapper implements StopCodeWrapperInterface {
  constructor(private customStopCode: string) {}

  private isBusStop() {
    return !this.customStopCode.includes('-')
  }

  public getStopTypeCode(): string {
    if (this.isBusStop()) {
      return StopTypeNumCode.Bus
    }
    return this.customStopCode.split('-')[0] as StopTypeNumCode
  }
}
