import React from 'react'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { LineTheme } from 'common/lines/models/line-style-models'
import s from './LineNumber.module.scss'

const getLineNumberFontClassName = (props: LineNumberComponentProps): string | undefined => {
  if (props.lineNumber.length > 3) {
    return s.smallFont
  }
  return undefined
}

function buildLineStyle(lineStyle: LineTheme): React.CSSProperties {
  if (lineStyle.outlined) {
    return {
      border: `1px solid ${lineStyle.primary}`,
      color: lineStyle.primary,
      background: 'transparent',
    }
  }
  return {
    color: lineStyle.secondary || 'white',
    background: lineStyle.primary,
  }
}

interface LineNumberComponentProps {
  lineNumber: string
  transportTypeCode: string
  isNocturnal: boolean
}

const LineNumber: React.ComponentType<LineNumberComponentProps> = (props) => {
  const { stops: { helpers: { buildLineTheme } } } = useBrandConfigContext()
  const lineTheme = buildLineTheme(props)
  const cssStyle = buildLineStyle(lineTheme)
  return (
    <div className={getLineNumberFontClassName(props)}>
      <div style={cssStyle} className={s.lineNumberLabel}>
        {props.lineNumber}
      </div>
    </div>
  )
}

export default LineNumber
