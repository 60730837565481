import React from 'react'
import s from './ExternalSectionTitle.module.scss'

interface ExternalSectionTitleProps {
  title: React.ReactNode
}

const ExternalSectionTitle: React.FC<ExternalSectionTitleProps> = (props) => (
  <div className={s.container}>
    <h2 className={s.title}>{ props.title }</h2>
    <div>{ props.children }</div>
  </div>
)

export default ExternalSectionTitle
