import React from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import ErrorView from 'common/ui/components/aux-views/ErrorView'
import NoInternetError from 'common/ui/components/aux-views/NoInternetError'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import { isNoInternetError } from 'common/shared/helpers/network-utils'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import { LineListItem } from 'common/lines/models/line-list-models'
import { StopListItem } from 'common/stops/models/stop-list-models'
import SearchTabPanel from 'common/search/components/SearchTabPanel/SearchTabPanel'
import Search from 'common/search/components/Search/Search'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage, useIntl } from 'react-intl'

interface SearchPageViewProps {
  retrieveStatus?: OpStatus
  retrieveError?: Error
  stopList: StopListItem[] | undefined
  lineList: LineListItem[] | undefined
  defaultQuery?: string
  onRetryFetchButtonClick: () => void
}

const renderLoading = () => (
  <LoadingView><FormattedMessage id="stop_search_loading" /></LoadingView>
)

const renderNoInternetError = (props: SearchPageViewProps) => (
  <NoInternetError onRetryButtonClick={props.onRetryFetchButtonClick} />
)

const renderNoStopsDataError = (props: SearchPageViewProps) => {
  const text = <FormattedMessage id="stop_search_error" />
  return (
    <ErrorView mainText={text} onRetryButtonClick={props.onRetryFetchButtonClick} />
  )
}

const renderErrorView = (props: SearchPageViewProps) => {
  if (props.retrieveStatus === OpStatus.Failed) {
    if (isNoInternetError(props.retrieveError!)) {
      return renderNoInternetError(props)
    }
    return renderNoStopsDataError(props)
  }
  return undefined
}

const SearchPageView: React.FC<SearchPageViewProps> = (props) => {
  const intl = useIntl()
  const { stops, common } = useBrandConfigContext()
  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={intl.formatMessage({ id: 'stop_search_action_bar_title' })}
          backLinkPath={common.urls.getRootUrl()}
        />
      )}
    >
      <PageMetadata pageId={stops.pageIds.stopSearch} />
      <SearchTabPanel isMapSelected={false} />
      <Container>
        { props.retrieveStatus === OpStatus.InProgress ? renderLoading() : undefined }
        { renderErrorView(props) }
        { props.retrieveStatus === OpStatus.Success
          ? (
            <Search
              stopList={props.stopList!}
              lineList={props.lineList!}
              defaultQuery={props.defaultQuery}
            />
          ) : undefined }
      </Container>
    </PageLayout>
  )
}

export default SearchPageView
