import classNames from 'classnames'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import React from 'react'
import { NavLink } from 'react-router-dom'
import s from './Navbar.module.scss'

const Navbar: React.FC = () => {
  const { navbar } = useBrandConfigContext().common
  return (
    <div className={s.root}>
      <div className={s.navbarContainer}>
        <ul className={s.menu}>
          {
            navbar.items.map((item) => (
              <li key={item.url}>
                <NavLink
                  to={item.url}
                  className={classNames({
                    [s.link]: true,
                    [s.notification]: item.isSettingsUrl,
                  })}
                  activeClassName={s.active}
                >
                  { item.icon }
                  { item.text }
                </NavLink>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default Navbar
