import React from 'react'
import classNames from 'classnames'
import CardLink from 'common/ui/components/CardLink/CardLink'
import { ClientType } from 'common/core/models/param-app-config-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as CloudIcon } from './cloud_upload_black_24dp.svg'
import s from './SyncReminderCard.module.scss'

interface Props {
  className?: string
}

function sendEvent() {
  window.ga('send', {
    hitType: 'event',
    eventCategory: 'syncReminderCardClick',
  })
}

const SyncReminderCard: React.FC<Props> = (props) => {
  const { common: commonConfig, stops: stopsConfig } = useBrandConfigContext()
  if (commonConfig.services.paramAppConfig.clientType === ClientType.IOS
    || commonConfig.services.appUsageTracker.loginUsed()
    || stopsConfig.services.stopFavsStore.getStopFavs().length === 0) {
    return <></>
  }
  const leftChildren = (
    <div className={s.appIcon}>
      <CloudIcon />
    </div>
  )
  const smallTextClassname = classNames('globalInlineBlock', s.smallText)
  return (
    <Link
      to={commonConfig.urls.getSettingsUrl()}
      className={classNames(s.root, props.className)}
      onClick={sendEvent}
    >
      <CardLink leftChildren={leftChildren}>
        <span className={smallTextClassname}>
          <FormattedMessage id="sync_reminder_secondary" />
        </span>
        <div className={s.button}>
          <FormattedMessage id="sync_reminder_primary" />
        </div>
      </CardLink>
    </Link>
  )
}

export default SyncReminderCard
