import React from 'react'
import { AppModal } from 'common/ui/components/AppModal/AppModal'
import { useIntl } from 'react-intl'
import s from 'common/ui/components/modals/modals.module.scss'

interface RemoveFavoriteModalProps {
  onAccept: () => void
  onCancel: () => void
  isOpen: boolean
}

const RemoveFavoriteModal = (props: RemoveFavoriteModalProps) => {
  const intl = useIntl()
  return (
    <AppModal
      isOpen={props.isOpen}
      title={intl.formatMessage({ id: 'stop_remove_fav_modal_title' })}
      shouldShowCloseButton={false}
      onRequestClose={props.onCancel}
    >
      {intl.formatMessage({ id: 'stop_remove_fav_modal_text' })}
      <div className={s.buttonsContainer}>
        <button type="button" onClick={() => props.onCancel()} className={s.button}>
          {intl.formatMessage({ id: 'stop_remove_fav_cancel' })}
        </button>
        <button type="button" onClick={() => props.onAccept()} className={s.button}>
          {intl.formatMessage({ id: 'stop_remove_fav_ok' })}
        </button>
      </div>
    </AppModal>
  )
}

export default RemoveFavoriteModal
