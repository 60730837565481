import React from 'react'
import { getStopListItemStopTypeCode } from 'common/search/helpers/stop-list-helpers'
import normalizeForSearch from 'normalize-for-search'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'
import StopMap from 'common/maps/components/StopMap/StopMap'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

interface Props {
  stopList: StopListItem[]
  googleMapsLib: typeof google.maps
  lineNumber: string
  stopTypeCode: string
}

function calculateBounds(
  googleMapsLib: typeof google.maps,
  stops: StopListItem[],
): google.maps.LatLngBounds {
  const bounds = new googleMapsLib.LatLngBounds()
  stops.forEach((stop) => {
    bounds.extend({
      lat: stop[StopListItemIndex.Lat],
      lng: stop[StopListItemIndex.Long],
    })
  })
  return bounds
}

function filterStopsByLine(
  stops: StopListItem[],
  stopTypeCode: string,
  lineNumber: string,
) {
  const normalizedLineNumber = normalizeForSearch(lineNumber)
  return stops.filter((stop) => {
    const normalizedStopLineNumbers = stop[StopListItemIndex.LineNumbers].map((number) => (
      normalizeForSearch(number)
    ))
    return getStopListItemStopTypeCode(stop) === stopTypeCode
      && normalizedStopLineNumbers.includes(normalizedLineNumber)
  })
}

const LineStopMap: React.FC<Props> = (props) => {
  const { stops: stopsBrandConfig } = useBrandConfigContext()
  const lineStops = filterStopsByLine(props.stopList!, props.stopTypeCode, props.lineNumber)
  const bounds = calculateBounds(props.googleMapsLib!, lineStops)
  const { defaultMapCenter } = stopsBrandConfig.helpers
  return (
    <StopMap
      googleMapsLib={props.googleMapsLib!}
      stopList={lineStops}
      enableClustering={false}
      mapCenter={defaultMapCenter}
      mapZoom={11}
      boundsToFit={bounds}
    />
  )
}

export default LineStopMap
