import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { ReactComponent as ArrowRightIcon } from 'common/images/keyboard_arrow_right-24px.svg'
import { ReactComponent as MoreIcon } from 'common/images/arrow_drop_down-24px.svg'
import { StopDeparture, LineDirection } from 'common/stops/models/stop-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage } from 'react-intl'
import LineNumber from 'common/lines/components/LineNumber/LineNumber'
import s from './DepartureGroup.module.scss'

export interface DepartureGroup {
  lineCode?: string
  lineBound: string
  lineNumber: string
  lineDirection?: LineDirection
  transportTypeCode: string
  isNocturnal: boolean
  departures: StopDeparture[]
}

interface DepartureGroupProps {
  group: DepartureGroup
  isFavorite: boolean
  onCreateFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void
  onRemoveFavoriteArrival: (lineCode: string, lineDir: LineDirection) => void
}

const renderPlatform = (departure: StopDeparture) => (
  <div className={s.lineAux}>
    <FormattedMessage
      id="stop_departure_platform"
      values={{ platform: departure.platform }}
    />
  </div>
)

const renderDistance = (departure: StopDeparture) => (
  <div className={s.lineAux}>
    <FormattedMessage
      id="stop_departure_distance"
      values={{ distance: departure.distanceM }}
    />
  </div>
)

const renderDeparture = (departure: StopDeparture, index: number) => (
  <div key={index} className={s.lineTime}>
    {departure.departureTime}
    { departure.platform ? renderPlatform(departure) : undefined }
    { departure.distanceM !== undefined ? renderDistance(departure) : undefined }
  </div>
)

const renderDepartures = (group: DepartureGroup) => {
  if (group.departures.length === 0) {
    return (
      <div className={s.noData}>
        <FormattedMessage id="stop_departure_no_data" />
      </div>
    )
  }
  return group.departures.map((departure, index) => renderDeparture(departure, index))
}

const renderLineBound = (lineBound: string) => {
  const parts = lineBound.split('▶')
  return parts.map((part, index) => {
    if (index === parts.length - 1) {
      return part
    }
    return (
      <span key={part} className={s.lineBoundPart}>
        {part} <ArrowRightIcon />
      </span>
    )
  })
}

function renderFavoriteArrivalButton(props: DepartureGroupProps) {
  const { lineCode, lineDirection } = props.group
  let createFavButton
  if (!props.isFavorite) {
    createFavButton = (
      <button
        type="button"
        onClick={() => props.onCreateFavoriteArrival(lineCode!, lineDirection!)}
        className={s.action}
      >
        <FormattedMessage id="stop_departure_actions_fav_arrival_add" />
      </button>
    )
  }
  let removeFavButton
  if (props.isFavorite) {
    removeFavButton = (
      <button
        type="button"
        onClick={() => props.onRemoveFavoriteArrival(lineCode!, lineDirection!)}
        className={s.action}
      >
        <FormattedMessage id="stop_departure_actions_fav_arrival_remove" />
      </button>
    )
  }
  return (
    <>
      { createFavButton }
      { removeFavButton }
    </>
  )
}

function renderLineLink(lineCode: string, stopsConfig: StopsBrandConfig) {
  return (
    <Link to={stopsConfig.urls.getLineUrl(lineCode)} className={s.action}>
      <FormattedMessage id="stop_departure_actions_go_to_line" />
    </Link>
  )
}

function renderActions(props: DepartureGroupProps, stopsConfig: StopsBrandConfig) {
  const stopTypeNumCode = stopsConfig.helpers
    .getStopTypeCodeFromTransportTypeCode(props.group.transportTypeCode)
  const lineMapUrl = stopsConfig.urls.getStopsMapUrl(
    undefined, stopTypeNumCode, props.group.lineNumber,
  )
  const { lineCode, lineDirection, transportTypeCode } = props.group
  const isLineAvailable = stopsConfig.helpers.transportTypeCodesWithRealLines
    .includes(transportTypeCode)
  return (
    <div className={s.lineActions}>
      {
        lineCode && lineDirection
          ? renderFavoriteArrivalButton(props)
          : undefined
      }
      {
        isLineAvailable && lineCode
          ? renderLineLink(lineCode, stopsConfig)
          : undefined
      }
      {
        !isLineAvailable && (
          <Link to={lineMapUrl} className={s.action}>
            <FormattedMessage id="stop_departure_actions_show_on_map" />
          </Link>
        )
      }
    </div>
  )
}

const DepartureGroupComponent: React.FC<DepartureGroupProps> = (props) => {
  const { stops: stopsConfig } = useBrandConfigContext()
  const { group } = props
  const [showActions, setShowActions] = useState<boolean>(false)

  function toggleShowActions() {
    setShowActions(!showActions)
  }

  return (
    <div className={s.departureGroup}>
      <button type="button" onClick={toggleShowActions} className={s.departureButton}>
        <div className={s.line}>
          <div className={s.lineNumber}>
            <LineNumber
              lineNumber={group.lineNumber}
              transportTypeCode={group.transportTypeCode}
              isNocturnal={group.isNocturnal}
            />
          </div>
          <div className={s.centralSection}>
            <div className={s.lineBound}>
              <FormattedMessage id="stop_departure_direction" />&nbsp;
              {renderLineBound(group.lineBound)}
            </div>
            <div className={s.departures}>
              { renderDepartures(group) }
            </div>
          </div>
          <MoreIcon
            className={classNames({ [s.moreIcon]: true, [s.rotated]: showActions })}
          />
        </div>
      </button>
      { showActions ? renderActions(props, stopsConfig) : undefined }
    </div>
  )
}

export default DepartureGroupComponent
