import React from 'react'
import { Spinner, SpinnerSize } from 'common/ui/components/Spinner/Spinner'
import s from './aux-views.module.scss'
import FullHeightContainer from './FullHeightContainer'

interface Props {
  spinnerSize?: SpinnerSize
}

const LoadingView: React.FC<Props> = ({ children, spinnerSize }) => (
  <FullHeightContainer>
    <div className={s.container}>
      <Spinner size={spinnerSize} />
      {children && <div className={s.loaderText}>{ children }</div>}
    </div>
  </FullHeightContainer>
)

export default LoadingView
