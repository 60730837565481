export type Zones = {
  stopCode: string
  name: string
}[]

export const plazaCastillaZones: Zones = [
  { stopCode: '18074', name: 'Nivel 0 Calle' },
  { stopCode: '17470', name: 'Nivel -1' },
  { stopCode: '17472', name: 'Nivel -3' },
]

export const principePioZones: Zones = [
  { stopCode: '17051', name: 'Nivel -1' },
  { stopCode: '17052', name: 'Nivel -2' },
]

export const plazaElipticaZones: Zones = [
  { stopCode: '17041', name: 'Nivel -1' },
  { stopCode: '17042', name: 'Nivel -2' },
]

export const moncloaZones: Zones = [
  { stopCode: '06002', name: 'Isla 1' },
  { stopCode: '17480', name: 'Islas 2 y 3' },
]
