export const commonMessagesInEnglish = {
  settings_language: 'Language',

  ads_advertisement_title: 'Advertisement',
  ads_hide: 'Hide',

  accept_button: 'OK',

  stops_favs_title: 'Saved stops',
  stops_no_favorites: `You have no saved stops.
    When viewing a stop, press the "Save Stop" button
    so they appear here.`,
  stop_favs_error: 'No se ha podido obtener las paradas guardadas. Por favor, reintenta de nuevo.',
  stops_favs_sort_start: 'Sort',
  stops_favs_sort_finish: 'Finish',

  chat_card_secondary: '¿Do you need help?',
  chat_card_primary: 'WRITE US BY CHAT',

  share_app_modal_title: 'Share the app',
  share_app_modal_text_one: 'Send this link to your friends:',
  share_app_modal_copy_clipboard: 'Copy to clipboard',
  share_app_modal_whatsapp: 'Send by WhatsApp',
  share_app_modal_telegram: 'Send by Telegram',

  install_card_secondary_one: 'Our app is easier to use ',
  install_card_secondary_two: 'and it\'s really lightweight',
  install_card_primary: 'DOWNLOAD FOR FREE',

  rate_app_card_secondary: 'Help us to improve',
  rate_app_card_primary: 'RATE THE APP',

  sync_reminder_secondary: 'Save your stops in the cloud',
  sync_reminder_primary: 'SIGN IN',

  action_bar_install_app: 'Install app',
  modal_close_btn: 'Close',

  no_internet_error: 'It looks like you don\'t have an internet connection',

  search_tab_panel_by_text: 'By number, name or line',
  search_tab_panel_on_map: 'On the map',

  stop_search_action_bar_title: 'Search stop',
  stop_search_loading: 'Loading',
  stop_search_error: `An error occurred while retrieving the list of stops.
    Please, try again.`,
  stop_search_by_code: 'Search stop by code',
  search_found_stops_title: 'Found stops',
  search_found_lines_title: 'Found lines',
  line_search_show_more: 'SHOW {numberOfItems} MORE LINES',
  search_show_more_stops: 'SHOW {numberOfItems} MORE STOPS',
  search_stops_not_found: 'No stops found with query "{searchQuery}"',
  search_stop_by_unknown_code: 'Go to {stopCode} stop',

  stop_map_action_bar_title: 'Search stop',
  stop_map_loading: 'Loading',
  stop_map_error: 'An error occurred while retrieving the map data. Please, try again.',
  map_search_places_field_placeholder: 'Find places on the map',
  stop_map_infowindow_lines: 'Lines: ',
  stop_map_infowindow_go_to_stop: 'Go to this stop',

  stop_action_bar_title: 'Stop',
  stop_action_bar_search_link_title: 'Search stop',
  stop_refresh_button: 'Refresh',
  stop_loading: 'Loading',
  stop_error: 'An error occurred while getting the stop data. Please, try again.',
  stop_fav_arrivals_hint: 'TIP: To make a line always appear on top, use its button "Pin line"',
  stop_error_arrivals_sources_btn: 'Why is there no data?',
  stop_actions_remove_fav: 'Saved',
  stop_actions_add_fav: 'Save stop',
  stop_actions_rename: 'Rename',
  stop_actions_show_on_map: 'Show on map',
  stop_departure_direction: 'To',
  stop_departure_platform: 'Platform {platform}',
  stop_departure_distance: '{distance} m',
  stop_departure_no_data: 'currently no data',
  stop_departure_actions_fav_arrival_add: 'Pin line',
  stop_departure_actions_fav_arrival_remove: 'Unpin line',
  stop_departure_actions_go_to_line: 'Show line details',
  stop_departure_actions_show_on_map: 'Show line on the map',
  arrivals_sources_modal_title: 'How we obtain the data',
  arrivals_sources_modal_ok: 'Got it',
  stop_fav_modal_title: 'Save stop',
  stop_fav_modal_select_name: 'Enter an alias:',
  stop_fav_modal_cancel: 'Cancel',
  stop_fav_modal_save: 'Save',
  stop_fav_rename_modal_title: 'Rename stop',
  stop_fav_rename_modal_select_name: 'Enter a new alias:',
  stop_fav_rename_modal_cancel: 'Cancel',
  stop_fav_rename_modal_save: 'Save',
  stop_remove_fav_modal_title: 'Delete saved stop',
  stop_remove_fav_modal_text: 'Are you sure to delete this stop from saved stops?',
  stop_remove_fav_cancel: 'Cancel',
  stop_remove_fav_ok: 'Delete',

  line_search_action_bar_title: 'Lines',
  line_search_loading: 'Loading',
  line_search_error: 'An error occurred while retrieving the list of lines. Please, try again.',
  line_search_field_placeholder: 'Search line by number or name',

  line_action_bar_title: 'Line',
  line_action_bar_search_link_title: 'Search line',
  line_loading: 'Loading',
  line_error: 'An error occurred while getting the line data. Please, try again.',
  line_show_on_map_btn: 'Show line on the map',
  line_schedule_btn: 'Schedule to {destination}',
  line_stops_title: 'Line stops',
  line_schedule_modal_title: 'Schedule',

  posters_action_bar_title: 'Pocket Maps',
  posters_available_offline: 'Available offline',
  posters_not_available_offline: 'Not available offline',

  settings_action_bar_title: 'Settings',
  settings_theme: 'Theme',
  settings_theme_use_system: 'Use system theme',
  settings_theme_light: 'Light theme',
  settings_theme_dark: 'Dark theme',
  settings_more_stuff: 'More stuff',
  secondary_actions_rate_app: 'Rate the app',
  secondary_actions_use_chat: 'Write us by chat',
  secondary_actions_share_app: 'Share the app',

  retry: 'Retry',

  auth_user_account: 'User account',
  auth_logged_out_intro: 'Sign in to save your favorite stops in the cloud. This way you will not lose them if you change your device.',
  auth_login_with_google: 'Sign in with Google',
  auth_logged_in_with: 'Signed in user: ',
  auth_logged_in_description: 'Your favorite stops are safely stored in the cloud.',
  auth_logout: 'Sign out',
  auth_logout_modal_title: 'Sign out',
  auth_logout_modal_text: 'Are you sure you want to sign out?',
  auth_logout_modal_accept: 'Sign out',
  auth_logout_modal_cancel: 'Cancel',

  sync_title: 'Sync favorites',
  sync_intro: 'You have stops saved both on your user account and locally on your device. What do you want to do with the stops saved on your device?',
  sync_btn_upload_local_stop_favs: 'Add the stops from the device to my user account',
  sync_btn_delete_local_stop_favs: 'Remove the stops from the device and use only those from my user account',
}
