import React from 'react'
import ShortStop from '../ShortStop/ShortStop'
import s from './PopularStops.module.scss'

interface Props {
  stops: {stopCode: string, name: string }[]
}

const PopularStops: React.FC<Props> = ({
  stops,
}) => (
  <div className={s.stops}>
    {
      stops.map((stop) => (
        <ShortStop
          stopCode={stop.stopCode}
          stopName={stop.name}
          key={stop.stopCode}
          onClick={() => {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'popularStopClick',
              eventAction: `${stop.stopCode} | ${stop.name}`,
            })
          }}
        />
      ))
    }
  </div>
)

export default PopularStops
