import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowBackIcon } from 'common/images/arrow_back-24px.svg'
import { ClientType } from 'common/core/models/param-app-config-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { BrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage } from 'react-intl'
import s from './ActionBar.module.scss'

interface ActionBarProps {
  title?: React.ReactNode
  backLinkPath?: string
  rightSection?: React.ReactNode
}

const renderBackLink = (linkPath: string) => (
  <Link to={linkPath} className={s.backArrowLink}>
    <ArrowBackIcon />
  </Link>
)

const renderTitle = ({ title }: ActionBarProps) => (
  <h1 className={s.title}>
    { title }
  </h1>
)

function sendEvent() {
  window.ga('send', {
    hitType: 'event',
    transport: 'beacon',
    eventCategory: 'installAppLinkClick',
    eventAction: 'actionBar',
  })
}

function renderDownloadAppLink(brandConfig: BrandConfig) {
  return (
    <a
      href={brandConfig.common.downloadAppUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={sendEvent}
      className={s.app}
    >
      <FormattedMessage id="action_bar_install_app" />
    </a>
  )
}

const ActionBar: React.FC<ActionBarProps> = (props) => {
  const brandConfig = useBrandConfigContext()
  const { paramAppConfig } = brandConfig.common.services
  return (
    <div className={s.header}>
      <div className={s.headerContainer}>
        { props.backLinkPath ? renderBackLink(props.backLinkPath) : undefined }
        { renderTitle(props) }
        <div className={s.rightSection}>
          { props.rightSection }
          { paramAppConfig.clientType === ClientType.Web && renderDownloadAppLink(brandConfig) }
        </div>
      </div>
    </div>
  )
}

export default ActionBar
