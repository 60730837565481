import axios from 'axios'
import { Stop } from 'common/stops/models/stop-models'
import { StopApiClientInterface } from 'common/stops/services/StopApiClientInterface'

export default class StopApiClient implements StopApiClientInterface {
  constructor(private apiGateway: string) {}

  public async getStop(stopCode: string): Promise<Stop> {
    const url = this.getStopUrl(stopCode)
    const response = await axios.get<Stop>(url, {
      timeout: 60 * 1000,
    })
    return response.data
  }

  private getStopUrl(stopCode: string): string {
    return `${this.apiGateway}/v1/stops/${stopCode}`
  }
}
