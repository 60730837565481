import UiPreferencesStore from 'common/ui/services/ui-preferences-store/UiPreferencesStore'
import { AppThemeName } from 'common/ui/models/app-theme-models'

export class UiThemeManager {
  constructor(
    private uiPreferencesStore: UiPreferencesStore,
  ) {}

  public getThemeName(): AppThemeName {
    const preferredThemeName = this.uiPreferencesStore.getPreferredThemeName()
    if (preferredThemeName) {
      return preferredThemeName
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return AppThemeName.Dark
    }
    return AppThemeName.Green
  }
}
