import { LineDirection } from 'common/stops/models/stop-models'

export interface FavoriteArrival {
  stopCode: string
  lineCode: string
  lineDir: LineDirection
}

interface StoredFavoriteArrivalItem {
  stopCode: string
  lineCode: string
  lineDir: LineDirection
}

interface StoredPayload {
  items: StoredFavoriteArrivalItem[]
}

export default class FavoriteArrivalsStore {
  constructor(private localStorageKey: string) {}

  public getFavArrivals(stopCode: string): FavoriteArrival[] {
    const favs = this.retrieveFavorites()
    return favs.filter((favorite) => favorite.stopCode === stopCode)
  }

  public addFavArrival(stopCode: string, lineCode: string, lineDir: LineDirection): void {
    const favs = this.retrieveFavorites()
    const foundFavorite = favs.find((favorite) => (
      favorite.stopCode === stopCode
      && favorite.lineCode === lineCode
      && favorite.lineDir === lineDir
    ))
    if (!foundFavorite) {
      const newFavorites = [
        ...favs,
        {
          stopCode,
          lineCode,
          lineDir,
        },
      ]
      this.saveFavorites(newFavorites)
    }
  }

  public removeFavArrival(stopCode: string, lineCode: string, lineDir: LineDirection): void {
    const favs = this.retrieveFavorites()
    const newFavs = favs.filter((favorite) => (
      !(
        favorite.stopCode === stopCode
        && favorite.lineCode === lineCode
        && favorite.lineDir === lineDir
      )
    ))
    this.saveFavorites(newFavs)
  }

  private retrieveFavorites(): FavoriteArrival[] {
    const dataStr = window.localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return FavoriteArrivalsStore.convertPayloadToFavorites(JSON.parse(dataStr))
    }
    return []
  }

  private saveFavorites(favorites: FavoriteArrival[]): void {
    const payload = FavoriteArrivalsStore.convertFavoritesToPayload(favorites)
    window.localStorage.setItem(
      this.localStorageKey, JSON.stringify(payload),
    )
  }

  private static convertPayloadToFavorites(payload: StoredPayload): FavoriteArrival[] {
    return payload.items.map<FavoriteArrival>((storedFavorite) => ({
      stopCode: storedFavorite.stopCode,
      lineCode: storedFavorite.lineCode,
      lineDir: storedFavorite.lineDir,
    }))
  }

  private static convertFavoritesToPayload(favorites: FavoriteArrival[]): StoredPayload {
    const itemsToStore = favorites.map<StoredFavoriteArrivalItem>((favorite) => ({
      stopCode: favorite.stopCode,
      lineCode: favorite.lineCode,
      lineDir: favorite.lineDir,
    }))
    return {
      items: itemsToStore,
    }
  }
}
