import React, { useState, useEffect } from 'react'
import { getSearchableWords } from 'common/search/helpers/search-normalize'
import { LineListItem } from 'common/lines/models/line-list-models'
import { getSearchableLines } from 'common/search/helpers/line-search-preparation'
import SearchBox from 'common/search/components/SearchBox/SearchBox'
import ResultList from 'common/search/components/ResultList/ResultList'
import EnteringAnimation from 'common/ui/components/EnteringAnimation/EnteringAnimation'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import FoundRealLine from 'common/lines/components/FoundRealLine/FoundRealLine'
import FoundRealLines from 'common/lines/components/FoundRealLines/FoundRealLines'
import { searchByScore } from 'common/search/helpers/score-search'
import { SearchableLine } from 'common/search/models/search-models'
import { FormattedMessage, useIntl } from 'react-intl'
import s from './LineSearch.module.scss'

function renderOnlyFoundRealLines(
  searchableLines: SearchableLine[],
  wordsToSearch: string[],
  searchQuery: string,
) {
  const foundSearchableLines = searchByScore(searchableLines, wordsToSearch)
  if (foundSearchableLines.length > 0) {
    return (
      <>
        <div className={s.foundItems}>
          <FoundRealLines
            foundSearchableLines={foundSearchableLines}
            searchQuery={searchQuery}
          />
        </div>
      </>
    )
  }
  return undefined
}

interface LineSearchProps {
  lineList: LineListItem[]
  defaultQuery?: string
}

function renderPreSearchSection(searchableLines: SearchableLine[]) {
  return (
    <div className={s.foundItems}>
      <ResultList
        items={searchableLines}
        searchQuery=""
        renderItem={({ item }) => (
          <FoundRealLine lineListItem={item} key={item.lineCode} />
        )}
        buttonTextFn={(numberOfItems) => (
          <FormattedMessage
            id="line_search_show_more"
            values={{ numberOfItems }}
          />
        )}
        initialShowLimit={30}
      />
    </div>
  )
}

function renderFoundItems(
  searchableLines: SearchableLine[],
  searchValue: string,
) {
  const wordsToSearch = getSearchableWords(searchValue)
  const title = <FormattedMessage id="search_found_lines_title" />
  return (
    <>
      <ExternalSectionTitle title={title} />
      { renderOnlyFoundRealLines(searchableLines, wordsToSearch, searchValue) }
    </>
  )
}

const renderResults = (
  searchableLines: SearchableLine[] | undefined,
  searchValue: string | undefined,
) => {
  if (!searchableLines) {
    return undefined
  }
  if (!searchValue) {
    return renderPreSearchSection(searchableLines)
  }
  return renderFoundItems(searchableLines, searchValue)
}

const LineSearch: React.FC<LineSearchProps> = (props) => {
  const intl = useIntl()
  const [searchValue, setSearchValue] = useState<string | undefined>(props.defaultQuery)
  const [searchableLines, setSearchableLines] = useState<SearchableLine[] | undefined>()

  useEffect(() => {
    setSearchableLines(getSearchableLines(props.lineList))
  }, [props.lineList])

  function handleTextFieldChange(newValue: string) {
    setSearchValue(newValue)
  }

  return (
    <EnteringAnimation>
      <div className={s.root}>
        <SearchBox
          initialSearchQuery={searchValue}
          onChange={handleTextFieldChange}
          placeholder={intl.formatMessage({ id: 'line_search_field_placeholder' })}
          autofocus={false}
        />
        {
          renderResults(searchableLines, searchValue)
        }
      </div>
    </EnteringAnimation>
  )
}

export default LineSearch
