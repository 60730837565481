import React from 'react'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import InstallAppCard from 'common/ui/components/InstallAppCard/InstallAppCard'
import EnteringAnimation from 'common/ui/components/EnteringAnimation/EnteringAnimation'
import FeedbackCardButton from 'common/ui/components/FeedbackCardButton/FeedbackCardButton'
import RateAppCard from 'common/ui/components/RateAppCard/RateAppCard'
import { Stop } from 'common/stops/models/stop-models'
import StopHeader from 'common/stops/components/StopHeader/StopHeader'
import { StopArrivals } from 'common/stops/components/StopArrivals/StopArrivals'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { StopPageMetadataOptions } from 'common/core/models/page-metadata-models'
import SyncReminderCard from 'common/ui/components/SyncReminderCard/SyncReminderCard'
import s from './Stop.module.scss'

const renderPageMetada = (stop: Stop, stopsConfig: StopsBrandConfig) => {
  const options: StopPageMetadataOptions = {
    stopCode: stop.stopCode,
    stopName: stop.stopName,
    stopTypeCode: stop.stopTypeCode,
  }
  return (
    <PageMetadata
      pageId={stopsConfig.pageIds.stop}
      options={options}
    />
  )
}

interface Props {
  isInitialStopRetrieving: boolean
  stop: Stop
  stopFav?: StopFav
  onAddToFavoritesButtonClick: () => void
  onRemoveFromFavoritesButtonClick: () => void
  onRenameFavoriteButtonClick: () => void
  onShowNoDataExplanationClick: () => void
}

const StopComponent: React.FC<Props> = (props) => {
  const { stops: stopsConfig, common: commonConfig } = useBrandConfigContext()
  const DisplayAdWrapperComponent = commonConfig.components.displayAdWrapperComponent
  const RelatedContentComponent = stopsConfig.components.stopRelatedContent
  return (
    <EnteringAnimation>
      <div className={s.stopContainer}>
        { renderPageMetada(props.stop, stopsConfig) }
        <StopHeader
          stop={props.stop}
          stopFav={props.stopFav}
          onAddToFavoritesButtonClick={props.onAddToFavoritesButtonClick}
          onRemoveFromFavoritesButtonClick={props.onRemoveFromFavoritesButtonClick}
          onRenameFavoriteButtonClick={props.onRenameFavoriteButtonClick}
        />
        <DisplayAdWrapperComponent pageId={stopsConfig.pageIds.stop} />
        <StopArrivals
          stop={props.stop}
          onShowNoDataExplanationClick={props.onShowNoDataExplanationClick}
        />
        <SyncReminderCard className={s.syncReminderCard} />
        {stopsConfig.helpers.shouldShowChatButtonOnStopPage(commonConfig.services.paramAppConfig)
          && <FeedbackCardButton />}
        <InstallAppCard className={s.installAppCard} />
        {stopsConfig.helpers.shouldShowRateAppButtonOnStopPage(props.stop)
          && <RateAppCard className={s.rateAppCard} /> }
        <RelatedContentComponent stop={props.stop} />
      </div>
    </EnteringAnimation>
  )
}

export default StopComponent
