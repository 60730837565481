import React from 'react'
import useTransManagerContext from 'common/i18n/hooks/useTransManagerContext'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { FormattedMessage } from 'react-intl'
import LocaleSelector from 'common/i18n/components/LocaleSelector/LocaleSelector'

const SettingsLocaleChanger: React.FC = () => {
  const { changeLocale, usedLocale, allLocales } = useTransManagerContext()

  function selectLocale(locale: string) {
    changeLocale(locale)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'selectLocale',
      eventAction: locale,
      eventLabel: 'settings',
    })
  }

  if (allLocales.length <= 1) {
    return <></>
  }

  return (
    <>
      <ExternalSectionTitle title={<FormattedMessage id="settings_language" />} />
      <LocaleSelector
        selectedLocale={usedLocale}
        onLocaleSelect={selectLocale}
      />
    </>
  )
}

export default SettingsLocaleChanger
