import React from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as CloseIcon } from 'common/images/close-24px.svg'
import { useIntl } from 'react-intl'
import s from './ScheduleModal.module.scss'

interface ModalProps {
  isOpen: boolean
  onRequestClose: () => void
  imgUrl: string | undefined
}

export const ScheduleModal: React.FC<ModalProps> = (props) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'line_schedule_modal_title' })

  const renderCloseButton = () => (
    <button
      type="button"
      className={s.closeButton}
      onClick={props.onRequestClose}
      aria-label={intl.formatMessage({ id: 'modal_close_btn' })}
    >
      <CloseIcon />
    </button>
  )

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel={title}
      onRequestClose={props.onRequestClose}
      overlayClassName={s.modalOverlay}
      className={s.modal}
      closeTimeoutMS={150}
    >
      <div className={s.header}>
        { title }
        { renderCloseButton() }
      </div>
      <pinch-zoom class={s.pinchZoomContainer}>
        <img
          src={props.imgUrl}
          alt=""
          className={s.zoomable}
        />
      </pinch-zoom>
    </ReactModal>
  )
}
