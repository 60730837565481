import React from 'react'
import { BrandConfig } from 'common/core/models/brand-config.models'
import { ReactComponent as TourIcon } from 'common/images/tour-24px.svg'
import { ReactComponent as SettingsIcon } from 'common/images/settings-24px.svg'
import { ReactComponent as TimelineIcon } from 'common/images/timeline-24px.svg'
import { ReactComponent as MapIcon } from 'common/images/map-24px.svg'
import { SearchableStopsBuilder } from 'common/search/services/SearchableStopsBuilder'
import { FakeSearchableLinesBuilder } from 'common/search/services/FakeSearchableLinesBuilder'
import { Stop } from 'common/stops/models/stop-models'
import { FormattedMessage } from 'react-intl'
import { ClientType } from 'common/core/models/param-app-config-models'
import { RouteManager } from './core/services/route-manager/RouteManager'
import StopIcon from './stops/components/StopIcon/StopIcon'
import { AppDeps, PageId } from './core/models/app-models'
import { StopTypeNumCode, TransportTypeNumCode } from './stops/models/stop-models'
import StopCodeWrapperFactory from './core/services/StopCodeWrapperFactory'
import { parseStringToCustomStopCode } from './stops/helpers/transport-utils'
import { pageMetadataCollection } from './core/helpers/page-metadata'
import { stopMapIcons } from './stop-map-config'
import BigStopIcon from './stops/components/BigStopIcon'
import DisplayAdWrapper from './ads/components/DisplayAdWrapper/DisplayAdWrapper'
import ArrivalsSourcesModalContent from './stops/components/ArrivalsSourcesModalContent/ArrivalsSourcesModalContent'
import StopLoadingContent from './stops/components/StopLoadingContent'
import { AdsenseSlot } from './ads/models/ads-models'
import { buildLineTheme } from './lines/helpers/line-theme'

const searchStopTypeCodesOrder: StopTypeNumCode[] = [
  StopTypeNumCode.Bus,
]

const transportTypeCodesWithRealLines: TransportTypeNumCode[] = [
  TransportTypeNumCode.Bus,
]

const stopTypeCodesThatShouldGenerateFakeSearchableLines: string[] = []

function getTransportTypeCodeFromStopTypeCode(stopTypeCode: string) {
  const map: {[key in StopTypeNumCode]: TransportTypeNumCode } = {
    [StopTypeNumCode.Bus]: TransportTypeNumCode.Bus,
  }
  return map[stopTypeCode as StopTypeNumCode] || TransportTypeNumCode.Bus
}

const transportTypeCodeToStopTypeCode: {[key in TransportTypeNumCode]: StopTypeNumCode} = {
  [TransportTypeNumCode.Bus]: StopTypeNumCode.Bus,
}

function getStopTypeCodeFromTransportTypeCode(transportTypeCode: string): StopTypeNumCode {
  return transportTypeCodeToStopTypeCode[transportTypeCode as TransportTypeNumCode]
}

const trainStopTypes: StopTypeNumCode[] = []

function getArrrivalsTitleText(stop: Stop) {
  if (trainStopTypes.includes(stop.stopTypeCode as StopTypeNumCode)) {
    return <FormattedMessage id="next_arrivals_title_trains" />
  }
  return <FormattedMessage id="next_arrivals_title_buses" />
}

function composeLineDescriptionInSearchResults(
  lineNumber: string, transportTypeCode: string,
): React.ReactNode {
  type LineDescriptionMap = {
    [key in TransportTypeNumCode] : (line: string) => React.ReactNode
  }
  const lineDescriptionMap: LineDescriptionMap = {
    [TransportTypeNumCode.Bus]: (lineNum) => (
      <FormattedMessage id="found_line_description_bus" values={{ lineNumber: lineNum }} />
    ),
  }
  const messageFn = lineDescriptionMap[transportTypeCode as TransportTypeNumCode]
  return messageFn
    ? messageFn(lineNumber)
    : <FormattedMessage id="found_line_description_other" values={{ lineNumber }} />
}

function composeStopDescription(stopTypeCode: string): React.ReactNode {
  const stopDescriptions: {[stopTypeCode in StopTypeNumCode]: React.ReactNode} = {
    [StopTypeNumCode.Bus]: <FormattedMessage id="stop_description_bus" />,
  }
  return stopDescriptions[stopTypeCode as StopTypeNumCode]
    || <FormattedMessage id="stop_description_other" />
}

function getStopLinesDescriptionInSearch(stopTypeNumCode: string): React.ReactNode {
  if (stopTypeNumCode === StopTypeNumCode.Bus) {
    return <FormattedMessage id="search_stop_lines_desc_bus" />
  }
  return <FormattedMessage id="search_stop_lines_desc_other" />
}

function composeLineDescription(transportTypeCode: string) {
  const lineDescriptionMap: {[key in TransportTypeNumCode] : () => React.ReactNode } = {
    [TransportTypeNumCode.Bus]: () => <FormattedMessage id="line_description_bus" />,
  }
  const messageFn = lineDescriptionMap[transportTypeCode as TransportTypeNumCode]
  return messageFn ? messageFn() : <FormattedMessage id="line_description_other" />
}

const stopTypeCodesWithoutFavDeparturesAlert: StopTypeNumCode[] = []

export function createBrandConfig(appDeps: AppDeps): BrandConfig {
  return {
    common: {
      downloadAppUrl: 'https://smarturl.it/okTransitBilbao',
      pageMetadataCollection,
      navbar: {
        items: [
          {
            url: RouteManager.getStopsUrl(),
            text: (<FormattedMessage id="navbar_stops" />),
            icon: (<TourIcon />),
          },
          {
            url: RouteManager.getLinesUrl(),
            text: (<FormattedMessage id="navbar_lines" />),
            icon: (<TimelineIcon />),
          },
          {
            url: RouteManager.getPostersUrl(),
            text: (<FormattedMessage id="navbar_posters" />),
            icon: (<MapIcon />),
          },
          {
            url: RouteManager.getSettingsUrl(),
            text: (<FormattedMessage id="navbar_settings" />),
            icon: (<SettingsIcon />),
          },
        ],
      },
      urls: {
        getRootUrl: RouteManager.getStopsUrl,
        getSettingsUrl: RouteManager.getSettingsUrl,
      },
      bottomBannerAdSlot: AdsenseSlot.BottomBanner,
      services: {
        buildtimeConfig: appDeps.buildtimeConfig,
        paramAppConfig: appDeps.paramAppConfig,
        uiThemeManager: appDeps.uiThemeManager,
        uiPreferencesStore: appDeps.uiPreferencesStore,
        adsManager: appDeps.adsManager,
        appUsageTracker: appDeps.appUsageTracker,
        i18nPersistor: appDeps.i18nPersistor,
        firebaseApp: appDeps.firebaseApp,
        userFirestoreClient: appDeps.userFirestoreClient,
      },
      components: {
        displayAdWrapperComponent: DisplayAdWrapper,
      },
      helpers: {
        shareAppText: (intl) => intl.formatMessage({ id: 'share_app_text' }),
        shouldShowChatButtonOnSettingsPage: () => false,
        shouldShowBottomBannerAd: (paramAppConfig) => [
          ClientType.Web,
        ].includes(paramAppConfig.clientType),
      },
    },
    stops: {
      urls: {
        getStopUrl: RouteManager.getStopUrl,
        getStopSearchUrl: RouteManager.getStopsSearchUrl,
        getStopsMapUrl: RouteManager.getStopsMapUrl,
        getLinesUrl: RouteManager.getLinesUrl,
        getLineUrl: RouteManager.getLineUrl,
      },
      services: {
        stopApiClient: appDeps.stopApiClient,
        stopCodeWrapperFactory: new StopCodeWrapperFactory(),
        stopFavsStore: appDeps.stopFavsStore,
        stopFavsService: appDeps.stopFavsService,
        visitedStopsStore: appDeps.visitedStopsStore,
        searchHistoryStore: appDeps.searchHistoryStore,
        searchableStopsBuilder: new SearchableStopsBuilder(
          stopTypeCodesThatShouldGenerateFakeSearchableLines, searchStopTypeCodesOrder,
        ),
        fakeSearchableLinesBuilder: new FakeSearchableLinesBuilder(
          stopTypeCodesThatShouldGenerateFakeSearchableLines,
        ),
        stopListApiClient: appDeps.stopListApiClient,
        lineListApiClient: appDeps.lineListApiClient,
        lineApiClient: appDeps.lineApiClient,
        stopMapStateStore: appDeps.stopMapStateStore,
        favoriteArrivalsStore: appDeps.favoriteArrivalsStore,
      },
      helpers: {
        getStopLinesDescriptionInSearch,
        stopTypeCodesWithoutFavDeparturesAlert,
        parseStringToCustomStopCode,
        composeLineDescriptionInSearchResults,
        composeLineDescription,
        getArrrivalsTitleText,
        getTransportTypeCodeFromStopTypeCode,
        getStopTypeCodeFromTransportTypeCode,
        getFakeSearchableLineTransportTypeCode: (line) => (
          getTransportTypeCodeFromStopTypeCode(line.stopTypeNumCode)
        ),
        transportTypeCodesWithRealLines,
        stopSeacrhFieldPlaceholder: (intl) => intl.formatMessage({ id: 'stop_search_field_placeholder' }),
        stopMapIcons,
        composeStopDescription,
        defaultMapCenter: {
          lat: 43.263032,
          lng: -2.934816,
        },
        autocompleteMapBounds: {
          west: -2.985981,
          south: 43.221791,
          east: -2.881615,
          north: 43.29032,
        },
        shouldShowRateAppButtonOnStopPage: () => true,
        shouldShowChatButtonOnStopPage: () => false,
        buildLineTheme,
      },
      pageIds: {
        stopSearch: PageId.SearchPage,
        stopMap: PageId.StopMapPage,
        stop: PageId.StopPage,
        lines: PageId.LinesPage,
        line: PageId.LinePage,
      },
      components: {
        stopIcon: StopIcon,
        bigStopIcon: BigStopIcon,
        stopRelatedContent: () => <></>,
        arrivalsSourcesModalContent: ArrivalsSourcesModalContent,
        stopLoadingContent: StopLoadingContent,
      },
    },
    postersConfig: {
      urls: {
        getPostersUrl: RouteManager.getPostersUrl,
      },
    },
  }
}
