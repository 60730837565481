import React from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { ReactComponent as CloseIcon } from 'common/images/close-24px.svg'
import s from './AppModal.module.scss'

export enum AppModalSize {
  Small = 1,
  Normal = 2,
  Large = 3,
}

const sizeClassNamesMapping: { [size in AppModalSize]: string } = {
  [AppModalSize.Small]: s.sizeSmall,
  [AppModalSize.Normal]: s.sizeNormal,
  [AppModalSize.Large]: s.sizeLarge,
}

interface ModalProps {
  isOpen: boolean
  title: string
  maxWidth?: AppModalSize
  shouldCloseOnOverlayClick?: boolean
  shouldShowCloseButton: boolean
  onRequestClose?: (() => void)
}

function getSizeClassName({ maxWidth }: ModalProps) {
  return maxWidth ? sizeClassNamesMapping[maxWidth] : s.sizeSmall
}

const renderCloseButton = (props: ModalProps) => (
  <button
    type="button"
    className={s.closeButton}
    onClick={() => props.onRequestClose && props.onRequestClose()}
    aria-label="Cerrar"
  >
    <CloseIcon />
  </button>
)

export const AppModal: React.FC<ModalProps> = (props) => (
  <ReactModal
    isOpen={props.isOpen}
    contentLabel={props.title}
    shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ?? true}
    onRequestClose={() => props.onRequestClose && props.onRequestClose()}
    overlayClassName={s.modalOverlay}
    className={classNames(s.modal, getSizeClassName(props))}
    closeTimeoutMS={150}
  >
    <div className={s.header}>
      { props.title }
      { props.shouldShowCloseButton ? renderCloseButton(props) : undefined }
    </div>
    {props.children}
  </ReactModal>
)
