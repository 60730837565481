import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'
import React from 'react'
import { Link } from 'react-router-dom'
import { getStopListItemStopTypeCode } from 'common/search/helpers/stop-list-helpers'
import { FormattedMessage } from 'react-intl'
import s from './MapInfoWindowContent.module.scss'

interface Props {
  stopListItem: StopListItem
}

const MapInfoWindowContent: React.FC<Props> = ({ stopListItem }) => {
  const { helpers, urls } = useBrandConfigContext().stops
  return (
    <div className={s.infoWindow}>
      <div className={s.infoWindowTitle}>{stopListItem[StopListItemIndex.StopName]}</div>
      <div className={s.infoWindowSecondaryTitle}>
        {helpers.composeStopDescription(getStopListItemStopTypeCode(stopListItem))}{' '}
        {stopListItem[StopListItemIndex.StopCode]}
      </div>
      <div className={s.infoWindowLines}>
        <FormattedMessage id="stop_map_infowindow_lines" />
        {stopListItem[StopListItemIndex.LineNumbers].join(', ')}
      </div>
      <Link
        to={urls.getStopUrl(stopListItem[StopListItemIndex.StopCode])}
        className={s.infoWindowLink}
      >
        <FormattedMessage id="stop_map_infowindow_go_to_stop" />
      </Link>
    </div>
  )
}

export default MapInfoWindowContent
