import { getStopListItemStopTypeCode } from 'common/search/helpers/stop-list-helpers'
import normalizeForSearch from 'normalize-for-search'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'

export interface SearchableFakeLine {
  stopTypeNumCode: string
  lineNumber: string
}

export class FakeSearchableLinesBuilder {
  constructor(private stopTypeCodesToInclude: string[]) {}

  public getSearchableFakeLines(stops: StopListItem[]): SearchableFakeLine[] {
    const transportTypeLineMap = this.createEmptyMap()
    stops.forEach((stop) => {
      const stopTypeNumCode = getStopListItemStopTypeCode(stop)
      if (this.shouldInclude(stopTypeNumCode)) {
        stop[StopListItemIndex.LineNumbers].forEach((lineNumber) => {
          transportTypeLineMap[stopTypeNumCode][lineNumber] = lineNumber
        })
      }
    })
    const searchableLines: SearchableFakeLine[] = []
    Object.entries(transportTypeLineMap).forEach(([stopTypeNumCode, lineMap]) => {
      Object.keys(lineMap).forEach((lineNumber) => {
        searchableLines.push({
          stopTypeNumCode,
          lineNumber: normalizeForSearch(lineNumber),
        })
      })
    })
    return searchableLines
  }

  private createEmptyMap() {
    const map: {[stopTypeCode: string]: {[key: string]: string}} = {}
    this.stopTypeCodesToInclude.forEach((stopTypeCode) => {
      map[stopTypeCode] = {}
    })
    return map
  }

  private shouldInclude(stopTypeNumCode: string) {
    return this.stopTypeCodesToInclude.includes(stopTypeNumCode)
  }
}
