import React from 'react'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { SearchableFakeLine } from 'common/search/services/FakeSearchableLinesBuilder'
import FoundLineLayout from '../FoundLineLayout/FoundLineLayout'
import LineNumber from '../LineNumber/LineNumber'

interface Props {
  line: SearchableFakeLine
}

const FoundFakelLine: React.FC<Props> = ({ line }) => {
  const { urls, helpers } = useBrandConfigContext().stops

  const url = urls.getStopsMapUrl(undefined, line.stopTypeNumCode, line.lineNumber)
  const transportTypeCode = helpers.getTransportTypeCodeFromStopTypeCode(line.stopTypeNumCode)
  const lineNumber = line.lineNumber.toUpperCase()
  return (
    <FoundLineLayout
      url={url}
      lineNumberElement={(
        <LineNumber
          lineNumber={lineNumber}
          transportTypeCode={transportTypeCode}
          isNocturnal={lineNumber.startsWith('N')}
        />
      )}
      primaryText={
        helpers.composeLineDescriptionInSearchResults(lineNumber, transportTypeCode)
      }
    />
  )
}

export default FoundFakelLine
