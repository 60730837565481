import React, { useEffect, useState, useCallback } from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import { loadGoogleMaps } from 'common/maps/helpers/google-maps-utils'
import { StopListItem } from 'common/stops/models/stop-list-models'
import StopMapPageView from 'common/maps/components/StopMapPageView/StopMapPageView'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

interface StopMapPageProps {
  selectedStopCode?: string
  lineNumber?: string
  stopTypeNumCode?: string
}

const StopMapPage: React.FC<StopMapPageProps> = (props) => {
  const [retrieveStatus, setRetrieveStatus] = useState<OpStatus>()
  const [retrieveError, setRetrieveError] = useState<Error>()
  const [stopList, setStopList] = useState<StopListItem[]>()
  const [googleMapsLib, setGoogleMapsLib] = useState<typeof google.maps>() // eslint-disable-line

  const { stops } = useBrandConfigContext()

  const fetchData = useCallback(async () => {
    setRetrieveStatus(OpStatus.InProgress)
    setRetrieveError(undefined)
    try {
      const [result, googleMapsLibResult] = await Promise.all([
        stops.services.stopListApiClient.getStopList(),
        loadGoogleMaps(),
      ])
      setStopList(result)
      setGoogleMapsLib(googleMapsLibResult)
      setRetrieveStatus(OpStatus.Success)
    } catch (error) {
      setRetrieveError(error)
      setRetrieveStatus(OpStatus.Failed)
    }
  }, [stops])

  useEffect(() => { fetchData() }, [fetchData])

  return (
    <StopMapPageView
      retrieveStatus={retrieveStatus}
      retrieveError={retrieveError}
      stopList={stopList}
      googleMapsLib={googleMapsLib}
      selectedStopCode={props.selectedStopCode}
      lineNumber={props.lineNumber}
      stopTypeCode={props.stopTypeNumCode}
      onRetryFetchButtonClick={fetchData}
    />
  )
}

export default StopMapPage
