import { SearchableItem } from 'common/search/models/search-models'

interface FoundItem<T> {
  searchableItem: SearchableItem<T>
  score: number
}

function wordsContainString(words: string[], str: string): boolean {
  return words.some((word) => word.startsWith(str))
}

function getItemSearchScore<T>(item: SearchableItem<T>, wordsToSearch: string[]): number {
  let score = 0
  wordsToSearch.forEach((wordToSearch) => {
    if (wordsContainString(item.normalizedWords, wordToSearch)) {
      score += 1
    }
    if (item.normalizedLineNumbers.includes(wordToSearch)) {
      score += 10
    }
  })
  return score
}

function getFoundItems<T>(
  searchableItems: SearchableItem<T>[],
  wordsToSearch: string[],
): FoundItem<T>[] {
  const foundItems: FoundItem<T>[] = []
  searchableItems.forEach((searchableItem) => {
    const score = getItemSearchScore(searchableItem, wordsToSearch)
    if (score > 0) {
      foundItems.push({ searchableItem, score })
    }
  })
  return foundItems
}

export function searchByScore<T>(
  items: SearchableItem<T>[],
  wordsToSearch: string[],
): SearchableItem<T>[] {
  const foundItems = getFoundItems(items, wordsToSearch)
  return foundItems.sort((a, b) => b.score - a.score).map((item) => item.searchableItem)
}
