import React from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import ErrorView from 'common/ui/components/aux-views/ErrorView'
import { isNoInternetError } from 'common/shared/helpers/network-utils'
import NoInternetError from 'common/ui/components/aux-views/NoInternetError'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import { StopListItem } from 'common/stops/models/stop-list-models'
import SearchTabPanel from 'common/search/components/SearchTabPanel/SearchTabPanel'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import NormalStopMap from 'common/maps/components/NormalStopMap/NormalStopMap'
import LineStopMap from 'common/maps/components/LineStopMap/LineStopMap'
import { FormattedMessage } from 'react-intl'

interface StopMapPageViewProps {
  retrieveStatus?: OpStatus
  retrieveError?: Error
  stopList?: StopListItem[]
  googleMapsLib?: typeof google.maps
  selectedStopCode?: string
  lineNumber?: string
  stopTypeCode?: string
  onRetryFetchButtonClick: () => void
}

const renderLoading = () => (
  <LoadingView><FormattedMessage id="stop_map_loading" /></LoadingView>
)

const renderNoInternetError = (props: StopMapPageViewProps) => (
  <NoInternetError onRetryButtonClick={props.onRetryFetchButtonClick} />
)

const renderNoStopsDataError = (props: StopMapPageViewProps) => {
  const text = <FormattedMessage id="stop_map_error" />
  return (
    <ErrorView mainText={text} onRetryButtonClick={props.onRetryFetchButtonClick} />
  )
}

const renderErrorView = (props: StopMapPageViewProps) => {
  if (props.retrieveStatus === OpStatus.Failed) {
    if (isNoInternetError(props.retrieveError!)) {
      return renderNoInternetError(props)
    }
    return renderNoStopsDataError(props)
  }
  return undefined
}

function renderSuccessView(props: StopMapPageViewProps) {
  if (props.lineNumber) {
    return (
      <LineStopMap
        lineNumber={props.lineNumber}
        stopTypeCode={props.stopTypeCode!}
        stopList={props.stopList!}
        googleMapsLib={props.googleMapsLib!}
      />
    )
  }
  return (
    <NormalStopMap
      stopList={props.stopList!}
      googleMapsLib={props.googleMapsLib!}
      selectedStopCode={props.selectedStopCode}
    />
  )
}

const StopMapPageView: React.FC<StopMapPageViewProps> = (props) => {
  const { common: commonBrandConfig, stops: stopsBrandConfig } = useBrandConfigContext()
  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={<FormattedMessage id="stop_map_action_bar_title" />}
          backLinkPath={commonBrandConfig.urls.getRootUrl()}
        />
      )}
    >
      <PageMetadata pageId={stopsBrandConfig.pageIds.stopMap} />
      <SearchTabPanel isMapSelected />
      { props.retrieveStatus === OpStatus.InProgress && renderLoading() }
      { renderErrorView(props) }
      { props.retrieveStatus === OpStatus.Success && renderSuccessView(props) }
    </PageLayout>
  )
}

export default StopMapPageView
