import React from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorView from './ErrorView'

interface NoInternetErrorProps {
  onRetryButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const NoInternetError: React.FC<NoInternetErrorProps> = (props) => (
  <ErrorView
    mainText={<FormattedMessage id="no_internet_error" />}
    onRetryButtonClick={props.onRetryButtonClick}
  />
)

export default NoInternetError
