import React, { useEffect } from 'react'

interface Props {
  category: string
  action?: string
  label?: string
}

const AxImpression: React.FC<Props> = ({ category, action, label }) => {
  useEffect(() => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    })
  }, [category, action, label])
  return <></>
}

export default AxImpression
