import React, { useEffect, useState, useCallback } from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import { LineListItem } from 'common/lines/models/line-list-models'
import LinesPageView from 'common/lines/components/LinesPageView/LinesPageView'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

const LinesPage: React.FC = () => {
  const [retrieveStatus, setRetrieveStatus] = useState<OpStatus>()
  const [retrieveError, setRetrieveError] = useState<Error>()
  const [lineList, setLineList] = useState<LineListItem[]>()
  const { services } = useBrandConfigContext().stops

  const fetchData = useCallback(async () => {
    setRetrieveStatus(OpStatus.InProgress)
    try {
      const retrievedLineList = await services.lineListApiClient.getLineList()
      setLineList(retrievedLineList)
      setRetrieveStatus(OpStatus.Success)
    } catch (error) {
      setRetrieveError(error)
      setRetrieveStatus(OpStatus.Failed)
    }
  }, [services])

  useEffect(() => { fetchData() }, [fetchData])

  return (
    <LinesPageView
      retrieveStatus={retrieveStatus}
      retrieveError={retrieveError}
      lineList={lineList}
      onRetryFetchButtonClick={fetchData}
    />
  )
}

export default LinesPage
