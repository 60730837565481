import busStopIcon from './maps/images/stop_bus.svg'
import busNocturnalStopIcon from './maps/images/stop_bus_nocturnal.svg'
import { StopTypeNumCode } from './stops/models/stop-models'

interface StopIcons {
  normal: {
    [stopTypeCode in StopTypeNumCode]: string
  },
  nocturnal: {
    [stopTypeCode: string]: string
  }
}

export const stopMapIcons: StopIcons = {
  normal: {
    [StopTypeNumCode.Bus]: busStopIcon,
  },
  nocturnal: {
    [StopTypeNumCode.Bus]: busNocturnalStopIcon,
  },
}
