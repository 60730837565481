import React from 'react'
import { FormattedMessage } from 'react-intl'
import s from './aux-views.module.scss'
import FullHeightContainer from './FullHeightContainer'

interface ErrorViewProps {
  mainText: React.ReactNode,
  onRetryButtonClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const renderRetryButton = (props: ErrorViewProps) => {
  if (props.onRetryButtonClick) {
    return (
      <>
        <br />
        <button
          type="button"
          className={s.retryButton}
          onClick={props.onRetryButtonClick}
        >
          <FormattedMessage id="retry" />
        </button>
      </>
    )
  }
  return undefined
}

const ErrorView: React.FC<ErrorViewProps> = (props) => (
  <FullHeightContainer>
    { props.mainText }
    { props.children }
    { renderRetryButton(props) }
  </FullHeightContainer>
)

export default ErrorView
