import { ClientType } from 'common/core/models/param-app-config-models'
import React from 'react'
import DisplayAd from 'common/ads/components/DisplayAd/DisplayAd'
import { DisplayAdWrapperType } from 'common/ads/components/ad-components-types'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { PageId } from '../../../core/models/app-models'
import { AdsenseSlot } from '../../models/ads-models'

const stopPageAdsMapping: {[clientType in ClientType]: AdsenseSlot} = {
  [ClientType.Web]: AdsenseSlot.WebStopPage,
  [ClientType.Android]: AdsenseSlot.AndroidStopPage,
  [ClientType.IOS]: AdsenseSlot.IosStopPage,
}

const linePageAdsMapping: {[clientType in ClientType]: AdsenseSlot} = {
  [ClientType.Web]: AdsenseSlot.WebLinePage,
  [ClientType.Android]: AdsenseSlot.AndroidLinePage,
  [ClientType.IOS]: AdsenseSlot.IosLinePage,
}

function getAdsenseSlot(pageId: PageId, clientType: ClientType): AdsenseSlot | undefined {
  if (pageId === PageId.StopPage) {
    return stopPageAdsMapping[clientType]
  }
  if (pageId === PageId.LinePage) {
    return linePageAdsMapping[clientType]
  }
  return undefined
}

function renderAd(pageId: string, clientType: ClientType) {
  const adsenseSlot = getAdsenseSlot(pageId as PageId, clientType)
  if (!adsenseSlot) {
    return <></>
  }
  return <DisplayAd adsenseSlot={adsenseSlot} pageId={pageId} />
}

const DisplayAdWrapper: DisplayAdWrapperType = ({ pageId }) => {
  const { paramAppConfig } = useBrandConfigContext().common.services

  if (paramAppConfig.clientType === ClientType.Web) {
    return renderAd(pageId, paramAppConfig.clientType)
  }
  return <></>
}

export default DisplayAdWrapper
