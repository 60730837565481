import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import MarkerClusterer from '@google/markerclusterer'
import { getStopListItemStopTypeCode } from 'common/search/helpers/stop-list-helpers'
import { ReactComponent as MyLocationIcon } from 'common/images/my_location-24px.svg'
import myLocationIcon from 'common/images/my_location.svg'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'
import { ClientType } from 'common/core/models/param-app-config-models'
import MapInfoWindowContent from 'common/maps/components/MapInfoWindowContent/MapInfoWindowContent'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopMapIcons } from 'common/maps/models/maps-models'
import { CommonBrandConfig } from 'common/core/models/brand-config.models'
import { useIntl } from 'react-intl'
import s from './StopMap.module.scss'
import { createMapConfig, getClusteringOptions } from './map-config'

interface StopMapProps {
  stopList: StopListItem[]
  enableClustering: boolean
  selectedStopCode? : string
  mapCenter: {
    lat: number
    lng: number
  }
  mapZoom: number
  boundsToFit?: google.maps.LatLngBounds
  onMapIdle?: (map: google.maps.Map) => void
  googleMapsLib: typeof google.maps
}

function renderInfoWindowContentPortal(selectedStop: StopListItem, domElement: HTMLDivElement) {
  return ReactDOM.createPortal(
    <MapInfoWindowContent stopListItem={selectedStop} />,
    domElement,
  )
}

function renderMyLocationButton(getGeolocationFn: () => void) {
  return (
    <button type="button" onClick={getGeolocationFn} className={s.myLocationButton}>
      <MyLocationIcon />
    </button>
  )
}

function renderMyLocationButtonPortal(domElement: HTMLDivElement, getGeolocationFn: () => void) {
  return ReactDOM.createPortal(renderMyLocationButton(getGeolocationFn), domElement)
}

function isIosClientWithoutLocationSupport(commonBrandConfig: CommonBrandConfig) {
  return commonBrandConfig.services.paramAppConfig.clientType === ClientType.IOS
}

function shouldShowMyLocationButton(commonBrandConfig: CommonBrandConfig) {
  if (isIosClientWithoutLocationSupport(commonBrandConfig)) {
    return false
  }
  return true
}

function createMarkers(
  googleMapsLib: typeof google.maps,
  stopList: StopListItem[],
  markerClickHandler: (this: google.maps.Marker) => void,
  stopMapIcons: StopMapIcons,
) {
  return stopList.map((stop) => {
    const isNocturnal = stop[StopListItemIndex.IsNocturnal]
    const stopTypeCode = getStopListItemStopTypeCode(stop)
    const marker = new googleMapsLib.Marker({
      position: {
        lat: stop[StopListItemIndex.Lat],
        lng: stop[StopListItemIndex.Long],
      },
      icon: isNocturnal
        ? stopMapIcons.nocturnal[stopTypeCode]
        : stopMapIcons.normal[stopTypeCode],
    })
    marker.set('stop', stop)
    marker.addListener('click', markerClickHandler)
    return marker
  })
}

function showMarkers(
  map: google.maps.Map,
  markers: google.maps.Marker[],
  enableClustering: boolean,
) {
  if (enableClustering) {
    new MarkerClusterer(map, markers, getClusteringOptions()) // eslint-disable-line
  } else {
    markers.forEach((marker) => marker.setMap(map))
  }
}

const StopMap: React.FC<StopMapProps> = (props) => {
  const mapContainerDomEl = useRef<HTMLDivElement>(null)
  const mapRef = useRef<google.maps.Map>()
  const infowindowDomElRef = useRef<HTMLDivElement>(document.createElement('div'))
  const myLocationButtonDomElRef = useRef<HTMLDivElement>(document.createElement('div'))
  const userLocationMarkerRef = useRef(new props.googleMapsLib.Marker({ icon: myLocationIcon }))
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchInputContainerDomElRef = useRef<HTMLDivElement>(document.createElement('div'))

  const [selectedStop, setSelectedStop] = useState<StopListItem>()
  const { common: commonBrandConfig, stops: stopsBrandConfig } = useBrandConfigContext()

  const { onMapIdle } = props
  useEffect(() => {
    mapRef.current = new props.googleMapsLib.Map(
      mapContainerDomEl.current!,
      createMapConfig(
        props.googleMapsLib, props.mapCenter,
        commonBrandConfig.services.uiThemeManager.getThemeName(), props.mapZoom,
      ),
    )
    const infowindow = new props.googleMapsLib.InfoWindow()
    function markerClickHandler(this: google.maps.Marker) {
      const marker = this
      setSelectedStop(marker.get('stop'))
      infowindow.setContent(infowindowDomElRef.current)
      infowindow.open(mapRef.current, marker)
    }
    const markers = createMarkers(
      props.googleMapsLib, props.stopList,
      markerClickHandler, stopsBrandConfig.helpers.stopMapIcons,
    )
    showMarkers(mapRef.current, markers, props.enableClustering)

    const position = props.googleMapsLib.ControlPosition.TOP_RIGHT
    mapRef.current.controls[position].push(myLocationButtonDomElRef.current)

    if (props.selectedStopCode) {
      const foundMarker = markers.find((marker) => (
        marker.get('stop')[StopListItemIndex.StopCode] === props.selectedStopCode
      ))
      if (foundMarker) {
        props.googleMapsLib.event.addListenerOnce(mapRef.current!, 'idle', () => {
          mapRef.current!.setCenter(foundMarker.getPosition()!)
          mapRef.current!.setZoom(18)
          props.googleMapsLib.event.trigger(foundMarker, 'click')
        })
      }
    }

    if (props.boundsToFit) {
      mapRef.current.fitBounds(props.boundsToFit)
    }

    props.googleMapsLib.event.addListener(mapRef.current!, 'idle', () => {
      if (onMapIdle) {
        onMapIdle(mapRef.current!)
      }
    })

    const searchPosition = props.googleMapsLib.ControlPosition.TOP_LEFT
    mapRef.current.controls[searchPosition].push(searchInputContainerDomElRef.current)
    const autocomplete = new props.googleMapsLib.places.Autocomplete(searchInputRef.current!)
    autocomplete.setFields(['geometry', 'name'])
    autocomplete.setBounds(stopsBrandConfig.helpers.autocompleteMapBounds)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (!place.geometry) {
        return
      }
      const map = mapRef.current!
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(17)
      }
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'selectStopMapSearchResult',
        eventAction: place.name,
      })
    })
  }, [
    props.googleMapsLib,
    props.stopList,
    props.enableClustering,
    props.mapCenter,
    props.mapZoom,
    props.selectedStopCode,
    onMapIdle,
    props.boundsToFit,
    commonBrandConfig,
    stopsBrandConfig,
  ])

  const getGeolocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          mapRef.current!.setCenter(pos)
          mapRef.current!.setZoom(16)
          userLocationMarkerRef.current.setPosition(pos)
          userLocationMarkerRef.current.setMap(mapRef.current!)
        },
      )
    }
  }

  const intl = useIntl()

  return (
    <>
      <div className={s.map} ref={mapContainerDomEl} />
      {
        selectedStop
          ? renderInfoWindowContentPortal(selectedStop, infowindowDomElRef.current)
          : undefined
      }
      {
        shouldShowMyLocationButton(commonBrandConfig)
          ? renderMyLocationButtonPortal(myLocationButtonDomElRef.current, getGeolocation)
          : undefined
      }
      {
        ReactDOM.createPortal(
          (
            <input
              type="search"
              placeholder={intl.formatMessage({ id: 'map_search_places_field_placeholder' })}
              ref={searchInputRef}
              className={s.searchinput}
            />
          ),
          searchInputContainerDomElRef.current,
        )
      }
    </>
  )
}

export default StopMap
