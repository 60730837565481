import React from 'react'
import CardLink from 'common/ui/components/CardLink/CardLink'
import { ReactComponent as LogoImg } from 'common/images/chat-24px.svg'
import FeedbackGenericButton from 'common/ui/components/FeedbackGenericButton/FeedbackGenericButton'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import s from './FeedbackCardButton.module.scss'

interface FeedbackCardButtonProps {
  className?: string
}

const FeedbackCardButton: React.FC<FeedbackCardButtonProps> = ({ className = '' }) => {
  const leftChildren = (
    <div className={s.iconContainer}>
      <LogoImg />
    </div>
  )

  const content = (
    <CardLink leftChildren={leftChildren}>
      <FormattedMessage id="chat_card_secondary" />
      <div className={s.button}><FormattedMessage id="chat_card_primary" /></div>
    </CardLink>
  )

  return (
    <FeedbackGenericButton>
      {
        (renderContent) => (
          <div className={classNames(s.root, className)}>
            { renderContent(content) }
          </div>
        )
      }
    </FeedbackGenericButton>
  )
}

export default FeedbackCardButton
