import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BottomAdLayout from 'common/ads/components/BottomAdLayout/BottomAdLayout'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { UserContext } from 'common/auth/hooks/useUserContext'
import {
  getAuth, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, User,
} from 'firebase/auth'
import { enableMultiTabIndexedDbPersistence, getFirestore } from 'firebase/firestore'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import { FirebaseError } from 'firebase/app'
import SyncManager from 'common/core/components/SyncManager/SyncManager'
import s from './App.module.scss'

function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    const { pathname, search } = location
    const path = `${pathname}${search}`
    window.ga('send', 'pageview', path)
  }, [location])
}

const App: React.FC = ({ children }) => {
  usePageViews()

  const {
    common: {
      services: {
        firebaseApp,
      },
    },
  } = useBrandConfigContext()

  const [authInitialized, setAuthInitialized] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)
  const [fsPersistenceActivationFinished, setFsPersistenceActivationFinished] = useState(false)

  const handleAuthRedirectResult = useCallback(async () => {
    const auth = getAuth(firebaseApp)
    try {
      const result = await getRedirectResult(auth)
      if (result) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'authGetRedirectResultSuccess',
        })
        const credential = GoogleAuthProvider.credentialFromResult(result)
        window.location.replace(`/auth-handler?idToken=${credential?.idToken}`)
        console.log('result', result)
        console.log('credential', credential)
        // const token = credential.accessToken;
      }
    } catch (error) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'authGetRedirectResultError',
        eventAction: `Error code: ${(error as FirebaseError).code}`,
        eventLabel: `Error msg: ${(error as FirebaseError).message}`,
      })
      throw error
    }
  }, [firebaseApp])

  const activateFirestorePersistence = useCallback(async () => {
    const db = getFirestore(firebaseApp)
    try {
      await enableMultiTabIndexedDbPersistence(db)
    } catch (error) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'firestore:enableIndexedDbPersistenceError',
        eventAction: `Error code: ${(error as FirebaseError).code}`,
        eventLabel: `Error msg: ${(error as FirebaseError).message}`,
      })
      throw error
    } finally {
      setFsPersistenceActivationFinished(true)
    }
  }, [firebaseApp])

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    return onAuthStateChanged(auth, async (user) => {
      setLoggedInUser(user)
      setAuthInitialized(true)
    })
  }, [firebaseApp])

  useEffect(() => {
    activateFirestorePersistence()
    handleAuthRedirectResult()
  }, [activateFirestorePersistence, handleAuthRedirectResult])

  function renderAuthLoaded() {
    return (
      <UserContext.Provider value={loggedInUser}>
        <SyncManager
          render={() => (
            <BottomAdLayout>
              { children }
            </BottomAdLayout>
          )}
        />
      </UserContext.Provider>
    )
  }

  function renderLoading() {
    return (
      <BottomAdLayout shouldLoadAd={false}>
        <PageLayout
          actionbar={<ActionBar />}
        >
          <LoadingView />
        </PageLayout>
      </BottomAdLayout>
    )
  }

  return (
    <div className={s.root}>
      {(!fsPersistenceActivationFinished || !authInitialized) && renderLoading()}
      {fsPersistenceActivationFinished && authInitialized && renderAuthLoaded()}
    </div>
  )
}

export default App
