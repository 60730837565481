import React, { useEffect } from 'react'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

interface Props {
  pageId: string
  title: string
}

const PosterPage: React.FC<Props> = (props) => {
  const { common: commonConfig, postersConfig } = useBrandConfigContext()

  useEffect(() => {
    commonConfig.services.adsManager.requestIntersitialAdIfNeeded('mapPage')
  }, [props.pageId, commonConfig])

  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={props.title}
          backLinkPath={postersConfig.urls.getPostersUrl()}
        />
      )}
    >
      <PageMetadata pageId={props.pageId} />
      { props.children }
    </PageLayout>
  )
}

export default PosterPage
