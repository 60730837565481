import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { LineModel, LineItinerary, LineItineraryStop } from 'common/lines/models/line-models'
import { ReactComponent as TrendingFlatIcon } from 'common/images/trending_flat-24px.svg'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { ReactComponent as EventNodeIcon } from 'common/images/event_note-24px.svg'
import InstallAppCard from 'common/ui/components/InstallAppCard/InstallAppCard'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { ScheduleModal } from 'common/lines/components/ScheduleModal/ScheduleModal'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage } from 'react-intl'
import { LineTheme } from 'common/lines/models/line-style-models'
import s from './Line.module.scss'
import LineNumber from '../LineNumber/LineNumber'

interface LineProps {
  line: LineModel
}

function renderStop(
  stop: LineItineraryStop,
  isFirst: boolean,
  isLast: boolean,
  lineStyle: React.CSSProperties,
  index: number,
  stopsConfig: StopsBrandConfig,
) {
  const dashClassName = classNames({
    [s.dash]: true,
    [s.first]: isFirst,
    [s.last]: isLast,
  })
  return (
    <Link
      to={stopsConfig.urls.getStopUrl(stop.stopCode)}
      className={s.stopLink}
      key={`${stop.stopCode}-${index}`}
    >
      <div className={s.stopIconSection}>
        <div className={dashClassName} style={lineStyle} />
        <div className={s.stopIcon} style={lineStyle} />
        { isFirst && <TrendingFlatIcon className={s.dirArrow} /> }
      </div>
      <div className={s.stopName}>{stop.stopName}</div>
      <div className={s.stopCodeSection}>{stop.stopCode}</div>
    </Link>
  )
}

function renderItineraryStops(
  itinerary: LineItinerary,
  lineStyle: React.CSSProperties,
  stopsConfig: StopsBrandConfig,
) {
  return itinerary.stops.map((stop, index, arr) => {
    const isFirst = index === 0
    const isLast = index === arr.length - 1
    return renderStop(stop, isFirst, isLast, lineStyle, index, stopsConfig)
  })
}

function getItineraryDestinationName(
  itinerary: LineItinerary,
): string {
  return itinerary.itineraryName
}

function buildLineStyle(lineTheme: LineTheme): React.CSSProperties {
  return {
    background: lineTheme.primary,
  }
}

function renderSelectedItinerary(
  line: LineModel,
  selectedItineraryDir: string | undefined,
  stopsConfig: StopsBrandConfig,
) {
  if (!selectedItineraryDir) {
    return undefined
  }
  const lineTheme = stopsConfig.helpers.buildLineTheme({
    lineNumber: line.lineNumber,
    transportTypeCode: line.transportTypeCode,
    isNocturnal: line.isNocturnalLine,
  })
  const lineStyle = buildLineStyle(lineTheme)
  const selectedItinerary = line.itineraries.find((it) => (
    it.direction === selectedItineraryDir
  ))!
  return (
    <div>{ renderItineraryStops(selectedItinerary, lineStyle, stopsConfig) }</div>
  )
}

function renderTabs(
  itineraries: LineItinerary[],
  selectedItineraryDir: string | undefined,
  onSelect: (selectedItineraryCode: string) => void,
) {
  return (
    <div className={s.tabsPanel}>
      {
        itineraries.map((it) => (
          <button
            type="button"
            className={
              classNames({
                [s.tab]: true,
                [s.selected]: it.direction === selectedItineraryDir,
              })
            }
            key={it.direction}
            onClick={() => onSelect(it.direction)}
          >
            A {getItineraryDestinationName(it)}
          </button>
        ))
      }
    </div>
  )
}

const renderScheduleModal = (imgUrl: string | undefined, onAccept: () => void) => (
  <ScheduleModal
    isOpen={!!imgUrl}
    onRequestClose={onAccept}
    imgUrl={imgUrl}
  />
)

function renderScheduleButton(
  imgUrl: string,
  destination: string,
  handleClick: (imgUrl: string) => void,
) {
  return (
    <button
      className={s.scheduleCard}
      type="button"
      onClick={() => handleClick(imgUrl)}
    >
      <div className={s.scheduleButton}>
        {/* <div className={s.scheduleImgContainer}>
          <img src={imgUrl} alt="" className={s.scheduleImg} />
        </div> */}
        <EventNodeIcon className={s.scheduleIcon} />
        <div className={s.rightPart}>
          <FormattedMessage id="line_schedule_btn" values={{ destination }} />
        </div>
      </div>
    </button>
  )
}

function renderSchedulesSelector(
  line: LineModel,
  handleClick: (imgUrl: string) => void,
) {
  return (
    <div className={s.schedulesSection}>
      {
        line.schedules.one && renderScheduleButton(
          line.schedules.one,
          line.destinationName,
          handleClick,
        )
      }
      {
        line.schedules.two && renderScheduleButton(
          line.schedules.two,
          line.originName,
          handleClick,
        )
      }
    </div>
  )
}

const Line: React.FC<LineProps> = ({ line }) => {
  const { stops: stopsConfig, common: commonConfig } = useBrandConfigContext()
  const { helpers, pageIds, urls } = stopsConfig
  const DisplayAdWrapperComponent = commonConfig.components.displayAdWrapperComponent

  const mainItineraries = line.itineraries.filter((it) => it.isMainItinerary)
  const [selectedItineraryDir, setSelectedItineraryDir] = useState<string | undefined>(
    mainItineraries[0]?.direction,
  )
  const [imgUrlToShow, setImgUrlToShow] = useState<string | undefined>()
  const stopTypeNumCode = helpers.getStopTypeCodeFromTransportTypeCode(line.transportTypeCode)
  const lineMapUrl = urls.getStopsMapUrl(
    undefined, stopTypeNumCode, line.lineNumber,
  )

  function closeScheduleModal() {
    setImgUrlToShow(undefined)
  }

  function showSchedule(imgUrl: string) {
    setImgUrlToShow(imgUrl)
  }

  return (
    <div>
      <div className={s.lineHeader}>
        <div className={s.headerMain}>
          <div className={s.leftSection}>
            <LineNumber
              lineNumber={line.lineNumber}
              transportTypeCode={line.transportTypeCode}
              isNocturnal={line.isNocturnalLine}
            />
          </div>

          <div className={s.rightSection}>
            <h1 className={s.lineName}>{ line.lineName }</h1>
            <div className={s.headerSecondaryTitle}>
              { helpers.composeLineDescription(line.transportTypeCode) }
            </div>
          </div>
        </div>
        <div className={s.headerActions}>
          <Link to={lineMapUrl} className={s.action}>
            <FormattedMessage id="line_show_on_map_btn" />
          </Link>
        </div>
      </div>

      <DisplayAdWrapperComponent pageId={pageIds.line} />

      { (line.schedules.one || line.schedules.two)
      && renderSchedulesSelector(line, showSchedule) }

      <ExternalSectionTitle title={<FormattedMessage id="line_stops_title" />} />
      <div className={s.itinerariesCard}>
        {mainItineraries.length > 1 && renderTabs(
          mainItineraries, selectedItineraryDir, setSelectedItineraryDir,
        )}
        { renderSelectedItinerary(line, selectedItineraryDir, stopsConfig) }
      </div>
      <InstallAppCard className={s.installAppCard} />

      {renderScheduleModal(imgUrlToShow, closeScheduleModal)}
    </div>
  )
}

export default Line
