export interface StopFav {
  name: string
  stopCode: string
}

interface StoredFavorite {
  name: string
  stopCode: string
}

interface StoredPayload {
  items: StoredFavorite[]
}

export default class StopFavsStore {
  constructor(private localStorageKey: string) {}

  public getStopFavs(): StopFav[] {
    const dataStr = window.localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return StopFavsStore.convertPayloadToFavorites(JSON.parse(dataStr))
    }
    return []
  }

  public setStopFavs(favorites: StopFav[]) {
    const payload = StopFavsStore.convertFavoritesToPayload(favorites)
    window.localStorage.setItem(
      this.localStorageKey, JSON.stringify(payload),
    )
  }

  public deleteAllStopFavs() {
    window.localStorage.removeItem(this.localStorageKey)
  }

  private static convertPayloadToFavorites(payload: StoredPayload): StopFav[] {
    return payload.items.map<StopFav>((storedFavorite) => ({
      name: storedFavorite.name,
      stopCode: storedFavorite.stopCode,
    }))
  }

  private static convertFavoritesToPayload(favorites: StopFav[]): StoredPayload {
    const itemsToStore = favorites.map<StoredFavorite>((favorite) => ({
      name: favorite.name,
      stopCode: favorite.stopCode,
    }))
    return {
      items: itemsToStore,
    }
  }
}
