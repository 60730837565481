import React from 'react'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import PostersCatalog from 'common/posters/components/PostersCatalog/PostersCatalog'
import { useIntl } from 'react-intl'
import { PageId } from '../../../core/models/app-models'
import { RouteManager } from '../../../core/services/route-manager/RouteManager'
import busLinesPosterPreviewImg from '../../images/maps_preview_bus_lines.jpg'
import busStopsPosterPreviewImg from '../../images/maps_preview_bus_stops.jpg'
import busNocturnalPosterImgUrl from '../../images/preview_plano_bus_nocturno.jpg'

const PostersPage: React.FC = () => {
  const intl = useIntl()
  return (
    <PageLayout
      actionbar={<ActionBar title={intl.formatMessage({ id: 'posters_action_bar_title' })} />}
    >
      <PageMetadata pageId={PageId.PostersPage} />
      <Container>
        <PostersCatalog
          previews={[
            {
              text: intl.formatMessage({ id: 'posters_bus_lines' }),
              previewUrl: busLinesPosterPreviewImg,
              linkUrl: RouteManager.getBusLinesPosterUrl(),
              isOffline: false,
            },
            {
              text: intl.formatMessage({ id: 'posters_bus_stops' }),
              previewUrl: busStopsPosterPreviewImg,
              linkUrl: RouteManager.getBusStopsPosterUrl(),
              isOffline: false,
            },
            {
              text: intl.formatMessage({ id: 'posters_nocturnal_buses' }),
              previewUrl: busNocturnalPosterImgUrl,
              linkUrl: RouteManager.getNocturnalBusPosterUrl(),
              isOffline: false,
            },
          ]}
        />
      </Container>
    </PageLayout>
  )
}

export default PostersPage
