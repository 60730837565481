import React from 'react'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import AuthSettings from 'common/auth/components/AuthSettings/AuthSettings'
import { FormattedMessage } from 'react-intl'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { ClientType } from 'common/core/models/param-app-config-models'
import SecondaryActions from '../SecondaryActions/SecondaryActions'
import ThemeSelector from '../ThemeSelector/ThemeSelector'
import SettingsLocaleChanger from '../SettingsLocaleChanger/SettingsLocaleChanger'

const SettingsPage: React.FC = function SettingsPage() {
  const { common: { services: { paramAppConfig } } } = useBrandConfigContext()
  return (
    <PageLayout
      actionbar={<ActionBar title={<FormattedMessage id="settings_action_bar_title" />} />}
    >
      <Container>
        { paramAppConfig.clientType !== ClientType.IOS && (
          <>
            <ExternalSectionTitle title={<FormattedMessage id="auth_user_account" />} />
            <AuthSettings />
          </>
        )}
        <ExternalSectionTitle title={<FormattedMessage id="settings_theme" />} />
        <ThemeSelector />
        <SettingsLocaleChanger />
        <ExternalSectionTitle title={<FormattedMessage id="settings_more_stuff" />} />
        <SecondaryActions />
      </Container>
    </PageLayout>
  )
}

export default SettingsPage
