import { UserDoc } from 'common/stops/models/firestore-models'
import { FirebaseApp } from 'firebase/app'
import { User } from 'firebase/auth'
import {
  doc, DocumentReference, getDoc, getDocFromCache, getDocFromServer, getFirestore, setDoc,
} from 'firebase/firestore'
import { StopFav } from '../stop-favs-store/StopFavsStore'

export enum CityId {
  Madrid = 'madrid',
  Barcelona = 'barcelona',
  Bilbao = 'bilbao',
}

export class UserFirestoreClient {
  private alreadyReadFromServer = false

  constructor(
    private firebaseApp: FirebaseApp,
    private cityId: CityId,
  ) {}

  private async getDocWithCache(docRef: DocumentReference) {
    if (this.alreadyReadFromServer) {
      return getDocFromCache<UserDoc>(docRef)
    }
    const document = await getDoc<UserDoc>(docRef)
    this.alreadyReadFromServer = true
    return document
  }

  private getUserDocRef(user: User) {
    const db = getFirestore(this.firebaseApp)
    return doc(db, 'cities', this.cityId, 'users', user.uid)
  }

  public async getStopFavsFromServer(user: User) {
    const userDocRef = this.getUserDocRef(user)
    const userDocSnap = await getDocFromServer<UserDoc>(userDocRef)
    if (userDocSnap.exists()) {
      return userDocSnap.data().stopFavs || []
    }
    return []
  }

  public async getStopFavsWithCache(user: User) {
    const userDocRef = this.getUserDocRef(user)
    const userDocSnap = await this.getDocWithCache(userDocRef)
    if (userDocSnap.exists()) {
      return userDocSnap.data().stopFavs || []
    }
    return []
  }

  public async setStopFavs(user: User, favs: StopFav[]) {
    const userDocRef = this.getUserDocRef(user)
    return setDoc(userDocRef, { stopFavs: favs }, { merge: true })
  }
}
