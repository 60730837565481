import React, { useEffect, useState } from 'react'

interface Props {
  adsenseSlot: string
}

const AD_REFRESH_INTERVAL_IN_MILLISECONDS = 40 * 1000

const BannerDisplayAd: React.FC<Props> = ({ adsenseSlot }) => {
  const [adCounter, setAdCounter] = useState(1)

  function showAd() {
    const w = window as any
    (w.adsbygoogle = w.adsbygoogle || []).push({})
  }

  useEffect(() => {
    showAd()
    setTimeout(() => {
      setAdCounter(adCounter + 1)
    }, AD_REFRESH_INTERVAL_IN_MILLISECONDS)
  }, [adsenseSlot, adCounter, setAdCounter])

  return (
    <ins
      key={adCounter}
      className="adsbygoogle"
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '60px',
      }}
      data-ad-client="ca-pub-4759034046917334"
      data-ad-slot={adsenseSlot}
      data-ad-format="fluid"
    />
  )
}

export default BannerDisplayAd
