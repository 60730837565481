import React from 'react'
import 'pinch-zoom-element'
import s from './PosterIframeView.module.scss'

interface Props {
  title: string
  iframeUrl: string
}

const PosterIframeView: React.FC<Props> = (props) => (
  <iframe
    title={props.title}
    src={props.iframeUrl}
    className={s.easyZoomIframe}
    data-hj-whitelist
  />
)

export default PosterIframeView
