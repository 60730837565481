import React from 'react'
import { CSSTransition } from 'react-transition-group'
import s from './EnteringAnimation.module.scss'

const EnteringAnimation: React.FC = ({ children }) => {
  const nodeRef = React.useRef(null)
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in
      appear
      timeout={150}
      classNames={{
        appear: s.appear,
        appearActive: s.appearActive,
      }}
    >
      <div className={s.container} ref={nodeRef}>{ children }</div>
    </CSSTransition>
  )
}

export default EnteringAnimation
