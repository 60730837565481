import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import React, { useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { TransManagerContext } from '../hooks/useTransManagerContext'

interface Props {
  translationGroups: {[locale: string]: any}
  allowToShowLocaleSelectionModal: boolean
}

function getTranslationsForLocale(translationGroups: {[locale: string]: any}, locale: string) {
  const foundGroup = translationGroups[locale]
  if (!foundGroup) {
    throw new Error(`Cannot find translations for '${locale}' locale`)
  }
  return foundGroup
}

const TransManager: React.FC<Props> = ({
  children, translationGroups, allowToShowLocaleSelectionModal,
}) => {
  const defaultLocale = 'es'
  const commonConfig = useBrandConfigContext().common
  const { i18nPersistor } = commonConfig.services

  const [localeSelectedByUser, setLocaleSelectedByUser] = useState(i18nPersistor.getLocale())

  const usedLocale = localeSelectedByUser || defaultLocale
  const translationsForLocale = getTranslationsForLocale(translationGroups, usedLocale)

  const contextValue = useMemo(() => ({
    changeLocale: (locale: string) => {
      setLocaleSelectedByUser(locale)
      i18nPersistor.setLocale(locale)
    },
    localeSelectedByUser,
    usedLocale,
    allLocales: Object.keys(translationGroups),
    allowToShowLocaleSelectionModal,
  }), [
    localeSelectedByUser, usedLocale, i18nPersistor,
    translationGroups, allowToShowLocaleSelectionModal,
  ])

  return (
    <IntlProvider
      messages={translationsForLocale}
      locale={usedLocale}
      defaultLocale={defaultLocale}
    >
      <TransManagerContext.Provider value={contextValue}>
        { children }
      </TransManagerContext.Provider>
    </IntlProvider>
  )
}

export default TransManager
