import axios from 'axios'
import { StopListItem } from 'common/stops/models/stop-list-models'

export class StopListApiClient {
  constructor(
    private apiGateway: string,
  ) {}

  public async getStopList(): Promise<StopListItem[]> {
    const url = this.apiGateway
    const response = await axios.get<StopListItem[]>(url, {
      timeout: 15 * 1000,
    })
    return response.data
  }
}
