import React from 'react'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { AppModal, AppModalSize } from 'common/ui/components/AppModal/AppModal'
import { ReactComponent as WhatsAppIcon } from 'common/images/social/whatsapp.svg'
import { ReactComponent as TelegramIcon } from 'common/images/social/telegram.svg'
import { ReactComponent as CopyIcon } from 'common/images/copy.svg'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { useIntl } from 'react-intl'
import s from './ShareAppModal.module.scss'

interface Props {
  isOpen: boolean
  onAccept: () => void
}

const ShareAppModal = ({ isOpen, onAccept }: Props) => {
  const intl = useIntl()
  const commonConfig = useBrandConfigContext().common
  const appUrl = commonConfig.downloadAppUrl
  const text = commonConfig.helpers.shareAppText(intl)
  const appUrlAndText = `${text}: ${appUrl}`

  const encodedAppUrl = encodeURI(appUrl)
  const encodedText = encodeURI(text)
  const encodedAppUrlAndText = encodeURI(appUrlAndText)

  return (
    <AppModal
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'share_app_modal_title' })}
      shouldShowCloseButton
      shouldCloseOnOverlayClick
      onRequestClose={onAccept}
      maxWidth={AppModalSize.Normal}
    >
      { intl.formatMessage({ id: 'share_app_modal_text_one' }) }
      <input type="text" className={s.textarea} readOnly value={appUrl} />

      <button
        type="button"
        className={classNames(s.option, s.clipboard)}
        onClick={() => {
          copy(appUrl)
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'shareApp:copyToClipboard',
          })
        }}
      >
        <CopyIcon />{intl.formatMessage({ id: 'share_app_modal_copy_clipboard' })}
      </button>
      <a
        className={classNames(s.option, s.whatsapp)}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?text=${encodedAppUrlAndText}&target=external`}
        onClick={() => {
          window.ga('send', {
            hitType: 'event',
            transport: 'beacon',
            eventCategory: 'shareApp:whatsapp',
          })
        }}
      >
        <WhatsAppIcon />{intl.formatMessage({ id: 'share_app_modal_whatsapp' })}
      </a>
      <a
        className={classNames(s.option, s.telegram)}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://t.me/share/url?text=${encodedText}&url=${encodedAppUrl}&target=external`}
        onClick={() => {
          window.ga('send', {
            hitType: 'event',
            transport: 'beacon',
            eventCategory: 'shareApp:telegram',
          })
        }}
      >
        <TelegramIcon />{intl.formatMessage({ id: 'share_app_modal_telegram' })}
      </a>
    </AppModal>
  )
}

export default ShareAppModal
