import React from 'react'
import { OpStatus } from 'common/ui/models/ui-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import ErrorView from 'common/ui/components/aux-views/ErrorView'
import NoInternetError from 'common/ui/components/aux-views/NoInternetError'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import { isNoInternetError } from 'common/shared/helpers/network-utils'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import { LineListItem } from 'common/lines/models/line-list-models'
import LineSearch from 'common/lines/components/LineSearch/LineSearch'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'

interface LinesPageViewProps {
  retrieveStatus?: OpStatus
  retrieveError?: Error
  lineList: LineListItem[] | undefined
  defaultQuery?: string
  onRetryFetchButtonClick: () => void
}

const renderLoading = () => (
  <LoadingView><FormattedMessage id="line_search_loading" /></LoadingView>
)

const renderNoInternetError = (props: LinesPageViewProps) => (
  <NoInternetError onRetryButtonClick={props.onRetryFetchButtonClick} />
)

const renderNoStopsDataError = (props: LinesPageViewProps) => {
  const text = <FormattedMessage id="line_search_error" />
  return (
    <ErrorView mainText={text} onRetryButtonClick={props.onRetryFetchButtonClick} />
  )
}

const renderErrorView = (props: LinesPageViewProps) => {
  if (props.retrieveStatus === OpStatus.Failed) {
    if (isNoInternetError(props.retrieveError!)) {
      return renderNoInternetError(props)
    }
    return renderNoStopsDataError(props)
  }
  return undefined
}

const LinesPageView: React.FC<LinesPageViewProps> = (props) => {
  const { pageIds } = useBrandConfigContext().stops
  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={<FormattedMessage id="line_search_action_bar_title" />}
        />
      )}
    >
      <PageMetadata pageId={pageIds.lines} />
      <Container>
        { props.retrieveStatus === OpStatus.InProgress ? renderLoading() : undefined }
        { renderErrorView(props) }
        { props.retrieveStatus === OpStatus.Success
          ? (
            <LineSearch
              lineList={props.lineList!}
              defaultQuery={props.defaultQuery}
            />
          ) : undefined }
      </Container>
    </PageLayout>
  )
}

export default LinesPageView
