import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'
import s from './SearchTabPanel.module.scss'

interface SearchTabPanelProps {
  isMapSelected: boolean
}

const SearchTabPanel: React.FC<SearchTabPanelProps> = ({ isMapSelected }) => {
  const { getStopSearchUrl, getStopsMapUrl } = useBrandConfigContext().stops.urls
  return (
    <div className={s.tabsContainer}>
      <Link
        to={getStopSearchUrl()}
        className={classNames({ [s.tab]: true, [s.selected]: !isMapSelected })}
      >
        <FormattedMessage id="search_tab_panel_by_text" />
      </Link>
      <Link
        to={getStopsMapUrl()}
        className={classNames({ [s.tab]: true, [s.selected]: isMapSelected })}
      >
        <FormattedMessage id="search_tab_panel_on_map" />
      </Link>
    </div>
  )
}

export default SearchTabPanel
