import React from 'react'
import { Link } from 'react-router-dom'
import CardLink from 'common/ui/components/CardLink/CardLink'
import { PosterPreviewModel } from 'common/posters/models/posters-models'
import { FormattedMessage } from 'react-intl'
import s from './PosterPreviewLink.module.scss'

interface MapPreviewLinkProps {
  model: PosterPreviewModel
}

const PosterPreviewLink: React.FC<MapPreviewLinkProps> = ({
  model: {
    text, previewUrl, linkUrl, isOffline,
  },
}) => (
  <Link to={linkUrl} className={s.mapPreview}>
    <CardLink
      leftChildren={(
        <div className={s.previewImg}>
          <img
            src={previewUrl}
            alt={text}
          />
        </div>
      )}
    >
      <div className={s.previewText}>
        <div className={s.mainText}>{text}</div>
        <div className={s.secondaryText}>
          {
            isOffline
              ? <FormattedMessage id="posters_available_offline" />
              : <FormattedMessage id="posters_not_available_offline" />
          }
        </div>
      </div>
    </CardLink>
  </Link>
)

export default PosterPreviewLink
