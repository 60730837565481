import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import s from './DisplayAd.module.scss'

interface Props {
  adsenseSlot: string
  pageId: string
}

const DisplayAd: React.FC<Props> = ({ adsenseSlot, pageId }) => {
  useEffect(() => {
    const w = window as any
    (w.adsbygoogle = w.adsbygoogle || []).push({})
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'adsenseDisplayAd:show',
      eventAction: pageId,
    })
  }, [pageId])

  const [isVisible, setIsVisible] = useState(true)

  function handleCloseAdClick() {
    setIsVisible(false)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'adsenseDisplayAd:close',
      eventAction: pageId,
    })
  }

  if (!isVisible) {
    return <></>
  }

  return (
    <>
      <ExternalSectionTitle title={<FormattedMessage id="ads_advertisement_title" />}>
        <button type="button" className={s.closeButton} onClick={handleCloseAdClick}>
          <FormattedMessage id="ads_hide" />
        </button>
      </ExternalSectionTitle>
      <div className={s.adWrapper}>
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          data-ad-client="ca-pub-4759034046917334"
          data-ad-slot={adsenseSlot}
          data-ad-format="fluid"
        />
      </div>
    </>
  )
}

export default DisplayAd
