import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { OpStatus } from 'common/ui/models/ui-models'
import { LineModel } from 'common/lines/models/line-models'
import AxImpression from 'common/analytics/components/AxImpression/AxImpression'
import LinePageView from 'common/lines/components/LinePageView/LinePageView'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

const LinePage: React.FC = () => {
  const { lineCode } = useParams<{lineCode: string}>()
  const [retrieveLineStatus, setRetrieveLineStatus] = useState<OpStatus>()
  const [retrieveLineError, setRetrieveLineError] = useState<Error>()
  const [line, setLine] = useState<LineModel>()

  const stopsConfig = useBrandConfigContext().stops

  const fetchData = useCallback(async () => {
    setRetrieveLineStatus(OpStatus.InProgress)
    setRetrieveLineError(undefined)
    try {
      const retrievedLine = await stopsConfig.services.lineApiClient.getLine(lineCode)
      setLine(retrievedLine)
      setRetrieveLineStatus(OpStatus.Success)
    } catch (error) {
      setRetrieveLineError(error)
      setRetrieveLineStatus(OpStatus.Failed)
    }
  }, [lineCode, stopsConfig])

  useEffect(() => { fetchData() }, [lineCode, fetchData])

  return (
    <>
      <AxImpression category="linePageView" />
      <LinePageView
        retrieveLineStatus={retrieveLineStatus}
        retrieveLineError={retrieveLineError}
        line={line}
        onRefreshButtonClick={fetchData}
      />
    </>
  )
}

export default LinePage
