import * as Sentry from '@sentry/react'
import { ParamAppConfig } from 'common/core/models/param-app-config-models'

function transformNumberToString(value: number | undefined): string {
  return value ? value.toString() : ''
}

export function initSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: 'https://c878f1f137134a44b6cf9dd2fdfcfd5e@sentry.io/3153198' })
  }
}

export function configureSentry(paramAppConfig: ParamAppConfig) {
  Sentry.configureScope((scope) => {
    scope.setTag('otClientType', paramAppConfig.clientType)
    scope.setTag(
      'otAndroidClientVersionCode',
      transformNumberToString(paramAppConfig.androidClientVersionCode),
    )
    scope.setTag(
      'otIosClientVersion',
      paramAppConfig.iosClientVersion || '',
    )
  })
}
