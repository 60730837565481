import React, { useCallback, useEffect, useState } from 'react'
import { User } from 'firebase/auth'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import BottomAdLayout from 'common/ads/components/BottomAdLayout/BottomAdLayout'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import Container from 'common/ui/components/Container/Container'
import { FormattedMessage } from 'react-intl'
import s from './SyncExecutor.module.scss'

interface Props {
  user: User
  localStopFavs: StopFav[]
  onSyncEnd: () => void
}

const SyncExecutor: React.FC<Props> = ({ user, localStopFavs, onSyncEnd }) => {
  const {
    common: {
      services: {
        userFirestoreClient,
      },
    },
  } = useBrandConfigContext()

  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false)
  const [firestoreStopFavs, setFirestoreStopFavs] = useState<StopFav[]>()

  const createStopFavsInFirestore = useCallback(async (stopFavs: StopFav[]) => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'syncUploadStopFavsStart',
    })
    try {
      await userFirestoreClient.setStopFavs(user, stopFavs)
    } catch (error) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'syncUploadStopFavsError',
      })
      throw error
    }
    onSyncEnd()
  }, [user, onSyncEnd, userFirestoreClient])

  const getStopFavs = useCallback(async () => {
    try {
      return await userFirestoreClient.getStopFavsFromServer(user)
    } catch (error) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'syncGetStopFavsError',
      })
      throw error
    }
  }, [user, userFirestoreClient])

  const performSync = useCallback(async () => {
    const stopFavs = await getStopFavs()
    if (stopFavs.length > 0) {
      setFirestoreStopFavs(stopFavs)
      setIsMergeModalOpen(true)
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'syncMergeMsgShow',
      })
    } else {
      createStopFavsInFirestore(localStopFavs)
    }
  }, [createStopFavsInFirestore, localStopFavs, getStopFavs])

  useEffect(() => {
    performSync()
  }, [performSync])

  function mergeLocalAndFirestoreStopFavs() {
    const firestoreStopFavsNotIncludedLocally = firestoreStopFavs!.filter((firestoreFav) => {
      const isFirestoreFavFoundLocally = localStopFavs.find(
        (localFav) => localFav.stopCode === firestoreFav.stopCode,
      )
      return !isFirestoreFavFoundLocally
    })
    const mergedFavs = [...firestoreStopFavsNotIncludedLocally, ...localStopFavs]
    createStopFavsInFirestore(mergedFavs)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'syncMergeSelectUploadLocalFavs',
    })
  }

  function deleteLocalStopFavs() {
    onSyncEnd()
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'syncMergeSelectDeleteLocalFavs',
    })
  }

  function renderMergeModal() {
    return (
      <div className={s.card}>
        <FormattedMessage id="sync_intro" />
        <button
          type="button"
          onClick={mergeLocalAndFirestoreStopFavs}
          className={s.button}
        >
          <FormattedMessage id="sync_btn_upload_local_stop_favs" />
        </button>
        <button
          type="button"
          onClick={deleteLocalStopFavs}
          className={s.button}
        >
          <FormattedMessage id="sync_btn_delete_local_stop_favs" />
        </button>
      </div>
    )
  }

  return (
    <BottomAdLayout shouldLoadAd={false}>
      <PageLayout
        actionbar={<ActionBar title={<FormattedMessage id="sync_title" />} />}
      >
        <Container>
          { isMergeModalOpen && renderMergeModal()}
        </Container>
      </PageLayout>
    </BottomAdLayout>
  )
}

export default SyncExecutor
