import React, { useCallback, useEffect, useState } from 'react'
import {
  SortableContainer, SortableElement, SortEndHandler, SortableHandle,
} from 'react-sortable-hoc'
import { ReactComponent as DragHandleIcon } from 'common/images/drag_handle-24px.svg'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import ShortStop from 'common/stops/components/ShortStop/ShortStop'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage, useIntl } from 'react-intl'
import { OpStatus } from 'common/ui/models/ui-models'
import useUserContext from 'common/auth/hooks/useUserContext'
import { StopFavsCollection } from 'common/stops/helpers/StopFavsCollection'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import { SpinnerSize } from 'common/ui/components/Spinner/Spinner'
import s from './FavoritesSection.module.scss'

interface SortableItemProps {
  item: StopFav
}

interface SortableListProps {
  favs: StopFav[]
  editModeEnabled: boolean
}

const renderEmptyFavs = () => (
  <div className={s.noFavorites}>
    <FormattedMessage id="stops_no_favorites" />
  </div>
)

const DragHandle = SortableHandle(() => (
  <div className={s.dragHandleContainer}>
    <div className={s.dragHandle}><DragHandleIcon /></div>
  </div>
))

const SortableItem = SortableElement(({ item }: SortableItemProps) => (
  <ShortStop
    key={item.stopCode}
    stopCode={item.stopCode}
    stopName={item.name}
    dragHandle={<DragHandle />}
  />
))

const SortableList = SortableContainer((props: SortableListProps) => (
  <div className={s.favs}>
    {props.favs.map((fav, index) => (
      <SortableItem key={fav.stopCode} index={index} item={fav} />
    ))}
  </div>
))

const renderNoEditableFavs = (favs: StopFav[]) => (
  <div className={s.favs}>
    {favs.map((fav) => (
      <ShortStop
        stopCode={fav.stopCode}
        stopName={fav.name}
        key={fav.stopCode}
      />
    ))}
  </div>
)

const renderFavs = (
  favs: StopFav[],
  onSortEnd: SortEndHandler,
  editModeEnabled: boolean,
) => {
  if (editModeEnabled) {
    return (
      <SortableList
        favs={favs}
        editModeEnabled={editModeEnabled}
        onSortEnd={onSortEnd}
        useDragHandle
        lockAxis="y"
        useWindowAsScrollContainer
      />
    )
  }
  return renderNoEditableFavs(favs)
}

const renderEditButton = (editModeEnabled: boolean, handleEditModeToggle: () => void) => (
  <button type="button" className={s.editButton} onClick={handleEditModeToggle}>
    { editModeEnabled
      ? <FormattedMessage id="stops_favs_sort_finish" />
      : <FormattedMessage id="stops_favs_sort_start" />}
  </button>
)

const FavoritesSection: React.FC = () => {
  const intl = useIntl()
  const { stopFavsService } = useBrandConfigContext().stops.services
  const currentUser = useUserContext()
  const [favsRetrieveStatus, setFavsRetrieveStatus] = useState<OpStatus>()
  const [favs, setFavs] = useState<StopFav[]>()
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const newFavs = new StopFavsCollection(favs!)
      .reorderStopFav(oldIndex, newIndex)
      .getAllStopFavs()
    stopFavsService.setStopFavs(currentUser, newFavs)
    setFavs(newFavs)
  }

  const retrieveFavs = useCallback(async () => {
    setFavsRetrieveStatus(OpStatus.InProgress)
    try {
      const retrievedFavs = await stopFavsService.getStopFavs(currentUser)
      console.log('retrieveFavs success')
      setFavs(retrievedFavs)
      setFavsRetrieveStatus(OpStatus.Success)
    } catch (error) {
      setFavsRetrieveStatus(OpStatus.Failed)
      console.log('retrieveFavs error', error)
      throw error
    }
  }, [stopFavsService, currentUser])

  useEffect(() => { retrieveFavs() }, [retrieveFavs])

  const handleEditModeToggle = () => {
    setEditModeEnabled(!editModeEnabled)
  }

  function renderTitle(content?: React.ReactElement) {
    return (
      <ExternalSectionTitle title={intl.formatMessage({ id: 'stops_favs_title' })}>
        { content }
      </ExternalSectionTitle>
    )
  }

  if (favsRetrieveStatus === OpStatus.InProgress) {
    return (
      <>
        {renderTitle()}
        <div className={s.favs}>
          <LoadingView spinnerSize={SpinnerSize.Small} />
        </div>
      </>
    )
  }

  if (favsRetrieveStatus === OpStatus.Failed) {
    return (
      <>
        {renderTitle()}
        <div className={s.errorCard}>
          <FormattedMessage id="stop_favs_error" />
          <button
            type="button"
            className={s.retryButton}
            onClick={retrieveFavs}
          >
            <FormattedMessage id="retry" />
          </button>
        </div>
      </>
    )
  }

  function renderTitleSecondaryContent() {
    if (favs!.length > 1) {
      return renderEditButton(editModeEnabled, handleEditModeToggle)
    }
    return undefined
  }

  if (favsRetrieveStatus === OpStatus.Success && favs) {
    return (
      <>
        { renderTitle(renderTitleSecondaryContent()) }
        {
          favs.length > 0
            ? renderFavs(favs, onSortEnd, editModeEnabled)
            : renderEmptyFavs()
        }
      </>
    )
  }

  return <></>
}

export default FavoritesSection
