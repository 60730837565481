import React, { ReactElement } from 'react'
import classNames from 'classnames'
import s from './FeedbackGenericButtonStyle.module.scss'

type ContentRenderingFn = (innerContent: JSX.Element) => JSX.Element

interface FeedbackGenericButtonProps {
  className?: string
  children: (contentRenderingFn: ContentRenderingFn) => ReactElement | null
}

const FeedbackGenericButton: React.FC<FeedbackGenericButtonProps> = (props) => {
  function onClick() {
    const { $crisp } = window as any
    $crisp.push(['do', 'chat:open'])
    $crisp.push(['do', 'chat:show'])
  }

  const contentRenderingFn: ContentRenderingFn = (innerContent) => (
    <>
      <button
        type="button"
        className={classNames(s.root, props.className)}
        onClick={onClick}
      >
        { innerContent }
      </button>
    </>
  )

  return props.children(contentRenderingFn)
}

export default FeedbackGenericButton
