import React from 'react'
import BannerDisplayAd from 'common/ads/components/BannerDisplayAd/BannerDisplayAd'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { BottomAdContext } from 'common/ads/hooks/useBottomAdContext'
import s from './BottomAdLayout.module.scss'

interface Props {
  shouldLoadAd?: boolean
}

const BottomAdLayout: React.FC<Props> = ({ children, shouldLoadAd = true }) => {
  const {
    bottomBannerAdSlot,
    services,
    helpers: { shouldShowBottomBannerAd },
  } = useBrandConfigContext().common
  const shouldShowFixedBottomAd = shouldShowBottomBannerAd(services.paramAppConfig)

  function renderAd() {
    return (
      <div className={s.fixedBottomAd}>
        <div className={s.fixedBottomAdCentered}>
          {shouldLoadAd && <BannerDisplayAd adsenseSlot={bottomBannerAdSlot} />}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={s.content}
      >
        <BottomAdContext.Provider value={{ isBottomAdShown: shouldShowFixedBottomAd }}>
          { children }
        </BottomAdContext.Provider>
      </div>
      { shouldShowFixedBottomAd && renderAd()}
    </>
  )
}

export default BottomAdLayout
