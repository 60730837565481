import React from 'react'
import classNames from 'classnames'
import CardLink from 'common/ui/components/CardLink/CardLink'
import { ReactComponent as ThumbUpIcon } from 'common/images/thumb_up-24px.svg'
import { ClientType } from 'common/core/models/param-app-config-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'
import s from './RateAppCard.module.scss'

interface RateAppProps {
  className?: string
}

function sendEvent() {
  window.ga('send', {
    hitType: 'event',
    transport: 'beacon',
    eventCategory: 'rateAppLinkClick',
  })
}

const RateAppCard: React.FC<RateAppProps> = (props) => {
  const { common: commonConfig } = useBrandConfigContext()
  if (commonConfig.services.paramAppConfig.clientType === ClientType.Web) {
    return <></>
  }
  const leftChildren = (
    <div className={s.appIcon}>
      <ThumbUpIcon />
    </div>
  )
  const smallTextClassname = classNames('globalInlineBlock', s.smallText)
  return (
    <a
      href={commonConfig.downloadAppUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(s.root, props.className)}
      onClick={sendEvent}
    >
      <CardLink leftChildren={leftChildren}>
        <span className={smallTextClassname}>
          <FormattedMessage id="rate_app_card_secondary" />
        </span>
        <div className={s.button}>
          <FormattedMessage id="rate_app_card_primary" />
        </div>
      </CardLink>
    </a>
  )
}

export default RateAppCard
