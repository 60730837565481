import React from 'react'
import StopZoneSelector from 'common/stops/components/StopZoneSelector/StopZoneSelector'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import { Stop } from 'common/stops/models/stop-models'
import StopActions from 'common/stops/components/StopActions/StopActions'
import { getFinalStopName } from 'common/stops/helpers/ui-helpers'
import s from './StopHeader.module.scss'

/* For stop names like 'Intercambiador Moncloa | Isla 1' */
function addHtmlLineBreaksInStopName(originalStopName: string) {
  if (!originalStopName.includes('|')) {
    return originalStopName
  }
  return originalStopName.split(' |').map((item, index) => {
    if (index === 0) {
      return <React.Fragment key={item}>{item}&nbsp;|</React.Fragment>
    }
    return <span className={s.oneLine} key={item}>{item}</span>
  })
}

interface Props {
  stop: Stop
  stopFav?: StopFav
  onAddToFavoritesButtonClick: () => void
  onRemoveFromFavoritesButtonClick: () => void
  onRenameFavoriteButtonClick: () => void
}

const StopHeader: React.FC<Props> = (props) => {
  const stop = props.stop!
  const { components, helpers } = useBrandConfigContext().stops
  const BigStopIconComponent = components.bigStopIcon
  return (
    <div className={s.bigHeader}>
      <div className={s.header}>
        <div className={s.headerLeftSection}>
          <BigStopIconComponent stopTypeCode={stop.stopTypeCode} />
        </div>
        <div className={s.headerRightSection}>
          <div className={s.headerTitle}>
            {addHtmlLineBreaksInStopName(getFinalStopName(stop, props.stopFav))}
          </div>
          <div className={s.headerSecondaryTitle}>
            { helpers.composeStopDescription(stop.stopTypeCode)} { stop.stopCode }
          </div>
          <StopZoneSelector stop={stop} />
        </div>
      </div>
      <StopActions
        stop={props.stop}
        stopFav={props.stopFav}
        onAddToFavoritesButtonClick={props.onAddToFavoritesButtonClick}
        onRemoveFromFavoritesButtonClick={props.onRemoveFromFavoritesButtonClick}
        onRenameFavoriteButtonClick={props.onRenameFavoriteButtonClick}
      />
    </div>
  )
}

export default StopHeader
