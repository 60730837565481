import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  stopCode: string
}

const StopLoadingContent: React.FC<Props> = () => (
  <FormattedMessage id="stop_loading" />
)

export default StopLoadingContent
