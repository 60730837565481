import arrayMove from 'array-move'
import { StopFav } from '../services/stop-favs-store/StopFavsStore'

export class StopFavsCollection {
  constructor(private stopFavs: StopFav[]) {}

  findStopFav(stopCode: string) {
    return this.stopFavs.find((stop) => stop.stopCode === stopCode)
  }

  addStopFav(stop: StopFav) {
    const newFavs = [...this.removeStopFav(stop.stopCode).getAllStopFavs(), stop]
    return new StopFavsCollection(newFavs)
  }

  renameStopFav(stopCode: string, newName: string) {
    const newFavs = this.stopFavs.map<StopFav>((stopFav) => {
      if (stopFav.stopCode === stopCode) {
        return {
          stopCode,
          name: newName,
        }
      }
      return stopFav
    })
    return new StopFavsCollection(newFavs)
  }

  removeStopFav(stopCode: string) {
    const newFavs = this.stopFavs.filter((stop) => (
      stop.stopCode !== stopCode
    ))
    return new StopFavsCollection(newFavs)
  }

  reorderStopFav(oldIndex: number, newIndex: number) {
    const newFavs = arrayMove(this.stopFavs, oldIndex, newIndex)
    return new StopFavsCollection(newFavs)
  }

  getAllStopFavs() {
    return this.stopFavs
  }
}
