import React from 'react'
import { AppThemeName } from 'common/ui/models/app-theme-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'
import ValueSelector from 'common/ui/components/ValueSelector/ValueSelector'
import ValueSelectorItem from 'common/ui/components/ValueSelectorItem/ValueSelectorItem'

const ThemeSelector: React.FC = function ThemeSelector() {
  const { services } = useBrandConfigContext().common
  function selectTheme(themeName: AppThemeName | undefined) {
    services.uiPreferencesStore.setPreferredThemeName(themeName)
    window.location.reload()
  }

  const selectedThemeName = services.uiPreferencesStore.getPreferredThemeName()

  return (
    <ValueSelector>
      <ValueSelectorItem
        onSelect={() => selectTheme(undefined)}
        value={undefined}
        text={<FormattedMessage id="settings_theme_use_system" />}
        selectedValue={selectedThemeName}
      />
      <ValueSelectorItem
        onSelect={() => selectTheme(AppThemeName.Green)}
        value={AppThemeName.Green}
        text={<FormattedMessage id="settings_theme_light" />}
        selectedValue={selectedThemeName}
      />
      <ValueSelectorItem
        onSelect={() => selectTheme(AppThemeName.Dark)}
        value={AppThemeName.Dark}
        text={<FormattedMessage id="settings_theme_dark" />}
        selectedValue={selectedThemeName}
      />
      {/* <button
        type="button"
        className={s.themeOption}
        onClick={() => selectTheme(AppThemeName.Blue)}
      >
        { renderSelectionIndicator(selectedThemeName, AppThemeName.Blue) }
        Tema claro azul
          </button> */}
    </ValueSelector>
  )
}

export default ThemeSelector
