import React from 'react'
import { useHistory } from 'react-router-dom'
import { Stop } from 'common/stops/models/stop-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import s from './StopZoneSelector.module.scss'
import {
  Zones, plazaCastillaZones, principePioZones,
  plazaElipticaZones, moncloaZones,
} from './helpers'

interface StopZoneSelectorProps {
  stop: Stop
}

function renderZones(zones: Zones, onSelect: (stopCode: string) => void) {
  const options = zones.map((zone) => (
    <option value={zone.stopCode} key={zone.stopCode}>{zone.name}</option>
  ))
  return (
    <select
      className={s.zoneSelect}
      value=""
      onChange={(event) => onSelect(event.target.value)}
    >
      <option value="" disabled>Zonas del intercambiador</option>
      { options }
    </select>
  )
}

const StopZoneSelector: React.FC<StopZoneSelectorProps> = ({ stop }) => {
  const { urls } = useBrandConfigContext().stops
  const history = useHistory()

  function handleSelect(stopCode: string) {
    history.push(urls.getStopUrl(stopCode))
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'selectStopZone',
      eventAction: stopCode,
    })
  }

  if (plazaCastillaZones.map((zone) => zone.stopCode).includes(stop.stopCode)) {
    return renderZones(plazaCastillaZones, handleSelect)
  }
  if (principePioZones.map((zone) => zone.stopCode).includes(stop.stopCode)) {
    return renderZones(principePioZones, handleSelect)
  }
  if (plazaElipticaZones.map((zone) => zone.stopCode).includes(stop.stopCode)) {
    return renderZones(plazaElipticaZones, handleSelect)
  }
  if (moncloaZones.map((zone) => zone.stopCode).includes(stop.stopCode)) {
    return renderZones(moncloaZones, handleSelect)
  }
  return <></>
}

export default StopZoneSelector
