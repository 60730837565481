import React from 'react'
import { Link } from 'react-router-dom'
import { SearchableStop } from 'common/search/models/search-models'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { ReactComponent as ArrowRightIcon } from 'common/images/keyboard_arrow_right-24px.svg'
import { StopListItemIndex } from 'common/stops/models/stop-list-models'
import SearchResultStop from 'common/search/components/SearchResultStop/SearchResultStop'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopsBrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage } from 'react-intl'
import s from './StopsFoundByStopCode.module.scss'

interface StopCodeWordSearchResult {
  word: string
  foundItem: SearchableStop | undefined
}

function searchByStopCode(
  items: SearchableStop[],
  wordsWithStopCodeFormat: string[],
  stopsBrandConfig: StopsBrandConfig,
): StopCodeWordSearchResult[] {
  return wordsWithStopCodeFormat.map((word) => {
    const customStopCodeToSearch = stopsBrandConfig.helpers.parseStringToCustomStopCode(word)
    const foundItem = items.find((item) => (
      item.item[StopListItemIndex.StopCode] === customStopCodeToSearch
    ))
    return { word, foundItem }
  })
}

const renderStopNotFoundByCode = (
  wordWithStopCodeFormat: string, stopsBrandConfig: StopsBrandConfig,
) => (
  <Link
    to={stopsBrandConfig.urls.getStopUrl(wordWithStopCodeFormat)}
    className={s.unknownStop}
    key={wordWithStopCodeFormat}
  >
    <FormattedMessage id="search_stop_by_unknown_code" values={{ stopCode: wordWithStopCodeFormat }} />
    <ArrowRightIcon className={s.arrow} />
  </Link>
)

function renderFoundItemsByStopCode(
  results: StopCodeWordSearchResult[],
  visitedStopCodes: string[],
) {
  return results
    .filter((result) => result.foundItem)
    .map((result) => {
      const { item } = result.foundItem!
      const isVisited = !!visitedStopCodes.find((stopCode) => (
        stopCode === item[StopListItemIndex.StopCode]
      ))
      return (
        <SearchResultStop
          stopListItem={item}
          isVisited={isVisited}
          key={item[StopListItemIndex.StopCode]}
        />
      )
    })
}

function renderNotFoundItemsByStopCodeLinks(
  results: StopCodeWordSearchResult[], stopsBrandConfig: StopsBrandConfig,
) {
  return results
    .filter((result) => !result.foundItem)
    .map((result) => renderStopNotFoundByCode(result.word, stopsBrandConfig))
}

interface Props {
  searchableStops: SearchableStop[],
  wordsWithStopCodeFormat: string[],
  visitedStopCodes: string[],
}

const StopsFoundByStopCode: React.FC<Props> = ({
  searchableStops: items, wordsWithStopCodeFormat, visitedStopCodes,
}) => {
  const stopsBrandConfig = useBrandConfigContext().stops
  if (wordsWithStopCodeFormat.length === 0) {
    return <></>
  }
  const results = searchByStopCode(items, wordsWithStopCodeFormat, stopsBrandConfig)
  const foundItems = renderFoundItemsByStopCode(results, visitedStopCodes)
  const notFoundItemLinks = renderNotFoundItemsByStopCodeLinks(results, stopsBrandConfig)
  return (
    <>
      <ExternalSectionTitle title={<FormattedMessage id="stop_search_by_code" />} />
      <div className={s.foundItems}>
        { foundItems }
        { notFoundItemLinks }
      </div>
    </>
  )
}

export default StopsFoundByStopCode
