import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { OpStatus } from 'common/ui/models/ui-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import LoadingView from 'common/ui/components/aux-views/LoadingView'
import ErrorView from 'common/ui/components/aux-views/ErrorView'
import { ReactComponent as RefreshIcon } from 'common/images/refresh-24px.svg'
import NoInternetError from 'common/ui/components/aux-views/NoInternetError'
import { isNoInternetError } from 'common/shared/helpers/network-utils'
import { ReactComponent as SearchIcon } from 'common/images/search-24px.svg'
import { ReactComponent as ShareIcon } from 'common/images/share-24px.svg'
import ShareAppGenericButton from 'common/ui/components/ShareAppGenericButton/ShareAppGenericButton'
import Container from 'common/ui/components/Container/Container'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import { ClientType } from 'common/core/models/param-app-config-models'
import StopComponent from 'common/stops/components/Stop/Stop'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import { Stop } from 'common/stops/models/stop-models'
import { CommonBrandConfig, StopsBrandConfig } from 'common/core/models/brand-config.models'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import s from './StopPageView.module.scss'

const renderSuccessView = (props: StopPageViewProps) => {
  const stopFavsAlreadyRetrieved = props.stopFavsRetrieveStatus === OpStatus.Success
    || props.stopFavsRetrieveStatus === OpStatus.Failed
  if (
    stopFavsAlreadyRetrieved
    && (!props.isInitialStopRetrieving && props.retrieveStopStatus !== OpStatus.Failed)
  ) {
    return (
      <StopComponent
        isInitialStopRetrieving={props.isInitialStopRetrieving}
        stop={props.stop!}
        stopFav={props.stopFav}
        onAddToFavoritesButtonClick={props.onAddToFavoritesButtonClick}
        onRemoveFromFavoritesButtonClick={props.onRemoveFromFavoritesButtonClick}
        onRenameFavoriteButtonClick={props.onRenameFavoriteButtonClick}
        onShowNoDataExplanationClick={props.onShowNoDataExplanationClick}
      />
    )
  }
  return undefined
}

function renderRefreshButton(
  props: StopPageViewProps,
  commonConfig: CommonBrandConfig,
  intl: IntlShape,
) {
  function onButtonClick() {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'stopRefreshButtonClick',
    })
    props.onRefreshButtonClick()
    commonConfig.services.adsManager.requestIntersitialAdIfNeeded('stopPage:refresh')
  }
  const shouldShowBottomAd = commonConfig.helpers
    .shouldShowBottomBannerAd(commonConfig.services.paramAppConfig)
  return (
    <button
      type="button"
      disabled={props.retrieveStopStatus === OpStatus.InProgress}
      className={
        classNames({
          [s.refreshButton]: true,
          [s.refreshButtonWithBottomAd]: shouldShowBottomAd,
        })
      }
      onClick={onButtonClick}
      aria-label={intl.formatMessage({ id: 'stop_refresh_button' })}
    >
      <div
        className={classNames({
          [s.refreshButtonIcon]: true,
          [s.spinning]: props.retrieveStopStatus === OpStatus.InProgress,
        })}
      >
        <RefreshIcon />
      </div>
    </button>
  )
}

const renderLoadingView = (props: StopPageViewProps, stopConfig: StopsBrandConfig) => {
  const StopLoadingContent = stopConfig.components.stopLoadingContent
  if (props.retrieveStopStatus !== OpStatus.Failed
    && (props.stopFavsRetrieveStatus === OpStatus.InProgress
    || (props.isInitialStopRetrieving
      && props.retrieveStopStatus === OpStatus.InProgress))
  ) {
    return (
      <LoadingView>
        <StopLoadingContent stopCode={props.stopCode} />
      </LoadingView>
    )
  }
  return undefined
}

const renderNoInternetError = (props: StopPageViewProps) => (
  <NoInternetError onRetryButtonClick={props.onRefreshButtonClick} />
)

const renderNoStopDataError = (props: StopPageViewProps) => {
  const text = <FormattedMessage id="stop_error" />
  return (
    <ErrorView mainText={text} onRetryButtonClick={props.onRefreshButtonClick}>
      <br />
      <button
        type="button"
        className={s.noDataButton}
        onClick={props.onShowNoDataExplanationClick}
      >
        <FormattedMessage id="stop_error_arrivals_sources_btn" />
      </button>
    </ErrorView>
  )
}

const renderErrorView = (props: StopPageViewProps) => {
  if (props.retrieveStopStatus === OpStatus.Failed) {
    if (isNoInternetError(props.retrieveStopError!)) {
      return renderNoInternetError(props)
    }
    return renderNoStopDataError(props)
  }
  return undefined
}

interface StopPageViewProps {
  stopCode: string
  retrieveStopStatus: OpStatus | undefined
  retrieveStopError: Error | undefined
  isInitialStopRetrieving: boolean
  stop: Stop | undefined
  stopFav?: StopFav
  stopFavsRetrieveStatus: OpStatus | undefined
  onAddToFavoritesButtonClick: () => void
  onRemoveFromFavoritesButtonClick: () => void
  onRenameFavoriteButtonClick: () => void
  onRefreshButtonClick: () => void
  onShowNoDataExplanationClick: () => void
}

const StopPageView: React.FC<StopPageViewProps> = (props) => {
  const { common: commonConfig, stops: stopsConfig } = useBrandConfigContext()
  const intl = useIntl()

  const actionBarRightSection = (
    <>
      <Link
        to={stopsConfig.urls.getStopSearchUrl()}
        title={intl.formatMessage({ id: 'stop_action_bar_search_link_title' })}
        className="globalActionBarIcon"
      >
        <SearchIcon />
      </Link>
      {commonConfig.services.paramAppConfig.clientType !== ClientType.Web && (
        <ShareAppGenericButton>
          {
            (renderInnerContent) => (
              <div className="globalActionBarIcon">
                { renderInnerContent(<ShareIcon />) }
              </div>
            )
          }
        </ShareAppGenericButton>
      )}
    </>
  )

  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title={intl.formatMessage({ id: 'stop_action_bar_title' })}
          backLinkPath={commonConfig.urls.getRootUrl()}
          rightSection={actionBarRightSection}
        />
      )}
    >
      <Container>
        { renderLoadingView(props, stopsConfig) }
        { renderErrorView(props) }
        { renderSuccessView(props) }
        {
          !props.isInitialStopRetrieving
            ? renderRefreshButton(props, commonConfig, intl)
            : undefined
        }
      </Container>
    </PageLayout>
  )
}

export default StopPageView
