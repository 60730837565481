import React, { useState } from 'react'
import { ReactComponent as GoogleIcon } from 'common/images/google.svg'
import useUserContext from 'common/auth/hooks/useUserContext'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import {
  getAuth, User, signOut,
} from 'firebase/auth'
import { FormattedMessage } from 'react-intl'
import s from './AuthSettings.module.scss'
import LogoutModal from '../LogoutModal/LogoutModal'

const AuthSettings: React.FC = () => {
  const { common: { services: { firebaseApp } } } = useBrandConfigContext()
  const currentUser = useUserContext()
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)

  async function signInWithGoogle() {
    window.location.href = '/auth-google-start?target=external'
  }

  function logout() {
    const auth = getAuth(firebaseApp)
    signOut(auth)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'authLogOut',
    })
  }

  function openLogoutModal() {
    setLogoutModalOpen(true)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'authOpenLogOutModal',
    })
  }

  function closeLogoutModal() {
    setLogoutModalOpen(false)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'authCloseLogOutModal',
    })
  }

  function renderAuthenticatedSettings(user: User) {
    return (
      <>
        <div className={s.card}>
          <FormattedMessage id="auth_logged_in_with" />
          <br /><b>{ user.email }</b>
          <p><FormattedMessage id="auth_logged_in_description" /></p>
          <button type="button" onClick={openLogoutModal} className={s.logoutButton}>
            <FormattedMessage id="auth_logout" />
          </button>
        </div>
        <LogoutModal
          isOpen={logoutModalOpen}
          onAccept={logout}
          onCancel={closeLogoutModal}
        />
      </>
    )
  }

  function renderUnauthenticatedSettings() {
    return (
      <div className={s.card}>
        <FormattedMessage id="auth_logged_out_intro" />
        <button
          type="button"
          onClick={signInWithGoogle}
          className={s.logoutButton}
        >
          <GoogleIcon className={s.loginIcon} />
          <FormattedMessage id="auth_login_with_google" />
        </button>
      </div>
    )
  }

  return (
    <>
      { currentUser ? renderAuthenticatedSettings(currentUser) : renderUnauthenticatedSettings() }
    </>
  )
}

export default AuthSettings
