import { getSearchableWords } from 'common/search/helpers/search-normalize'
import normalizeForSearch from 'normalize-for-search'
import { LineListItem } from 'common/lines/models/line-list-models'
import { SearchableLine } from 'common/search/models/search-models'

export function getSearchableLines(lines: LineListItem[]): SearchableLine[] {
  return lines.map<SearchableLine>((line) => (
    {
      item: line,
      normalizedWords: getSearchableWords(line.lineName),
      normalizedLineNumbers: [normalizeForSearch(line.lineNumber)],
    }
  ))
}
