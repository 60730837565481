import { AppThemeName } from 'common/ui/models/app-theme-models'

interface UiPreferences {
  isFavDeparturesHintDismissed?: boolean
  preferredThemeName?: AppThemeName
}

export default class UiPreferencesStore {
  private cachedPrefs: UiPreferences | undefined

  constructor(private localStorageKey: string) {}

  public setPreferredThemeName(appThemeName: AppThemeName | undefined): void {
    const currentPrefs = this.getPreferences()
    this.setPreferencies({
      ...currentPrefs,
      preferredThemeName: appThemeName,
    })
  }

  public getPreferredThemeName(): AppThemeName | undefined {
    return this.getPreferences().preferredThemeName
  }

  public isFavDeparturesHintDismissed(): boolean {
    return !!this.getPreferences().isFavDeparturesHintDismissed
  }

  public dismissFavDeparturesHint(): void {
    const currentPrefs = this.getPreferences()
    this.setPreferencies({
      ...currentPrefs,
      isFavDeparturesHintDismissed: true,
    })
  }

  private getPreferences(): UiPreferences {
    if (!this.cachedPrefs) {
      this.cachedPrefs = this.getPrefsFromStorage()
    }
    return this.cachedPrefs
  }

  private setPreferencies(newPrefs: UiPreferences): void {
    this.cachedPrefs = newPrefs
    this.savePrefsToStorage(newPrefs)
  }

  private savePrefsToStorage(newPrefs: UiPreferences): void {
    this.cachedPrefs = newPrefs
    localStorage.setItem(this.localStorageKey, JSON.stringify(newPrefs))
  }

  private getPrefsFromStorage(): UiPreferences {
    const dataStr = localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return JSON.parse(dataStr)
    }
    return {}
  }
}
