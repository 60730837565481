import React, { useState, useEffect } from 'react'
import { getSearchableWords, extractStopCodes } from 'common/search/helpers/search-normalize'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { SearchableLine, SearchableStop } from 'common/search/models/search-models'
import { LineListItem } from 'common/lines/models/line-list-models'
import EnteringAnimation from 'common/ui/components/EnteringAnimation/EnteringAnimation'
import { StopListItem, StopListItemIndex } from 'common/stops/models/stop-list-models'
import SearchBox from 'common/search/components/SearchBox/SearchBox'
import ResultList from 'common/search/components/ResultList/ResultList'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { searchByScore } from 'common/search/helpers/score-search'
import SearchResultStop from 'common/search/components/SearchResultStop/SearchResultStop'
import { SearchableFakeLine } from 'common/search/services/FakeSearchableLinesBuilder'
import StopsFoundByStopCode from 'common/search/components/StopsFoundByStopCode/StopsFoundByStopCode'
import FoundRealLines from 'common/lines/components/FoundRealLines/FoundRealLines'
import FoundFakelLine from 'common/lines/components/FoundFakelLine/FoundFakelLine'
import { getSearchableLines } from 'common/search/helpers/line-search-preparation'
import { FormattedMessage, useIntl } from 'react-intl'
import s from './Search.module.scss'

function renderFoundLines(
  searchableFakeLines: SearchableFakeLine[],
  searchableLines: SearchableLine[],
  wordsToSearch: string[],
  searchQuery: string,
) {
  const foundFakeLines = searchableFakeLines.filter((line) => (
    wordsToSearch.some((wordToSearch) => line.lineNumber === wordToSearch)
  ))
  const foundSearchableLines = searchByScore(searchableLines, wordsToSearch)
  if (foundFakeLines.length > 0 || foundSearchableLines.length > 0) {
    return (
      <>
        <ExternalSectionTitle title={<FormattedMessage id="search_found_lines_title" />} />
        <div className={s.foundItems}>
          {
            foundFakeLines.map((line) => {
              const key = `${line.stopTypeNumCode}-${line.lineNumber}`
              return <FoundFakelLine line={line} key={key} />
            })
          }
          <FoundRealLines
            foundSearchableLines={foundSearchableLines}
            searchQuery={searchQuery}
          />
        </div>
      </>
    )
  }
  return undefined
}
interface SearchProps {
  stopList: StopListItem[]
  lineList: LineListItem[]
  defaultQuery?: string
}

const renderFoundStops = (
  items: SearchableStop[],
  searchValue: string,
  visitedStopCodes: string[],
) => {
  const renderItem = ({ item }: SearchableStop) => {
    const isVisited = !!visitedStopCodes.find((stopCode) => (
      stopCode === item[StopListItemIndex.StopCode]
    ))
    return (
      <SearchResultStop
        stopListItem={item}
        key={item[StopListItemIndex.StopCode]}
        isVisited={isVisited}
      />
    )
  }
  return (
    <div className={s.foundItems}>
      <ResultList
        items={items}
        searchQuery={searchValue}
        renderItem={renderItem}
        buttonTextFn={(numberOfItems) => (
          <FormattedMessage
            id="search_show_more_stops"
            values={{ numberOfItems }}
          />
        )}
      />
    </div>
  )
}

const renderPreSearchSection = () => (
  <div />
)

const renderStopsNotFoundByName = (searchQuery: string) => (
  <div className={s.noResults}>
    <FormattedMessage
      id="search_stops_not_found"
      values={{ searchQuery }}
    />
  </div>
)

function renderStopsFoundByStopAttrs(
  searchableStops: SearchableStop[],
  searchValue: string,
  wordsToSearch: string[],
  showMessageIfNoResults: boolean,
  visitedStopCodes: string[],
) {
  const results = searchByScore(searchableStops, wordsToSearch)
  if (results.length === 0 && !showMessageIfNoResults) {
    return undefined
  }
  return (
    <>
      <ExternalSectionTitle title={<FormattedMessage id="search_found_stops_title" />} />
      {
        results.length === 0
          ? renderStopsNotFoundByName(searchValue)
          : renderFoundStops(results, searchValue, visitedStopCodes)
      }
    </>
  )
}

function renderFoundItems(
  searchableStops: SearchableStop[],
  searchableFakeLines: SearchableFakeLine[],
  searchableLines: SearchableLine[],
  searchValue: string,
  visitedStopCodes: string[],
) {
  const wordsToSearch = getSearchableWords(searchValue)
  const wordsWithStopCodeFormat = extractStopCodes(searchValue)
  const showMessageIfNoResult = wordsWithStopCodeFormat.length === 0
  return (
    <>
      <StopsFoundByStopCode
        searchableStops={searchableStops}
        wordsWithStopCodeFormat={wordsWithStopCodeFormat}
        visitedStopCodes={visitedStopCodes}
      />
      {
        renderStopsFoundByStopAttrs(
          searchableStops, searchValue, wordsToSearch, showMessageIfNoResult, visitedStopCodes,
        )
      }
      { renderFoundLines(searchableFakeLines, searchableLines, wordsToSearch, searchValue) }
    </>
  )
}

const renderResults = (
  searchableStops: SearchableStop[] | undefined,
  searchableFakeLines: SearchableFakeLine[] | undefined,
  searchableLines: SearchableLine[] | undefined,
  searchValue: string | undefined,
  visitedStopCodes: string[],
) => {
  if (!searchableStops || !searchableFakeLines || !searchableLines) {
    return undefined
  }
  if (!searchValue) {
    return renderPreSearchSection()
  }
  return renderFoundItems(
    searchableStops, searchableFakeLines, searchableLines, searchValue, visitedStopCodes,
  )
}

const Search: React.FC<SearchProps> = (props) => {
  const intl = useIntl()
  const [searchValue, setSearchValue] = useState<string | undefined>(props.defaultQuery)
  const [searchableStops, setSearchableStops] = useState<SearchableStop[] | undefined>()
  const [searchableFakeLines, setSearchableFakeLines] = useState<SearchableFakeLine[] | undefined>()
  const [searchableLines, setSearchableLines] = useState<SearchableLine[] | undefined>()
  const { services, helpers } = useBrandConfigContext().stops
  const visitedStopCodes = services.visitedStopsStore.getVisitedStopCodes()

  useEffect(() => {
    setSearchableStops(services.searchableStopsBuilder.getSearchableStops(props.stopList))
    setSearchableFakeLines(
      services.fakeSearchableLinesBuilder.getSearchableFakeLines(props.stopList),
    )
    setSearchableLines(getSearchableLines(props.lineList))
  }, [props.stopList, props.lineList, services])

  function handleTextFieldChange(newValue: string) {
    setSearchValue(newValue)
    services.searchHistoryStore.setLastQuery(newValue)
  }

  return (
    <EnteringAnimation>
      <div className={s.root}>
        <SearchBox
          initialSearchQuery={searchValue}
          onChange={handleTextFieldChange}
          placeholder={helpers.stopSeacrhFieldPlaceholder(intl)}
          autofocus
        />
        {
          renderResults(
            searchableStops, searchableFakeLines, searchableLines,
            searchValue, visitedStopCodes,
          )
        }
      </div>
    </EnteringAnimation>
  )
}

export default Search
