export function scrollToTop(): void {
  try {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  } catch (error) {} // eslint-disable-line
}

export function scrollIntoView(element: Element): void {
  try {
    element.scrollIntoView()
  } catch (error) {} // eslint-disable-line
}
