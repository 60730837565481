import React, { ReactElement } from 'react'
import { ClientType } from 'common/core/models/param-app-config-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

type ContentRenderingFn = (innerContent: JSX.Element) => JSX.Element

interface RateAppGenericLinkProps {
  children: (contentRenderingFn: ContentRenderingFn) => ReactElement | null
}

function onClick() {
  window.ga('send', {
    hitType: 'event',
    transport: 'beacon',
    eventCategory: 'rateAppLinkClick',
  })
}

const RateAppGenericLink: React.FC<RateAppGenericLinkProps> = (props) => {
  const { common: commonConfig } = useBrandConfigContext()

  function shouldShowLink(): boolean {
    const { clientType } = commonConfig.services.paramAppConfig
    return clientType === ClientType.IOS || clientType === ClientType.Android
  }

  function renderLink(innerContent: JSX.Element) {
    return (
      <a
        href={commonConfig.downloadAppUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        { innerContent }
      </a>
    )
  }

  if (shouldShowLink()) {
    const contentRenderingFn: ContentRenderingFn = (innerContent) => renderLink(innerContent)
    return props.children(contentRenderingFn)
  }
  return <></>
}

export default RateAppGenericLink
