import React from 'react'
import { ReactComponent as ChatIcon } from 'common/images/chat-24px.svg'
import { ReactComponent as ThumbUpIcon } from 'common/images/thumb_up-24px.svg'
import { ReactComponent as ShareIcon } from 'common/images/share-24px.svg'
import FeedbackGenericButton from 'common/ui/components/FeedbackGenericButton/FeedbackGenericButton'
import RateAppGenericLink from 'common/ui/components/RateAppGenericLink/RateAppGenericLink'
import ShareAppGenericButton from 'common/ui/components/ShareAppGenericButton/ShareAppGenericButton'
import { FormattedMessage } from 'react-intl'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import s from './SecondaryActions.module.scss'

function renderRateAppButton() {
  const innerContent = (
    <div className={s.actionInner}><ThumbUpIcon />
      <FormattedMessage id="secondary_actions_rate_app" />
    </div>
  )
  return (
    <RateAppGenericLink>
      {
        (renderInnerContent) => (
          <div className={s.action}>
            { renderInnerContent(innerContent) }
          </div>
        )
      }
    </RateAppGenericLink>
  )
}

function renderChatButton() {
  const innerContent = (
    <div className={s.actionInner}><ChatIcon />
      <FormattedMessage id="secondary_actions_use_chat" />
    </div>
  )
  return (
    <FeedbackGenericButton>
      {
        (renderInnerContent) => (
          <div className={s.action}>
            { renderInnerContent(innerContent) }
          </div>
        )
      }
    </FeedbackGenericButton>
  )
}

function renderShareButton() {
  const innerContent = (
    <div className={s.actionInner}><ShareIcon />
      <FormattedMessage id="secondary_actions_share_app" />
    </div>
  )
  return (
    <ShareAppGenericButton>
      {
        (renderInnerContent) => (
          <div className={s.action}>
            { renderInnerContent(innerContent) }
          </div>
        )
      }
    </ShareAppGenericButton>
  )
}

const SecondaryActions: React.FC = () => {
  const {
    common: {
      helpers: { shouldShowChatButtonOnSettingsPage },
      services: { paramAppConfig },
    },
  } = useBrandConfigContext()
  return (
    <div className={s.actions}>
      { shouldShowChatButtonOnSettingsPage(paramAppConfig) && renderChatButton() }
      { renderRateAppButton() }
      { renderShareButton() }
    </div>
  )
}

export default SecondaryActions
