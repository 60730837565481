import loadGoogleMapsApi from 'load-google-maps-api'

export const loadGoogleMaps = () => (
  loadGoogleMapsApi({
    key: getGoogleMapsApiKey(),
    language: 'es',
    region: 'ES',
    libraries: ['places'],
  })
)

const googleMapsApiKeys = [
  'AIzaSyALZoZ-5HIsW4sk15nkNFmgy7V56mPu9qI',
  'AIzaSyCDAT2AkeZKq8J5uT7kngQP-DCfxaxKibM',
  'AIzaSyBvWzsDyUUQQJgn8DZF05XYOJq_KhVNzTM',
  'AIzaSyC2GuzckBW01jWqaX44FAno1vZ0cPZqz_k',
]

function getKeyArrayIndex(dayOfMonth: number) {
  return Math.ceil((dayOfMonth / 31) * googleMapsApiKeys.length) - 1
}

function getGoogleMapsApiKey() {
  const dayOfMonth = new Date().getDate()
  return googleMapsApiKeys[getKeyArrayIndex(dayOfMonth)]
}
