import React from 'react'
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  Link,
  useParams,
} from 'react-router-dom'
import StopPage from 'common/stops/components/StopPage'
import SearchPage from 'common/search/components/SearchPage/SearchPage'
import StopMapPage from 'common/maps/components/StopMapPage/StopMapPage'
import AdminPage from 'common/core/components/AdminPage/AdminPage'
import SettingsPage from 'common/settings/components/SettingsPage/SettingsPage'
import LinePage from 'common/lines/components/LinePage/LinePage'
import LinesPage from 'common/lines/components/LinesPage/LinesPage'
import PosterIframeView from 'common/posters/components/PosterIframeView/PosterIframeView'
import PosterPage from 'common/posters/components/PosterPage/PosterPage'
import { useIntl } from 'react-intl'
import { StopTypeNumCode } from '../../stops/models/stop-models'
import PostersPage from '../../posters/components/PostersPage/PostersPage'
import { RouteManager } from '../services/route-manager/RouteManager'
import { PageId } from '../models/app-models'
import HomePage from '../../home/components/HomePage/HomePage'

const AppRoutes: React.FC = () => {
  const intl = useIntl()
  return (
    <Switch>
      <Route exact path={RouteManager.getAppDashboardUrl()}>
        <Redirect to={RouteManager.getStopsUrl()} />
      </Route>

      <Route exact path={RouteManager.getRootUrl()}>
        <Redirect to={RouteManager.getStopsUrl()} />
      </Route>
      <Route exact path={RouteManager.getStopsUrl()}>
        <HomePage />
      </Route>
      <Route exact path={RouteManager.getStopsSearchUrl()}>
        <SearchPage />
      </Route>
      <Route exact path={RouteManager.getStopsMapUrl()}>
        <StopMapPageWrapper />
      </Route>
      <Route exact path="/mapa-paradas">
        <Redirect to={RouteManager.getStopsMapUrl()} />
      </Route>
      <Route exact path="/buscar-parada">
        <Redirect to={RouteManager.getStopsSearchUrl()} />
      </Route>
      <Route path="/parada/:stopCode">
        <RedirectToStopPage />
      </Route>
      <Route exact path={RouteManager.getStopUrl('000')}>
        <AdminPage />
      </Route>
      <Route path={RouteManager.getStopUrlDef()}>
        <StopPage />
      </Route>

      <Route exact path={RouteManager.getLinesUrl()}>
        <LinesPage />
      </Route>
      <Route path={RouteManager.getLineUrlDef()}>
        <LinePage />
      </Route>

      <Route exact path={RouteManager.getPostersUrl()}>
        <PostersPage />
      </Route>
      <Route exact path={RouteManager.getBusLinesPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_bus_lines' })} pageId={PageId.BusLinesPosterPage}>
          <PosterIframeView
            title={intl.formatMessage({ id: 'posters_bus_lines' })}
            iframeUrl="https://www.easyzoom.com/embed/913844e14f754022bb67e3c9360fa963"
          />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getBusStopsPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_bus_stops' })} pageId={PageId.BusStopsPosterPage}>
          <PosterIframeView
            title={intl.formatMessage({ id: 'posters_bus_stops' })}
            iframeUrl="https://www.easyzoom.com/embed/f297134677524bcab21bf31ba44e0ce2"
          />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getNocturnalBusPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_nocturnal_buses' })} pageId={PageId.NocturnalBusesPosterPage}>
          <PosterIframeView
            title={intl.formatMessage({ id: 'posters_nocturnal_buses' })}
            iframeUrl="https://www.easyzoom.com/embed/ff345a76c9444a708863caac3cffa74b"
          />
        </PosterPage>
      </Route>

      <Route exact path={RouteManager.getSettingsUrl()}>
        <SettingsPage />
      </Route>

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  )
}

const StopMapPageWrapper: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const stopCode = queryParams.get(RouteManager.mapStopParamName) || undefined
  const lineNumber = queryParams.get(RouteManager.mapLineNumberParamName) || undefined
  const stopTypeNumCode = queryParams.get(
    RouteManager.mapStopTypeNumCodeParamName,
  ) as StopTypeNumCode || undefined
  return (
    <StopMapPage
      selectedStopCode={stopCode}
      lineNumber={lineNumber}
      stopTypeNumCode={stopTypeNumCode}
    />
  )
}

const RedirectToStopPage: React.FC = () => (
  <Redirect to={RouteManager.getStopUrl(useParams<{stopCode: string}>().stopCode)} />
)

function NoMatch() {
  const { pathname, search } = useLocation()
  const path = `${pathname}${search}`
  return (
    <div>
      La página &quot;<code>{path}</code>&quot; no existe. <br />
      <Link to={RouteManager.getRootUrl()}>Ir a la página principal</Link>
    </div>
  )
}

export default AppRoutes
