import React from 'react'
import s from './aux-views.module.scss'

const FullHeightContainer: React.FC = (props) => (
  <div className={s.fullHeighContainer}>
    <div className={s.fullHeighContainerContent}>
      { props.children }
    </div>
  </div>
)

export default FullHeightContainer
