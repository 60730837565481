import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import React from 'react'
import { Link } from 'react-router-dom'
import s from './ShortStop.module.scss'

interface Props {
  stopCode: string
  stopName: string
  dragHandle?: React.ReactElement
  onClick?: () => void
}

const ShortStop: React.FC<Props> = ({
  stopCode, stopName, dragHandle, onClick,
}) => {
  const {
    components,
    urls,
    services,
  } = useBrandConfigContext().stops
  const StopIconComponent = components.stopIcon
  const stopCodeWrapper = services.stopCodeWrapperFactory.create(stopCode)
  return (
    <Link
      to={urls.getStopUrl(stopCode)}
      className={s.stop}
      onClick={() => onClick && onClick()}
    >
      <div className={s.stopIcon}>
        <StopIconComponent stopTypeCode={stopCodeWrapper.getStopTypeCode()} />
      </div>
      <div className={s.stopName}>{stopName}</div>
      <div className={s.stopCode}>{stopCode}</div>
      { dragHandle }
    </Link>
  )
}

export default ShortStop
