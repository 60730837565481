import React from 'react'
import { ReactComponent as BusIcon } from '../../images/stop-bus.svg'
import { StopTypeNumCode } from '../../models/stop-models'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: {[key in StopTypeNumCode]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  [StopTypeNumCode.Bus]: BusIcon,
}

const StopIcon: React.FC<StopIconProps> = ({ stopTypeCode }) => {
  const IconComponent = mapping[stopTypeCode as StopTypeNumCode]
  if (IconComponent) {
    return <IconComponent />
  }
  return <></>
}

export default StopIcon
