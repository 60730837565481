import React, { ReactElement, useState } from 'react'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import useUserContext from 'common/auth/hooks/useUserContext'
import SyncExecutor from '../SyncExecutor/SyncExecutor'

interface Props {
  render: () => ReactElement | null
}

const SyncManager: React.FC<Props> = ({ render }) => {
  const currentUser = useUserContext()

  const {
    stops: {
      services: {
        stopFavsStore,
      },
    },
  } = useBrandConfigContext()

  const [syncFinished, setSyncFinished] = useState<boolean>(false)

  function handleSyncEnd() {
    stopFavsStore.deleteAllStopFavs()
    setSyncFinished(true)
  }

  function isSyncNeeded() {
    return stopFavsStore.getStopFavs().length > 0
  }

  return (
    <>
      {
        !!currentUser && isSyncNeeded() && !syncFinished
          ? (
            <SyncExecutor
              user={currentUser}
              localStopFavs={stopFavsStore.getStopFavs()}
              onSyncEnd={handleSyncEnd}
            />
          )
          : render()
      }
    </>
  )
}

export default SyncManager
