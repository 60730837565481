import React, { ReactElement, useState } from 'react'
import s from './ShareAppGenericButton.module.scss'
import ShareAppModal from '../ShareAppModal/ShareAppModal'

type ContentRenderingFn = (innerContent: JSX.Element) => JSX.Element

interface ShareAppGenericButtonProps {
  children: (contentRenderingFn: ContentRenderingFn) => ReactElement | null
}

const ShareAppGenericButton: React.FC<ShareAppGenericButtonProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  function onShareModalOpen() {
    setIsModalVisible(true)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'shareApp:openDialog',
    })
  }

  const contentRenderingFn: ContentRenderingFn = (innerContent) => (
    <>
      <button
        type="button"
        className={s.root}
        onClick={onShareModalOpen}
      >
        { innerContent }
      </button>
      <ShareAppModal isOpen={isModalVisible} onAccept={() => setIsModalVisible(false)} />
    </>
  )

  return props.children(contentRenderingFn)
}

export default ShareAppGenericButton
