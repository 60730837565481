import React from 'react'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import Container from 'common/ui/components/Container/Container'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import useTransManagerContext from 'common/i18n/hooks/useTransManagerContext'
import { AdsManager } from 'common/ads/services/AdsManager'

const AdminPage: React.FC = () => {
  const { changeLocale } = useTransManagerContext()
  const { services, urls } = useBrandConfigContext().common
  const { buildtimeConfig, paramAppConfig } = services

  function tryPersistentStorage() {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((granted) => {
        console.log('result', granted) // eslint-disable-line
        if (granted) {
          alert('GRANTED') // eslint-disable-line
        } else {
          alert('NOT GRANTED') // eslint-disable-line
        }
      })
    }
  }

  function callAndroidBridge() {
    AdsManager.requestAndroidBridgeInterstitialAd()
  }

  function iosPostMessage() {
    AdsManager.requestIosBridgeInterstitialAd()
  }

  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title="Admin"
          backLinkPath={urls.getRootUrl()}
        />
      )}
    >
      <Container>
        <p><button type="button" onClick={tryPersistentStorage}>Try persistent storage</button></p>
        <p><button type="button" onClick={callAndroidBridge}>Call Android bridge</button></p>
        <p>npm package version: { buildtimeConfig.REACT_APP_NPM_PACKAGE_VERSION }</p>
        <div>
          paramAppConfig:
          <pre>{JSON.stringify(paramAppConfig, undefined, 2)}</pre>
        </div>
        <button type="button" onClick={iosPostMessage}>
          iOS post message
        </button>
        <button type="button" onClick={() => changeLocale('es')}>
          Change locale to es
        </button>
        <button type="button" onClick={() => changeLocale('en')}>
          Change locale to en
        </button>
        <button type="button" onClick={() => changeLocale('ca')}>
          Change locale to ca
        </button>
      </Container>
    </PageLayout>
  )
}

export default AdminPage
