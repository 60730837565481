import React, { useState, useRef } from 'react'
import { ReactComponent as SearchIcon } from 'common/images/search-24px.svg'
import { ReactComponent as CloseIcon } from 'common/images/close-24px.svg'
import s from './SearchBox.module.scss'

interface SearchBoxProps {
  initialSearchQuery: string | undefined
  onChange: (searchQuery: string) => void
  placeholder: string
  autofocus: boolean
}

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const initialSearchQuery = props.initialSearchQuery || ''
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery)
  const inputEl = useRef<HTMLInputElement>(null)

  function changeSearchQuery(newSearchQuery: string) {
    setSearchQuery(newSearchQuery)
    props.onChange(newSearchQuery)
  }

  function handleTextFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSearchQuery = event.target.value
    changeSearchQuery(newSearchQuery)
  }

  function handleClearInput() {
    changeSearchQuery('')
    inputEl.current!.focus()
  }

  function renderClearButton() {
    return (
      <button type="button" onClick={handleClearInput}>
        <CloseIcon className={s.closeIcon} />
      </button>
    )
  }

  return (
    <div className={s.searchFieldContainer}>
      <input
        type="text"
        ref={inputEl}
        value={searchQuery}
        placeholder={props.placeholder}
        className={s.searchField}
        onChange={handleTextFieldChange}
        required
        autoComplete="off"
        autoFocus={props.autofocus && !initialSearchQuery}
        data-hj-whitelist
      />
      { searchQuery.length === 0 ? <SearchIcon className={s.searchIcon} /> : undefined}
      { searchQuery.length > 0 ? renderClearButton() : undefined }
    </div>
  )
}

export default SearchBox
