export class RouteManager {
  static getAppDashboardUrl() {
    return '/-app/inicio'
  }

  static getRootUrl() {
    return '/'
  }

  static getSettingsUrl() {
    return '/ajustes'
  }

  static getStopsUrl() {
    return '/paradas'
  }

  static getStopUrl(stopCode: string) {
    return `/paradas/${stopCode}`
  }

  static getStopUrlDef() {
    return '/paradas/:stopCode'
  }

  static mapStopParamName = 'codParada'

  static mapLineNumberParamName = 'numLinea'

  static mapStopTypeNumCodeParamName = 'numTParada'

  static getStopsMapUrl(
    selectedStopCode?: string,
    stopTypeNumCode?: string,
    lineNumber?: string,
  ) {
    const sharedPart = '/paradas/mapa'
    if (selectedStopCode) {
      return `${sharedPart}?${RouteManager.mapStopParamName}=${selectedStopCode}`
    }
    if (stopTypeNumCode && lineNumber) {
      const stopTypePart = `${RouteManager.mapStopTypeNumCodeParamName}=${stopTypeNumCode}`
      const lineNumberPart = `${RouteManager.mapLineNumberParamName}=${lineNumber}`
      return `${sharedPart}?${stopTypePart}&${lineNumberPart}`
    }
    return sharedPart
  }

  static getStopsSearchUrl() {
    return '/paradas/buscar'
  }

  static getLinesUrl() {
    return '/lineas'
  }

  static getLineUrl(lineCode: string) {
    return `/lineas/${lineCode}`
  }

  static getLineUrlDef() {
    return '/lineas/:lineCode'
  }

  static getPostersUrl() {
    return '/planos'
  }

  static getBusLinesPosterUrl() {
    return '/planos/mapa-lineas-bus-bilbao'
  }

  static getBusStopsPosterUrl() {
    return '/planos/mapa-paradas-bus-bilbao'
  }

  static getNocturnalBusPosterUrl() {
    return '/planos/mapa-buses-nocturnos-bilbao'
  }
}
