import React from 'react'
import classNames from 'classnames'
import CardLink from 'common/ui/components/CardLink/CardLink'
import { ReactComponent as LogoImg } from 'common/images/logo.svg'
import { ClientType } from 'common/core/models/param-app-config-models'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import { FormattedMessage } from 'react-intl'
import s from './InstallAppCard.module.scss'

interface AppCardProps {
  className?: string
}

function sendEvent() {
  window.ga('send', {
    hitType: 'event',
    transport: 'beacon',
    eventCategory: 'installAppLinkClick',
    eventAction: 'card',
  })
}

const InstallAppCard: React.FC<AppCardProps> = (props) => {
  const { common } = useBrandConfigContext()
  if (common.services.paramAppConfig.clientType !== ClientType.Web) {
    return <></>
  }
  const leftChildren = (
    <div className={s.appIcon}>
      <LogoImg />
    </div>
  )
  const smallTextClassname = classNames('globalInlineBlock', s.smallText)
  return (
    <a
      href={common.downloadAppUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(s.root, props.className)}
      onClick={sendEvent}
    >
      <CardLink leftChildren={leftChildren} className={s.customCardLink}>
        <span className={smallTextClassname}><FormattedMessage id="install_card_secondary_one" /></span>{' '}
        <span className={smallTextClassname}><FormattedMessage id="install_card_secondary_two" /></span>
        <div className={s.button}><FormattedMessage id="install_card_primary" /></div>
      </CardLink>
    </a>
  )
}

export default InstallAppCard
