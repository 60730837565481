import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { OpStatus } from 'common/ui/models/ui-models'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import { Stop } from 'common/stops/models/stop-models'
import { getFinalStopName } from 'common/stops/helpers/ui-helpers'
import StopPageView from 'common/stops/components/StopPageView/StopPageView'
import FavoriteModal from 'common/stops/components/modals/FavoriteModal'
import RemoveFavoriteModal from 'common/stops/components/modals/RemoveFavoriteModal'
import ArrivalsSourcesModal from 'common/stops/components/modals/ArrivalsSourcesModal'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'
import useUserContext from 'common/auth/hooks/useUserContext'
import RenameFavoriteModal from './modals/RenameFavoriteModal'
import { StopFavsCollection } from '../helpers/StopFavsCollection'

const StopPage: React.FC = () => {
  const { stopCode } = useParams<{stopCode: string}>()

  const { common: commonConfig, stops: stopsConfig } = useBrandConfigContext()
  const { adsManager, appUsageTracker } = commonConfig.services
  const {
    stopFavsService, visitedStopsStore, stopApiClient, stopCodeWrapperFactory,
  } = stopsConfig.services
  const currentUser = useUserContext()

  const [retrieveStopStatus, setRetrieveStopStatus] = useState<OpStatus>()
  const [retrieveStopError, setRetrieveStopError] = useState<Error>()
  const [isInitialStopRetrieving, setIsInitialStopRetrieving] = useState(true)

  const [stop, setStop] = useState<Stop>()
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false)
  const [isRenameFavoriteModalOpen, setIsRenameFavoriteModalOpen] = useState(false)
  const [isRemoveFavoriteModalOpen, setIsRemoveFavoriteModalOpen] = useState(false)
  const [isNoDataModalOpen, setIsNoDataModalOpen] = useState(false)

  const [fav, setFav] = useState<StopFav | undefined>()
  const [favsCollection, setFavsCollection] = useState<StopFavsCollection | undefined>()
  const [favsRetrieveStatus, setFavsRetrieveStatus] = useState<OpStatus>()

  const retrieveFavs = useCallback(async () => {
    setFavsRetrieveStatus(OpStatus.InProgress)
    try {
      const stopFavs = await stopFavsService.getStopFavs(currentUser)
      const retrievedStopFavsCollection = new StopFavsCollection(stopFavs)
      setFavsCollection(retrievedStopFavsCollection)
      setFav(retrievedStopFavsCollection.findStopFav(stopCode))
      setFavsRetrieveStatus(OpStatus.Success)
    } catch (error) {
      setFavsRetrieveStatus(OpStatus.Failed)
      throw error
    }
  }, [stopFavsService, currentUser, stopCode])

  useEffect(() => { retrieveFavs() }, [retrieveFavs])

  useEffect(() => {
    setIsInitialStopRetrieving(true)
    visitedStopsStore.recordVisit(stopCode)
  }, [stopCode, visitedStopsStore])

  useEffect(() => {
    adsManager.requestIntersitialAdIfNeeded('stopPage')
    appUsageTracker.registerStopPageVisit()
  }, [stopCode, appUsageTracker, adsManager])

  useEffect(() => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'stopPageView',
      eventAction: stopCodeWrapperFactory.create(stopCode).getStopTypeCode(),
      eventLabel: stopCode,
    })
  }, [stopCode, stopCodeWrapperFactory])

  const fetchStop = useCallback(async (isFirstRetrieving?: boolean) => {
    setRetrieveStopStatus(OpStatus.InProgress)
    setRetrieveStopError(undefined)
    try {
      const retrievedStop = await stopApiClient.getStop(stopCode)
      if (isFirstRetrieving) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'stopInitialLoadSuccess',
          eventAction: stopCodeWrapperFactory.create(stopCode).getStopTypeCode(),
          eventLabel: stopCode,
        })
      }
      setStop(retrievedStop)
      setRetrieveStopStatus(OpStatus.Success)
      setIsInitialStopRetrieving(false)
    } catch (error) {
      setRetrieveStopError(error)
      setRetrieveStopStatus(OpStatus.Failed)
      setIsInitialStopRetrieving(true)
    }
  }, [stopCode, stopApiClient, stopCodeWrapperFactory])

  useEffect(() => { fetchStop(true) }, [stopCode, fetchStop])

  function addStopToFavorites(favoriteName: string) {
    const stopFav: StopFav = {
      stopCode,
      name: favoriteName,
    }
    const newCollection = favsCollection!.addStopFav(stopFav)
    stopFavsService.setStopFavs(currentUser, newCollection.getAllStopFavs())
    setFav(stopFav)
    setFavsCollection(newCollection)
    setIsFavoriteModalOpen(false)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'createFavStop',
      eventAction: stopCode,
    })
  }

  function renameFavoriteStop(favoriteName: string) {
    const newCollection = favsCollection!.renameStopFav(stopCode, favoriteName)
    stopFavsService.setStopFavs(currentUser, newCollection.getAllStopFavs())
    setFav({ stopCode, name: favoriteName })
    setFavsCollection(newCollection)
    setIsRenameFavoriteModalOpen(false)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'renameFavStop',
      eventAction: stopCode,
    })
  }

  function removeStopFromFavorites() {
    const newCollection = favsCollection!.removeStopFav(stopCode)
    stopFavsService.setStopFavs(currentUser, newCollection.getAllStopFavs())
    setFav(undefined)
    setFavsCollection(newCollection)
    setIsRemoveFavoriteModalOpen(false)
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'removeFavStop',
      eventAction: stopCode,
    })
  }

  const renderFavoriteModal = (isOpen: boolean) => (
    <FavoriteModal
      isOpen={isOpen}
      defaultFavoriteName={stop ? getFinalStopName(stop, fav) : ''}
      onAccept={addStopToFavorites}
      onCancel={() => setIsFavoriteModalOpen(false)}
    />
  )

  const renderRenameFavoriteModal = (isOpen: boolean) => (
    <RenameFavoriteModal
      isOpen={isOpen}
      defaultFavoriteName={stop ? getFinalStopName(stop, fav) : ''}
      onAccept={renameFavoriteStop}
      onCancel={() => setIsRenameFavoriteModalOpen(false)}
    />
  )

  const renderRemoveFavoriteModal = (isOpen: boolean) => (
    <RemoveFavoriteModal
      isOpen={isOpen}
      onAccept={removeStopFromFavorites}
      onCancel={() => setIsRemoveFavoriteModalOpen(false)}
    />
  )

  const renderNoDataModal = (isOpen: boolean) => (
    <ArrivalsSourcesModal
      isOpen={isOpen}
      onAccept={() => setIsNoDataModalOpen(false)}
    />
  )

  return (
    <>
      <StopPageView
        stopCode={stopCode}
        retrieveStopStatus={retrieveStopStatus}
        retrieveStopError={retrieveStopError}
        isInitialStopRetrieving={isInitialStopRetrieving}
        stop={stop}
        stopFavsRetrieveStatus={favsRetrieveStatus}
        stopFav={fav}
        onAddToFavoritesButtonClick={() => setIsFavoriteModalOpen(true)}
        onRemoveFromFavoritesButtonClick={() => setIsRemoveFavoriteModalOpen(true)}
        onRenameFavoriteButtonClick={() => setIsRenameFavoriteModalOpen(true)}
        onRefreshButtonClick={() => fetchStop()}
        onShowNoDataExplanationClick={() => setIsNoDataModalOpen(true)}
      />
      { renderFavoriteModal(isFavoriteModalOpen) }
      { renderRenameFavoriteModal(isRenameFavoriteModalOpen) }
      { renderRemoveFavoriteModal(isRemoveFavoriteModalOpen) }
      { renderNoDataModal(isNoDataModalOpen) }
    </>
  )
}

export default StopPage
