import React from 'react'
import s from './ArrivalsSourcesModalContent.module.scss'

const ArrivalsSourcesModalContent: React.FC = () => (
  <>
    <p className={s.firstParagraph}>
      Obtenemos todos los datos de fuentes oficiales
      como el servidor de Bilbao Open Data. No generamos estos datos
      por nuestra cuenta, y por lo tanto dependemos completamente de las
      fuentes oficiales.
    </p>
    <p>
      El problema es que los servidores de estas fuentes a veces
      no están disponibles o proporcionan datos incorrectos.
      Estos errores suelen ser temporales, pero si persisten durante varios días
      nos puedes escribir a{' '}
      <span className={s.email}>
        oktransitapp+bilbao@gmail.com
      </span>
      {' '}y nosotros nos pondremos en contacto con
      la fuente de datos correspondiente para que solucionen el problema.
    </p>
    <p className={s.lastParagraph}>
      Perdona por las molestias si hay algo que no funciona.
    </p>
  </>
)

export default ArrivalsSourcesModalContent
