import React from 'react'
import classNames from 'classnames'
import s from './ValueSelectorItem.module.scss'

interface Props {
  value: string | undefined
  text: React.ReactNode
  selectedValue: string | undefined
  onSelect: (value: string | undefined) => void
}

const ValueSelectorItem: React.FC<Props> = ({
  value, text, selectedValue, onSelect,
}) => (
  <button
    type="button"
    className={s.themeOption}
    onClick={() => onSelect(value)}
  >
    <div
      className={
        classNames({
          [s.selectionIndicator]: true,
          [s.selected]: value === selectedValue,
        })
      }
    />
    {text}
  </button>
)

export default ValueSelectorItem
