import React, { useState, useEffect } from 'react'
import { AppModal } from 'common/ui/components/AppModal/AppModal'
import { FormattedMessage, useIntl } from 'react-intl'
import s from 'common/ui/components/modals/modals.module.scss'

interface FavoriteModalProps {
  isOpen: boolean
  defaultFavoriteName: string
  onAccept: (favoriteName: string) => void
  onCancel: () => void
}

const FavoriteModal = (props: FavoriteModalProps) => {
  const intl = useIntl()
  const [name, setName] = useState(props.defaultFavoriteName)

  useEffect(() => {
    setName(props.defaultFavoriteName)
  }, [props.defaultFavoriteName])

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value)
  }

  function handleAccept(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (name) {
      props.onAccept(name)
    } else {
      props.onAccept(props.defaultFavoriteName)
    }
  }

  return (
    <AppModal
      isOpen={props.isOpen}
      title={intl.formatMessage({ id: 'stop_fav_modal_title' })}
      shouldShowCloseButton={false}
      onRequestClose={props.onCancel}
    >
      <form onSubmit={handleAccept} method="POST">
        <FormattedMessage id="stop_fav_modal_select_name" />
        <br />
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          className={s.textField}
          data-hj-whitelist
        />
        <div className={s.buttonsContainer}>
          <button type="button" onClick={props.onCancel} className={s.button}>
            <FormattedMessage id="stop_fav_modal_cancel" />
          </button>
          <button type="submit" className={s.button}>
            <FormattedMessage id="stop_fav_modal_save" />
          </button>
        </div>
      </form>
    </AppModal>
  )
}

export default FavoriteModal
